import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { createOptions, goFetch } from "../../api/cirugiasApi";
import { useEffect, useState, useRef } from "react";
import { CardBody, CardWrapper } from "../Atoms/Card";
import { apiUrl } from "../../shared/url";
import AttachmentViewer from './molecules/AttachmentViewer';
import { cleanAttachmentName } from "./ticketsHelpers/ticketHelperFunctions";
import CornerUpLeft from "../Icons/CornerUpLeft";
import IconButton from "../Atoms/IconButton";
import BackButton from "../Atoms/BackButton";
import { useNavigate } from "react-router-dom"; 
import Modal from "../Atoms/Modal";
import useModalAndDrawer from "../../hooks/useModalAndDrawer";
import ReplyForm from "./molecules/ReplyForm";
import { Tooltip, useTheme } from '@mui/material';
import { getCookie } from "../../shared/authHelpers";
import Avatar from "../Atoms/Avatar";
import React from "react";
import StatusDropDown from './molecules/StatusDropDown';
import AssigneeDropDown from './molecules/AssigneeDropDown';
import PriorityDropDown from './molecules/PriorityDropDown';
import Chip from "../Atoms/Chip";
import Flag from "../Icons/Flag";
import useTicketActions from "../../hooks/useTicketActions";
import AgentChip from './molecules/AgentChip';
import HistoriaCotizacion from "../cotizaciones/molecules/HistoriaCotizacion";
import Layers from "../Icons/Layers";
import TimeTeller from "../molecules/TimeTeller";
import CommentsTickets from "./molecules/CommentsTickets";
import MessageCircle from "../Icons/Message-Circle";
import Cotizacion from "../cotizaciones/Cotizacion";
import useGeneratePDFFromComponent from "../../hooks/useGeneratePDFFromComponent";
import CrearCotizaciones from "../cotizaciones/CrearCotizaciones";
import FileText from "../Icons/File-text";
import CloudDownload from "../Icons/Cloud-download";
import QuoteProcessingStatus from './molecules/QuoteProcessingStatus';
import { useDispatch } from 'react-redux';
import { updateTicket as updateTicketInStore } from '../../app/ticketsReducer';
import { errorMessage } from "../../app/messagesReducer";

// Use the minified version:
const parse = require("html-react-parser/dist/html-react-parser.min.js");


//reminder flow is ticketdetail -> attachmentviewer -> cotizacion -> postcrear 
//also flow is ticketdetail -> replyform 
//ticketdetal holds a lot of state so that it flows well between components



const TicketDetail = () => {

    const {ticketId} = useParams();
    const [ticket, setTicket] = useState(null);
    const navigate = useNavigate();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = useState();
    const smallBottomSwipeDrawers = ['reply']
    const [showReplyForm, setShowReplyForm] = useState(false);
    const replyFormRef = useRef(null);
    const [initialAttachmentIndex, setInitialAttachmentIndex] = useState(0);
    const [initialMessageIndex, setInitialMessageIndex] = useState(0);
    const [agents, setAgents] = useState([]);
    const [agentsLoading, setAgentsLoading] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [newMessages, setNewMessages] = useState(0);
    const [ticketUpdates, setTicketUpdates] = useState([]);

    const hookValues = useGeneratePDFFromComponent();
    const { generatePDFFromComponent, isAttaching, attachStatus } = hookValues;
    
    useEffect(() => {
        console.log('Hook values in TicketDetail:', hookValues);
    }, [hookValues.isAttaching, hookValues.attachStatus]);

    const dispatch = useDispatch();

    const updateTicket = (ticketId, updatedTicket) => {
        setTicket(updatedTicket);
        dispatch(updateTicketInStore(updatedTicket));
    };

    const theme = useTheme();

    const { 
        handleTicketStatusChange, 
        handleTicketAgentChange, 
        handleTicketPriorityChange 
    } = useTicketActions(updateTicket);

    const getTicket = async () => {
        console.log('getTicket');
        try {
            const options = createOptions('GET', null);
            const response = await goFetch(`${apiUrl}tickets/${ticketId}`, options);            
            setTicket(response.ticket);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchAgents = async () => {
        setAgentsLoading(true);
        try {
            const options = createOptions('GET', null, true);
            const response = await goFetch(`${apiUrl}users/agents`, options);            
            setAgents(response.agents);
            
        } catch (error) {
            console.error('Error fetching agents:', error);
        } finally {
            setAgentsLoading(false);
        }
    };

    

    useEffect(() => {
        getTicket();
        fetchAgents();
    }, []);

    useEffect(() => {
        if (showReplyForm && replyFormRef.current) {
            replyFormRef.current.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [showReplyForm]);

   

    const loadNewMessages = async () => {
        if (ticketUpdates.length > 0) {
            await getTicket();
            setNewMessages(0);
            setTicketUpdates([]);
        }
    };

    const handleReplySubmit = async (formData) => {
        try {
            // Specific options for FormData submission
            const formDataOptions = {
                method: 'POST',
                body: formData,
                headers: {
                    // Only include authorization header
                    Authorization: `bearer ${getCookie('token')}`
                }
            };

            const response = await goFetch(`${apiUrl}tickets/reply/${ticketId}`, formDataOptions);
            console.log('Reply submitted successfully:', response);
            if(response.error){
                dispatch(errorMessage(response.error))
                return;
            }
            // Update both local and Redux state with the response.ticket
            const updatedTicket = response.ticket;
            setTicket(updatedTicket);
            dispatch(updateTicketInStore(updatedTicket));
            
            setShowReplyForm(false);
        } catch (error) {
            dispatch(errorMessage(error.error))
            console.error('Error submitting reply:', error);
        }
    };

    const getPriorityColor = (priority) => {
        const theme = {
            palette: {
                success: { main: '#4caf50' },
                warning: { main: '#ff9800' },
                error: { main: '#f44336' },
                grey: { 400: '#9e9e9e' }
            }
        };
        switch(priority) {
            case 'Baja':
                return theme.palette.success.main;
            case 'Media':
                return theme.palette.warning.main;                       
            case 'Alta':
                return theme.palette.error.main;
            default:
                return theme.palette.grey[400];
        }
    };

    const getPriorityLabel = (priority) => {
        switch(priority) {
            case 'Baja':
                return 'Baja';
            case 'Media':
                return 'Media';
            case 'Alta':
                return 'Alta';            
            default:
                return '';
        }
    };

    const getChipProps = () => {
        if(ticket?.status === 'Abierto') return { success: true };
        if(ticket?.status === 'Trabajando') return { warning: true };
        if(ticket?.status === 'Reabierto') return { success: true };
        if(ticket?.status === 'Cerrado') return { error: true };
        return { success: true }; // default
    };

    const getPriorityChipProps = (priority) => {
        switch(priority) {
            case 'Baja':
                return { success: true };
            case 'Media':
                return { warning: true };
            case 'Alta':
                return { error: true };
            default:
                return { success: true };
        }
    };

    const handleOnClick = (formType) => {
        setFormType(formType);
        setOpenModal(true);
    }

    const handleBack = () => {
        navigate('/app/tickets', { state: { from: 'ticket-detail' } });
    };
    
    // The callback passed to child
    const handleGeneratePdf = async (dataNeededForPdf, filename) => {
        console.log('handleGeneratePdf called with:', dataNeededForPdf?.cotizacionNumber);
        console.log('Current states before generation - isAttaching:', isAttaching, 'attachStatus:', attachStatus);
        
        try {
            const blob = await generatePDFFromComponent(
                Cotizacion, 
                {
                    cotizacion: dataNeededForPdf,
                    createPDF: true
                },
                filename
            );

            

            if (!blob) {
                throw new Error('No PDF blob was generated');
            }

            // Create an object containing both the blob and filename
            const pdfData = {
                blob: blob,
                filename: filename
            };

            setPdfBlob(pdfData);
            //open Reply Form
            setShowReplyForm(true);
            return blob;

        } catch (error) {
            console.error('Error in PDF generation:', error);
            throw error;
        }
    };

    // Add this function to refresh ticket data
    const refreshTicket = async () => {
        await getTicket();
    };

    return (
        <ExternalWrapper>
            <InternalWrapper>
                <BackButton onClick={handleBack} />
                <StyledCardWrapper>
                    <HeaderWrapper>
                       
                        <TitleRow>
                            <CardHeaderTitle>{ticket?.subject}</CardHeaderTitle>
                        </TitleRow>
                        <BottomRow>
                            <ChipsWrapper>
                                {ticket?.agent && (
                                    <AgentChip agent={ticket.agent} />
                                )}
                                <Chip 
                                    label={ticket?.status} 
                                    {...getChipProps()}
                                />
                                {ticket?.priority && ['Baja', 'Media', 'Alta'].includes(ticket?.priority) && (
                                    <Chip 
                                        icon={<Flag size={14} color={getPriorityColor(ticket?.priority)} />}
                                        label={getPriorityLabel(ticket?.priority)}
                                        outline
                                        {...getPriorityChipProps(ticket?.priority)}
                                    />
                                )}
                            </ChipsWrapper>
                            <ActionsWrapper>
                                <IconButton onClick={()=>handleOnClick("modalAttachmentViewer")}>
                                    <FileText 
                                        size={'20'} 
                                        color={ticket?.cotizacion?.cotizacionId 
                                            ? theme.palette.success.light 
                                            : theme.palette.grey[700]
                                        }                                        
                                    />
                                </IconButton>
                                <IconButton onClick={()=>handleOnClick("viewComments")}>
                                    <MessageCircle size={'20'} color={theme.palette.grey[700]}/>
                                </IconButton>
                                <IconButton onClick={()=>handleOnClick("verHistoria")}>
                                    <Layers size={'20'} color={theme.palette.grey[700]}/>
                                </IconButton>
                                <PriorityDropDown 
                                    onPriorityChange={(priority) => handleTicketPriorityChange(ticket._id, priority)}
                                    isClosed={ticket?.status === 'Cerrado'}
                                    currentPriority={ticket?.priority}
                                />
                                <StatusDropDown 
                                    onStatusChange={(status) => handleTicketStatusChange(ticket._id, status)}
                                    currentStatus={ticket?.status}
                                    isClosed={ticket?.status === 'Cerrado'}
                                />
                                <AssigneeDropDown 
                                    onAssigneeChange={(agent) => handleTicketAgentChange(ticket._id, agent)}
                                    isClosed={ticket?.status === 'Cerrado'}
                                    agents={agents}
                                    loading={agentsLoading}
                                    currentAgent={ticket?.agent}
                                />
                                <ReplyButton onClick={() => setShowReplyForm(true)}>
                                    <CornerUpLeft />
                                </ReplyButton>
                            </ActionsWrapper>
                        </BottomRow>
                    </HeaderWrapper>                                                            
                </StyledCardWrapper>
                
                {console.log('Ticket metadata:', ticket?.metadata)}
                {ticket?.metadata?.isQuoteRequest && (
                    <QuoteProcessingStatus ticketId={ticket._id} ticket={ticket} />
                )}
                
                {ticket?.messages && ticket?.messages.length > 0 && ticket?.messages.map((message, index) => (
                    <StyledCardWrapper key={index}> 
                            <CardBody>
                                <MessageContent 
                                    key={index} 
                                    message={message} 
                                    ticket={ticket} 
                                    index={index} 
                                    setInitialAttachmentIndex={setInitialAttachmentIndex} 
                                    setOpenModal={setOpenModal}
                                    setFormType={setFormType}
                                    setInitialMessageIndex={setInitialMessageIndex}
                                    />
                            </CardBody>
                    </StyledCardWrapper>
                        ))}                                            

                
                {showReplyForm && (
                    <div ref={replyFormRef}>
                        <ReplyForm
                            originalTicket={ticket}
                            onSubmit={handleReplySubmit}
                            onCancel={() => setShowReplyForm(false)}
                            preAttachedPdf={pdfBlob}
                        />
                    </div>
                )}
                
            </InternalWrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer={smallBottomSwipeDrawers.includes(formType)}                                    
            >
                
                {
                    formType === 'modalAttachmentViewer' && ticket?.messages?.[initialMessageIndex] && (
                        <AttachmentViewer                            
                            attachments={[
                                ...((ticket.messages[initialMessageIndex].attachments || [])
                                    .filter(att => att.showInViewer)
                                    .map(att => ({
                                        ...att,
                                        url: att.signedUrl || att.url // Ensure we use signedUrl if available
                                    }))),
                                ...((ticket.messages[initialMessageIndex].inlineAttachments || [])
                                    .filter(att => att.showInViewer)
                                    .map(att => ({
                                        ...att,
                                        url: att.signedUrl || att.url // Ensure we use signedUrl if available
                                    })))
                            ]}
                            initialIndex={initialAttachmentIndex}
                            message={ticket.messages[initialMessageIndex]}
                            setOpenModal={setOpenModal}
                            setFormType={setFormType}
                            ticket={ticket}
                            handleGeneratePdf={handleGeneratePdf}   
                            isAttaching={isAttaching}
                            attachStatus={attachStatus}
                            refreshTicket={refreshTicket}
                        />
                    )
                }
                
                {
                    formType === 'verHistoria' && (
                       <HistoriaCotizacion
                            cotizacion={ticket}
                            close={close}
                       />
                    )
                }
                {
                    formType === 'viewComments' && (
                        <CommentsTickets
                            ticketId={ticketId}
                            comments={ticket.comentarios}
                            close={close}
                            setFormType={setFormType}
                            setOpenModal={setOpenModal}
                            addComment={true}
                            setTicket={setTicket}
                        />
                    )
                }
            </Modal>
        </ExternalWrapper>
    )
}



const MessageContent = ({ 
    message, 
    ticket, 
    index, 
    setInitialAttachmentIndex, 
    setOpenModal, 
    setFormType,
    setInitialMessageIndex
}) => {
    
    if (!message) return null;

    const senderName = index === 0 
        ? ticket?.creator?.name 
        : message?.sender?.name;
        
    const senderEmail = index === 0 
        ? ticket?.creator?.email 
        : message?.sender?.email;

    // Get first character of name, fallback to email if no name
    const avatarInitial = senderName 
        ? senderName.charAt(0).toUpperCase()
        : senderEmail.charAt(0).toUpperCase();
    

    const messageDate = message.createdAt || message.date;

    // Compute the attachments that should be passed to the viewer:
    // Only include attachments flagged with showInViewer.
    const getViewerAttachments = () => {
        const normalAttachments = (message.attachments || []).filter(att => att.showInViewer);
        const inlineAttachmentsWithViewer = (message.inlineAttachments || []).filter(att => att.showInViewer);
        return [...normalAttachments, ...inlineAttachmentsWithViewer];
    };

    // For displaying the attachments list, show all normal attachments plus inline attachments (if flagged)
    const getDisplayedAttachments = () => {
        const normalAttachments = message.attachments || [];
        const inlineAttachmentsWithViewer = (message.inlineAttachments || []).filter(att => att.showInViewer);
        return [...normalAttachments, ...inlineAttachmentsWithViewer];
    };

    // When an attachment is clicked, compute its index within the viewerAttachments array.
    const handleAttachmentClick = (attachment) => {
        const viewerAttachments = getViewerAttachments();
        const viewIndex = viewerAttachments.findIndex(att => att === attachment);
        console.log("Attachment clicked:", {
            attachment,
            signedUrl: attachment.signedUrl,
            viewerAttachments,
            viewIndex
        });
        
        if (viewIndex >= 0) {
            const messageIndex = ticket?.messages?.findIndex(m => m._id === message._id);
            setInitialAttachmentIndex(viewIndex);
            setInitialMessageIndex(messageIndex);
            setOpenModal(true);
            setFormType('modalAttachmentViewer');
        }
    };

    // Get recipients info
    const getRecipients = () => {
        // All recipients are in the messages array
        const currentMessage = ticket.messages[index];
        
        // console.log('Current Message:', currentMessage);
        // console.log('To:', currentMessage?.to);
        // console.log('CC:', currentMessage?.cc);
        // console.log('BCC:', currentMessage?.bcc);
        
        return {
            to: currentMessage?.to || [],
            cc: currentMessage?.cc || [],
            bcc: currentMessage?.bcc || []
        };
    };

    const { to, cc, bcc } = getRecipients();

    // Log the processed recipients
    //console.log('Processed Recipients:', { to, cc, bcc });

    const formatRecipient = (recipient) => {
        if (!recipient) return '';
        return recipient.name ? `${recipient.name} <${recipient.email}>` : recipient.email;
    };

    const renderRecipients = (recipients) => {
        if (!recipients || recipients.length === 0) return 'Sin destinatario';
        return recipients.map(formatRecipient).join(', ');
    };

    const processHtml = (content) => {
        if (!content) return '';
        
        //console.log('Processing HTML content:', content);
        //console.log('Message inline attachments:', message.inlineAttachments);
        
        // Parse the HTML into a temporary document
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const images = doc.getElementsByTagName('img');
        
        //console.log('Found images:', images.length);
        if (images.length === 0) {
            //console.log('ℹ️ No <img> tags found in the HTML content.');
        }
        
        // Get inline attachments from the message (with S3 URLs)
        const inlineAttachments = message.inlineAttachments || [];
        const usedAttachmentIds = new Set(); // Use a Set to track used IDs
        
        Array.from(images).forEach((img, index) => {
            const src = img.getAttribute('src');
            let contentId = img.getAttribute('data-cid') || (src && src.startsWith('cid:') ? src.substring(4) : null);
            
            // console.log(`\nProcessing image ${index + 1}:`);
            // console.log('- Original src:', src);
            // console.log('- Content ID:', contentId);
            
            if (contentId) {
                // Clean up contentId by removing any angle brackets
                contentId = contentId.replace(/[<>]/g, '');
        
                // Find matching inline attachment
                const matchingAttachment = inlineAttachments.find(att => {
                    const attContentId = att.contentId || '';
                    const attFilename = att.filename || '';
                    const attUrl = att.url || '';
        
                    // console.log('- Comparing with attachment:', {
                    //     contentId: attContentId,
                    //     filename: attFilename,
                    //     url: attUrl
                    // });
        
                    return (
                        attContentId.includes(contentId) || 
                        contentId.includes(attContentId) ||
                        attFilename.includes(contentId) ||
                        contentId.includes(attFilename)
                    );
                });
        
                if (matchingAttachment) {
                    // Mark the attachment ID as used in the Set
                    // Use a unique identifier like _id or contentId
                    // We used go have matchingAttachment.used = true here but it was giving problems in production.
                    usedAttachmentIds.add(matchingAttachment._id || matchingAttachment.contentId); 
                    // Use signedUrl if available, fallback to url
                    img.setAttribute('src', matchingAttachment.signedUrl || matchingAttachment.url);
                    img.setAttribute('data-cid', contentId);
                } else {
                    console.log('❌ No matching attachment found for contentId:', contentId);
                }
            } else {
                console.log('ℹ️ No content ID found for image');
            }
            
            // Ensure responsive styling
            img.style.maxWidth = '100%';
            img.style.height = 'auto';
        });
        
        // You can use the usedAttachmentIds Set later if needed, for example:
        // console.log('IDs of attachments used in HTML:', usedAttachmentIds);

        return doc.body.innerHTML;
    };

    const displayedAttachments = getDisplayedAttachments();

    return (
        <ContentWrapper>
            <SenderWrapper>
                <SenderInfo>
                    <SenderTopRow>
                        <Avatar>{avatarInitial}</Avatar>
                        <SenderNameWrapper>
                            {senderName ? (
                                <NameAndActionWrapper>
                                    <Tooltip title={senderEmail} placement="right">
                                        <SenderName>{senderName}</SenderName> 
                                    </Tooltip>
                                    {index > 0 && <RespondioText>respondió</RespondioText>}
                                </NameAndActionWrapper>
                            ) : (
                                <SenderName>{senderEmail}</SenderName>
                            )}
                            
                            <TimeTeller date={messageDate} />
                        </SenderNameWrapper>
                    </SenderTopRow>
                    <RecipientsWrapper>
                        <RecipientLine>
                            <RecipientLabel>Para:</RecipientLabel>
                            <RecipientText>{renderRecipients(to)}</RecipientText>
                        </RecipientLine>
                        {cc.length > 0 && (
                            <RecipientLine>
                                <RecipientLabel>CC:</RecipientLabel>
                                <RecipientText>{renderRecipients(cc)}</RecipientText>
                            </RecipientLine>
                        )}
                        {bcc.length > 0 && (
                            <RecipientLine>
                                <RecipientLabel>BCC:</RecipientLabel>
                                <RecipientText>{renderRecipients(bcc)}</RecipientText>
                            </RecipientLine>
                        )}
                    </RecipientsWrapper>
                </SenderInfo>
            </SenderWrapper>
            <MessageBody>
                <div 
                    dangerouslySetInnerHTML={{ 
                        __html: processHtml(message.content) 
                    }}
                    style={{
                        maxWidth: '100%',
                        overflow: 'auto'
                    }}
                />
            </MessageBody>
            
            {displayedAttachments.length > 0 && (
                <AttachmentsSection>
                    <AttachmentsTitle>Attachments</AttachmentsTitle>
                    <AttachmentsList>
                        {displayedAttachments.map((attachment, idx) => (
                            <AttachmentItem 
                                key={attachment._id || idx}
                                onClick={(e) => {
                                    // Only trigger handleAttachmentClick if the click wasn't on the download button
                                    if (!e.target.closest('a')) {
                                        attachment.showInViewer && handleAttachmentClick(attachment);
                                    }
                                }}
                                style={{ cursor: attachment.showInViewer ? 'pointer' : 'default' }}
                            >
                                <AttachmentIcon>📎</AttachmentIcon>
                                <AttachmentInfo>
                                    <AttachmentName>
                                        {cleanAttachmentName(attachment.originalName || attachment.filename)}
                                    </AttachmentName>
                                    <AttachmentSize>
                                        {(attachment.size / 1024).toFixed(1)} KB
                                    </AttachmentSize>
                                </AttachmentInfo>

                                <IconButton
                                    as="a" 
                                    href={attachment.signedUrl || attachment.url} 
                                    target="_blank" 
                                    download
                                >
                                    <CloudDownload size={20} />
                                </IconButton>
                                
                            </AttachmentItem>
                        ))}
                    </AttachmentsList>
                </AttachmentsSection>
            )}             
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;    
    padding: 16px;
    
    @media ${p=>p.theme.media.tablet}{
        padding: 20px;
    }
`;

const MessageBody = styled.div`
    border-top: 1px solid ${props => props.theme.palette.divider};
    font-size: 14px;
    line-height: 1.6;
    color: ${props => props.theme.palette.text.primary};
    white-space: pre-wrap;
    margin: 12px 0;
    padding: 12px 0;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 8px 0;
    }

    table {
        max-width: 100%;
        overflow-x: auto;
        display: block;
    }

    * {
        max-width: 100%;
    }
`;

const AttachmentsSection = styled.div`
    border-top: 1px solid ${props => props.theme.palette.divider};
    padding-top: 16px;
`;

const AttachmentsTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px 0;
    color: ${props => props.theme.palette.text.primary};
`;

const AttachmentsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: ${props => props.theme.palette.background.paper};
    border-radius: 6px;
    border: 1px solid ${props => props.theme.palette.divider};
    flex-wrap: wrap;
    gap: 8px;
    
    @media ${p=>p.theme.media.tablet}{
        padding: 8px 12px;
        flex-wrap: nowrap;
    }
`;

const AttachmentIcon = styled.span`
    font-size: 20px;
    margin-right: 12px;
`;

const AttachmentInfo = styled.div`
    flex: 1;
    min-width: 0;
`;

const AttachmentName = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.palette.text.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    
    @media (max-width: 600px) {
        max-width: 150px;
    }
`;

const AttachmentSize = styled.div`
    font-size: 12px;
    color: ${props => props.theme.palette.text.secondary};
`;

const DownloadButton = styled.a`
    padding: 6px 12px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    border-radius: 4px;
    font-size: 12px;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${props => props.theme.palette.primary.dark};
    }
`;

const ExternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`

const InternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 4px;
    gap: 8px;

    @media ${p=>p.theme.media.tablet}{
        padding: 0 24px;
    }
`

const CardHeaderTitle = styled.h1`
    font-size: 24px;
    font-weight: 600;
`
const StyledCardWrapper = styled(CardWrapper)`
    width: 100%;
    min-width: unset;
    box-shadow: none;
    margin:0;
    gap: 8px;
    
    
    @media ${p=>p.theme.media.tablet}{
        min-width: 600px;        
    }
`;

const SenderWrapper = styled.div`
    margin-bottom: 12px;
`;

const SenderInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const SenderTopRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const RespondioText = styled.span`
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.875rem;
    font-style: italic;
`;

const SenderName = styled.span`
    font-weight: 600;
    color: ${props => props.theme.palette.text.primary};
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
    
    &:hover {
        text-decoration: ${props => props.onClick ? 'underline' : 'none'};
    }
`;

const SenderNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const NameAndActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
`;

const TitleRow = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ChipsWrapper = styled.div`
    display: flex;
    gap: 8px;
    
`;

const ActionsWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const ReplyButton = styled(IconButton)`
    min-width: 100px;
`;


const RecipientsWrapper = styled.div`
    margin-left: 40px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const RecipientLine = styled.div`
    display: flex;
    gap: 8px;
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.875rem;
`;

const RecipientLabel = styled.span`
    min-width: 35px;
`;

const RecipientText = styled.span`
    word-break: break-word;
`;



export default TicketDetail;
