import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    filter: {
        status: [],
        priority: [],
        agent: '',
        search: ''
    }
}



export const ticketFilterSlice = createSlice({
    name: 'ticketFilter',
    initialState,
    reducers: {
        setFilter(state, action) {
            console.log(action.payload)
            state.filter = action.payload
        }
    }
})

const { actions, reducer } = ticketFilterSlice

export const { setFilter } = actions

export default reducer



