import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import BeatLoader from 'react-spinners/BeatLoader'
import ThumbsUp from '../Icons/ThumbsUp';
import { css } from "@emotion/react";
import { Link } from 'react-router-dom';

const IconButton = React.forwardRef(({
    children,
    to,
    as,
    color,
    outline,
    loading,
    successEvent,
    onClick,
    successIcon,
    type,
    size,
    state,
    ...props
}, ref) => {
    const theme = useTheme();
    let widthAndHeight = "50px";

    if (size === "small") {
        widthAndHeight = '40px'
    }

    if (to) {
        return (
            <StyledIconLink
                ref={ref}
                color={color}
                outline={outline}
                to={to}
                state={state}
                {...props}
            >
                {children}
            </StyledIconLink>
        )
    }

    const override = css`
        line-height: 0; `;

    return (
        <StyledIconButton
            ref={ref}
            as={as}
            type={type}
            color={color}
            outline={outline}
            onClick={onClick}
            style={{ '--width-and-height': widthAndHeight }}
            {...props}
        >
            {children}
            {(loading || successEvent) && (
                <Layover>
                    {loading && <BeatLoader css={override} size={7} color={color} />}
                    {successEvent && <LayoverAnimatedIconWrapper>{successIcon ? successIcon : <ThumbsUp color={theme.palette.success.light} />}</LayoverAnimatedIconWrapper>}
                </Layover>
            )}
        </StyledIconButton>
    )
});

const bounce = keyframes`
    0%{
        transform: scale(0.5)
    }

    50%{
        transform: scale(0.75)
    }

    80%{
        transform: scale(1)
    }

    90%{
        transform: scale(1.2)
    }

    95%{
        transform: scale(1.1)
    }
    100%{
        transform: scale(1.0)

    }
`

const StyledIconButton = styled.button`
    position:relative;
    border-radius: 50%;
    font-size: 1.5rem;
    width:var(--width-and-height);
    height:var(--width-and-height);
    aspect-ratio: 1;

    display:grid;
    place-content:center;
    
    text-align:center;
    background-color: ${p=>p.color && !p.outline ? p.color:'transparent'};
    border:${p=>p.outline ? `1px solid ${p.color || p.theme.palette.grey[400]}` : '0'};
    outline:0;
    
    cursor: pointer; 
    overflow:hidden;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }    

`

const StyledIconLink = styled(Link)`
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 12px;
    text-align:center;
    background-color: ${p=>p.color && !p.outline ? p.color:'transparent'};
    border:${p=>p.outline ? `2px solid ${p.color || p.theme.palette.grey[400]}` : '0'};
    outline:0;
    display: inline-flex;
    align-self: center;
    cursor: pointer; 
    overflow:hidden;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04);
    }
`

const Layover = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius: 4px;
    display: grid;
    place-content:center;
    background-color: #ffff;
    
`

const LayoverAnimatedIconWrapper= styled.div`
    animation: ${bounce} 1s ease-out;
`

export default IconButton;