import styled from "@emotion/styled/macro";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card";
import CardInfo from "../../Atoms/CardInfo";
import useDateAndTimeFormatter from "../../../hooks/useDateAndTimeFormatter";
import IconButton from "../../Atoms/IconButton";
import Plus from "../../Icons/Plus";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import X from "../../Icons/X";
import Send from "../../Icons/Send";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import useCommentMaker from "../../../hooks/useCommentMaker";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import { useTheme } from "@mui/material";

const CommentsTickets = ({    
    setTicket,
    ticketId,
    comments,
    close,
    setFormType,    
    setOpenModal,    
}) => {
    const theme = useTheme();
    const [showTextField, setShowTextField] = useState(false);
    const [newComment, setNewComment] = useState('');
    const commentMaker = useCommentMaker();
    const accionMaker = useAccionMaker();
    const user = useCurrentUser();
    const { dateFormat } = useDateAndTimeFormatter();
    const [isLoading, setIsLoading] = useState(false);
    
    // Create a ref to the end of the comment list.
    const commentsEndRef = useRef(null);
    // Create a ref for the send icon button.
    const sendButtonRef = useRef(null);

    // Scroll down to the bottom whenever the comments change or when the text field opens/closes.
    useEffect(() => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [comments, showTextField]);

    // When the user types and presses Enter (without Shift) or the Down Arrow,
    // move focus to the send button.
    const handleKeyDown = (e) => {
        if ( e.key === "ArrowDown") {
            e.preventDefault();
            if (sendButtonRef.current) {
                sendButtonRef.current.focus();
            }
        }
    };

    const onClickAddComment = () => {
        setShowTextField(true);
    };

    const handleSubmitComment = async () => {
        if (newComment.trim()) {
            setIsLoading(true);
            const commentObj = commentMaker(newComment);
            const accion = accionMaker('Se agregó un nuevo comentario');
    
            const info = {            
                comentarios: commentObj,
                accion
            };
    
            const options = createOptions("PUT", info);
    
            try {   
                const response = await goFetch(`${apiUrl}tickets/comment/${ticketId}`, options);                
                setTicket(response.ticket);
                setNewComment('');
                setShowTextField(false);                
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        }
    };

    return (
        <StyledCommentWrapper>
            <CardTitle>Comentarios</CardTitle>
            {(!comments || comments.length === 0) && (
                <CardInfo>No hay comentarios.</CardInfo>
            )}
            <StyledCommentBody hasInput={showTextField}>
                {comments?.map((comment, index) => {
                    const userIsAuthor = user._id === comment.autor._id;
                    return (
                        <Comment key={`${comment}_${index}`} userIsAuthor={userIsAuthor}>
                            <Author>{`${comment.autor.firstname} ${comment.autor.lastname} ${dateFormat(comment.fecha)}`}</Author>
                            {comment.comentario}
                        </Comment>
                    );
                })}
                {/* This empty div is used to scroll to the bottom */}
                <div ref={commentsEndRef} />
            </StyledCommentBody>
            {showTextField ? (
                <CommentInput>
                    <StyledTextField 
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Escriba su comentario..."
                        multiline
                        rows={3}
                    />
                    <CommentInputActions>
                        <StyledIconButton
                            size="small"
                            onClick={() => setShowTextField(false)}
                            tabIndex={2}
                        >
                            <X color={theme.palette.error.light}/>
                        </StyledIconButton>
                        <StyledIconButton
                            size="small"
                            onClick={handleSubmitComment}
                            ref={sendButtonRef}
                            tabIndex={1}
                            loading={isLoading}
                        >
                            <Send color={theme.palette.success.light}/>
                        </StyledIconButton>                        
                    </CommentInputActions>
                </CommentInput>
            ) : (
                <CommentActions>
                    <IconButton size="small" onClick={onClickAddComment}><Plus/></IconButton>
                </CommentActions>
            )}
        </StyledCommentWrapper>
    );
};

const lightGreen = '#c8e6c9';
const lightBlue = '#bbdefb';

const StyledCommentWrapper = styled.div`
    border: 1px solid ${p => p.theme.palette.grey[200]};
    background-color: #ffff;
    border-radius: 8px;
    max-width: 400px;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    @media ${p => p.theme.media.tablet} {
        padding-left: 18px;
        padding-right: 18px;
        min-width: 400px;
        min-height: 300px;
    }
`;

const StyledCommentBody = styled(CardBody)`
    display: flex;
    flex-direction: column;
    overflow-y: auto;        
`;

const Comment = styled.div`    
    border-radius: 12px;
    padding: 8px;
    width: 300px;
    margin-bottom: 8px;
    font-size: 0.875rem;
    align-self: ${p => p.userIsAuthor ? 'flex-end' : 'flex-start'};
    background-color: ${p => p.userIsAuthor ? lightBlue : lightGreen};
    box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 63% / 0.34),
                0.4px 0.8px 1px -1.2px hsl(0deg 0% 63% / 0.34),
                1px 2px 2.5px -2.5px hsl(0deg 0% 63% / 0.34);
`;

const Author = styled.p`
    margin: 0px;
    margin-bottom: 4px;
    font-size: 0.65rem;
`;

const CommentActions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CommentInput = styled.div`
    margin-top: 8px;
    width: 100%;
`;

const StyledTextField = styled.textarea`
    width: 100%;
    padding: 8px;
    border: 1px solid ${p => p.theme.palette.grey[300]};
    border-radius: 8px;
    resize: none;
    font-family: inherit;
    &:focus {
        outline: none;
        border-color: ${p => p.theme.palette.primary.main};
    }
`;

const CommentInputActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;
`;

const StyledIconButton = styled(IconButton)`
    &:focus {
        outline: 2px solid ${p => p.theme.palette.grey[400]};
    }
`;


export default CommentsTickets;