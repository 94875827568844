import React from 'react';
import styled from '@emotion/styled';
import { FormControl, TextField } from "@mui/material"
//import CustomInputMuiLabel from "../../Atoms/CustomMuiInputLabel"
import Select from '../../Atoms/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import useCommentMaker from '../../../hooks/useCommentMaker';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import CustomInputMuiLabel from '../../Atoms/CustomMuiInputLabel';




const ModifyStatus = ({
    cotizacion,
    cotizacionId,
    cotizaciones,
    setCotizaciones,
    close,
    
})=>{

    
 
    const [status, setStatus] = React.useState(cotizacion?.status);
    const [comments, handleChangeComments] = React.useState('')
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);  
    const commentMaker = useCommentMaker(comments);
    const accionMaker = useAccionMaker();

   
    const onChange =(event)=>{
        setStatus(event.target.value)
    }


    const onSubmit = (event) =>{
        event.preventDefault();
        const commentObj = commentMaker(comments);
        const accion = accionMaker(`Status de la cotizacion cambio a "${status}"`);

      const infoStatus = {
        status,
        accion,
        cotizacionId,
        comentarios: commentObj
      }
      updateCotizacion('update-status', infoStatus)  
      }



    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Status Cotizacion</FormTitle>
            <FormBody>
            <FormControl fullWidth>
            <CustomInputMuiLabel>{'Status'}</CustomInputMuiLabel>
                <Select
                    labelId={"Status"}
                    id={'Status'}
                    value={status}                
                    onChange={onChange}
                    name="status"
                >
                    <MenuItem value="Aprobada">Aprobada</MenuItem>
                    <MenuItem value="Rechazada">Rechazada</MenuItem>
                    <MenuItem value="Cancelada">Cancelada</MenuItem>
                    <MenuItem value="Creada">Creada</MenuItem>                
                </Select>
            </FormControl>
            <TextField
                    label="Comentarios"
                    multiline
                    variant= 'outlined'
                    rows={4}
                    value={comments}
                    onChange={(e)=>handleChangeComments(e.target.value)}
                />
            </FormBody>
            <FormActionsBasic
                type="submit"
                as='button'
                close={close}                          
                successEvent={successEvent}
                loading={isLoading}
            />

        </FormWrapper>
    )
}



export default ModifyStatus;