import styled from '@emotion/styled';
import { useTheme } from '@mui/system';
import React from "react";
import useCheckForm from "../../hooks/useCheckForm";
import useMediaQuery from "../../hooks/useMediaQuery";
import useSuccessButton from "../../hooks/useSuccessButton";
import { isCotizacion, isInRange, isNumber, validate } from "../../shared/validators";
import Button from "../Atoms/Button";
import FormActionsBasic from "../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../Atoms/Forms";
import IconButton from '../Atoms/IconButton';
import TextInput from "../Atoms/TextInput";
import Minus from "../Icons/Minus";
import Plus from "../Icons/Plus";
import Trash2 from '../Icons/Trash2';
import { newTotalCotizacionCalc, round, updateSelectedProductObject } from "./CotizacionesHelpers";


const convertToNumber = (value) => {
    console.log(value)
    const stringWithoutCommas = value.replace(/,/g, '');
    const numberValue = Number(stringWithoutCommas);
    return numberValue;
  };


  // check if value is number, if not conver to number
    const checkIfNumber = (value) => {
        if (typeof value === 'number') {
            return value;
        } else {
            return convertToNumber(value);
        }
    }



const EditProductoCotizacion = ({ 
    productoId, 
    close, 
    selectedProductos, 
    setSelectedProductos,
    selectedProductoEdit,
    setTotalCotizacion,
    currency
}) => { 
        
    const isTablet= useMediaQuery('tablet');  
    const theme = useTheme();  
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()                
    const [quantity, setQuantity] = React.useState(selectedProductoEdit.quantity);  

    const [values, setValues] = React.useState({
        price: selectedProductoEdit.clientPrice,
        percentDiscount: selectedProductoEdit.percentDiscount,
        discount: selectedProductoEdit.discount


    })

    
    const [errors, setErrors] = React.useState({
        price: "",
        discount: ""
    })

    const [formCompleted, errorCount] = useCheckForm(values,errors, [])


    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        console.log(name, value)
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }
    
    const increaseQuantity = ()=>{
        setQuantity(state=>state + 1)
    }

    const decreaseQuantity = ()=>{
        setQuantity(state=>{
            if(state ===1)return state
            else{
                state=state-1
                return state
            }
        })
    }

    const handleQuantity= (e)=>{
        const _quantity = e.target.value;
        console.log(isNaN(_quantity))
        if(_quantity === '') return setQuantity(Number(0))
        var regex=/^[0-9]+$/;
        if (!_quantity.match(regex))return
        setQuantity(Number(_quantity))
    }

    
    
    function applyPercentDiscount(input, number) {
             console.log('applying discount')
        // Remove percent sign and convert to decimal
        const percent = parseFloat(input) / 100;
      
        // Calculate discount and return discounted value
        const discountedValue = number - (number * percent);        
        return Number(discountedValue.toFixed(2)); // round to 2 decimal places
    }


    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
        }

        const onSubmit = (event) =>{
            event.preventDefault();
            //console.log(selectedProductos)
            const newSelectedProductos = selectedProductos?.map(producto=>{
                let newProducto
                if(producto._id === productoId){                
                    const newPrice = checkIfNumber(values.price)                
                    const percentDiscount = values.percentDiscount
                    newProducto = updateSelectedProductObject(producto,newPrice,quantity,percentDiscount)
                    console.log(newProducto)
                    return newProducto
                }else{
                    return producto
                }   
            })   
            
            console.log(newSelectedProductos)
            const newTotalCotizacion = newTotalCotizacionCalc(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
            setTotalCotizacion(newTotalCotizacion)
            close();
        }


        const handleDelete = ()=>{
            const newSelectedProductos = selectedProductos?.filter(producto=>producto._id !== productoId)
            const newTotalCotizacion = newTotalCotizacionCalc(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
            setTotalCotizacion(newTotalCotizacion)
            close();
        }

    
          




        return(
            <FormWrapper onSubmit={onSubmit}>
                
                <FormTitle isTablet={isTablet} style={{display:"flex", alignItems:"center", justifyContent: "space-between"}}>Editar
                    <IconButton><Trash2 color={theme.palette.error.main} size={18} onClick={handleDelete}/></IconButton>
                </FormTitle>
                <FormBody isTablet={isTablet} style={{height:"90%"}}>
                <CantidadWrapper >
                            
                    <div style={{width:'150px'}}>
                    <TextInput style={{textAlign: 'end'}} label={"Cantidad"} name={'cantidad'} value={quantity} onChange={handleQuantity}></TextInput>
                        </div>
                        
                    <Button primary onClick={increaseQuantity}><Plus/></Button>
                    <Button primary onClick={decreaseQuantity}><Minus/></Button>
                </CantidadWrapper>

                <TextInput 
                    label={"Precio"}
                    name="price"
                    style={{textAlign: 'end'}}
                    value={typeof values.price === 'number' ? round(values.price.toString()) : values.price} 
                    onChangeHandler={(event)=>onChangeValues(event, [isNumber], true)}
                    endIcon={currency === 'pesos'?<span>pesos</span> : <span>dolares</span>}
                    helperText={errors.price}
                    error={errors.price}
                    onBlurValidation={(event)=>onBlur(event, [isNumber])}
                    
                ></TextInput>    
                <TextInput 
                    label={"Descuento"} 
                    name="percentDiscount"
                    style={{textAlign: 'end'}}
                    value={values.percentDiscount} 
                    onChange={(event)=>onChangeValues(event, [()=>isInRange(0,100, event.target.value)], true)} 
                    endIcon={<span>%</span>}
                    helperText={errors.percentDiscount ? errors.percentDiscount : `Precio final: ${applyPercentDiscount(values.percentDiscount, values.price)} pesos`}
                    error={errors.percentDiscount}
                    onBlurValidation={(event)=>onBlur(event, [()=>isInRange(0,100, event.target.value)])}
                ></TextInput>    
                </FormBody>
                
                <FormActionsBasic
                    close={close}                
                    successText="Guardar"
                    successEvent={successEvent}
                    loading={isLoading}
                    type="submit"
                    as="button"
                    disabledSuccess={!formCompleted || errorCount > 0}
               />
                
            </FormWrapper>
        )
    
}



const CantidadWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;    
    width: 100%;
    
`


export default EditProductoCotizacion;