import styled from "@emotion/styled";
import { goFetch, createOptions} from "../../api/cirugiasApi";
import { useEffect, useState, useCallback, useRef } from "react";
import { apiUrl } from "../../shared/url";
import useMediaQuery from "../../hooks/useMediaQuery";
import IconButton from "../Atoms/IconButton";
import ChevronLeft from "../Icons/ChevronLeft";
import ChevronRight from "../Icons/ChevronRight";
import { useTheme } from "@mui/material";
import TicketCard from "./TicketCard";
import useTicketActions from "../../hooks/useTicketActions";
import { useDispatch, useSelector } from "react-redux";
import { setTicketsData, setTicketsLoading, updateTicket, setNewTicketsCount } from "../../app/ticketsReducer";
import { useLocation, useNavigate } from "react-router-dom";
import FiltroTickets from "./molecules/FiltroTickets";
import socket from '../../shared/socketConnection';
import RefreshIcon from '../Icons/RefreshIcon';
import BeatLoader from "react-spinners/BeatLoader";

const Tickets = () => {
    const dispatch = useDispatch();
    
    // Add console.log to debug the state
    const ticketsState = useSelector(state => {
        return state.tickets;
    });

    
    // Add null checks and default values
    const { 
        tickets = [],
        nextCursor = null,
        previousCursor = null,
        hasMore = false,
        totalCount = 0,
        position = 0,
        rangeStart = 0,
        rangeEnd = 0,
        isLoading = false,
        newTicketsCount = 0,
    } = ticketsState || {};

    const [itemsPerPage] = useState(25);
    const isTablet = useMediaQuery('tablet');    
    const theme = useTheme();
    const [agents, setAgents] = useState([]);
    const [agentsLoading, setAgentsLoading] = useState(false);
    const location = useLocation();

    const filter = useSelector(state => state.ticketFilter.filter);
    
    // Update socket listeners to dispatch to Redux
    useEffect(() => {
        console.log('Setting up socket listeners for tickets list');
        
        socket?.on('newTicket', (data) => {
            console.log('Received newTicket event:', {
                ticketId: data.ticket._id,
                subject: data.ticket.subject
            });
            dispatch(setNewTicketsCount(newTicketsCount + 1));
        });

        // socket?.on('ticketChange', (data) => {
        //     console.log('Received ticketChange event:', {
        //         ticketId: data.ticketId
        //     });
        //     dispatch(setNewTicketsCount(newTicketsCount + 1));
        // });

        return () => {
            console.log('Cleaning up socket listeners');
            socket?.off('newTicket');
            socket?.off('ticketChange');
        };
    }, [dispatch, newTicketsCount]);

    // Update loadNewTickets to dispatch to Redux
    const loadNewTickets = async () => {
        console.log('Refreshing tickets');
        //setIsRefreshing(true);
        try {
            await fetchTickets(null, 'next', filter);
            dispatch(setNewTicketsCount(0)); // Reset counter in Redux
        } finally {
            //setIsRefreshing(false);
        }
    };

    const fetchTickets = useCallback(async (cursor = null, direction = 'next', filter = {}) => {
        try {
            dispatch(setTicketsLoading());
            const options = createOptions('GET', null, true);
            const queryParams = new URLSearchParams();

            queryParams.append('limit', itemsPerPage);
            queryParams.append('direction', direction);
            // Use the updated position from our ref
            queryParams.append('position', position.toString());

            // Add search term from filter state
            if (filter.search?.trim()) {
                queryParams.append('search', filter.search.trim());
            }

            // Existing filter handling
            if (filter.status && filter.status.length > 0) {
                filter.status.forEach(status => queryParams.append('status', status));
            }

            if (filter.priority && filter.priority.length > 0) {
                filter.priority.forEach(priority => queryParams.append('priority', priority));
            }

            if (filter.agent) {
                queryParams.append('agent', filter.agent);
            }

            if (cursor) {
                queryParams.append('cursor', cursor);
            }

            console.log("Query params:", queryParams.toString());

            const response = await goFetch(`${apiUrl}tickets?${queryParams.toString()}`, options);

            if (response.success) {
                dispatch(setTicketsData({
                    tickets: response.tickets,
                    nextCursor: response.nextCursor,
                    previousCursor: response.previousCursor,
                    hasMore: response.hasMore,
                    totalCount: response.totalCount,
                    position: response.position,
                    rangeStart: response.rangeStart,
                    rangeEnd: response.rangeEnd
                }));
            }
        } catch (error) {
            console.log('Error fetching tickets:', error);
        }
    }, [dispatch, itemsPerPage, filter, position]);

    const fetchAgents = async () => {
        setAgentsLoading(true);
        try {
            const options = createOptions('GET', null, true);
            const response = await goFetch(`${apiUrl}users/agents`, options);            
            setAgents(response.agents);
            
        } catch (error) {
            console.error('Error fetching agents:', error);
        } finally {
            setAgentsLoading(false);
        }
    };

    useEffect(() => {
        // Initial mount effect
        const { state } = location;
        if (state?.from === 'ticket-detail') {
            // Just clean up location state
            return;
        } else {
            // Only fetch if not coming from ticket-detail
            fetchTickets();
        }
        fetchAgents();
    }, [location]); // Watch for location changes

    

    const handleNext = () => {
        console.log('Handle Next clicked:', {
            hasMore,
            nextCursor
        });
        
        if (hasMore && nextCursor) {
            fetchTickets(nextCursor, 'next', filter);
        }
    };

    const handlePrevious = () => {
        if (previousCursor) {
            fetchTickets(previousCursor, 'previous', filter);
        }
    };

    const handleTicketUpdate = (ticketId, updatedTicket) => {
        dispatch(updateTicket(updatedTicket));
    };

    const { 
        handleTicketStatusChange, 
        handleTicketAgentChange, 
        handleTicketPriorityChange 
    } = useTicketActions(handleTicketUpdate);
    

    return (
        <ExternalWrapper>
            <Wrapper>
                <TicketsWrapper>    
                    <PaginationWrapper>
                        <RefreshSection>
                            <NewTicketsButton onClick={loadNewTickets} hasNewTickets={newTicketsCount > 0}>
                                <RefreshIcon size={16} />
                                {newTicketsCount > 0 ? 
                                    `${newTicketsCount} new ticket${newTicketsCount > 1 ? 's' : ''}` : 
                                    (isLoading ? 
                                        <LoaderWrapper>
                                            <BeatLoader 
                                                size={8} 
                                                color={newTicketsCount > 0 ? 'white' : theme.palette.grey[600]} 
                                            /> 
                                        </LoaderWrapper>
                                        : 'Refresh'
                                    )
                                }
                            </NewTicketsButton>
                        </RefreshSection>
                        <IconSection>
                            <IconButton
                                onClick={handlePrevious}
                                disabled={!previousCursor}
                            >
                                <ChevronLeft color={previousCursor ? `${theme.palette.grey[600]}` : `${theme.palette.text}`} />
                            </IconButton>
                            
                            <PaginationContent>                            
                                <PaginationInfo>
                                    {tickets.length > 0 ? `${rangeStart}-${rangeEnd} of ${totalCount}` : 'No tickets'}
                                </PaginationInfo>
                            </PaginationContent>
                            
                            <IconButton
                                onClick={handleNext}
                                disabled={!hasMore}
                            >
                                <ChevronRight color={hasMore ? `${theme.palette.grey[600]}` : `${theme.palette.text}`} />
                            </IconButton>
                        </IconSection>
                    </PaginationWrapper>                                        
                    {
                        tickets?.map((ticket) => (
                           <TicketCard 
                               key={`${ticket._id}`} 
                               ticket={ticket}
                               agents={agents}
                               agentsLoading={agentsLoading}
                               onStatusChange={handleTicketStatusChange}
                               onAgentChange={handleTicketAgentChange}
                               onPriorityChange={handleTicketPriorityChange}
                           />                        
                        ))
                    }
                    
                </TicketsWrapper>
                {isTablet && (
                    <FiltroTickets agents={agents} onFilter={fetchTickets}/>
                )}
            </Wrapper>
        </ExternalWrapper>
    );
};

const ExternalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Wrapper = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    flex-direction: row;
    gap: 24px;
`

const TicketsWrapper = styled.div`
    display: flex;
    flex-direction: column;    
    width: 100%;
    flex: 3;
    position: relative;
`

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    padding: 4px;
    min-height: 36px;
`;

const PaginationInfo = styled.span`
    color: ${props => props.theme.palette.grey[600]};
    font-size: 14px;
    text-align: center;
`;

// Add new PaginationContent container
const PaginationContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 100px;
    justify-content: center;
`;

const RefreshSection = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25px;
    z-index: 10;
    pointer-events: none;
`;

const IconSection = styled.div`
    display: flex;
    align-items: center;    
`;

const NewTicketsButton = styled.button`
    pointer-events: auto;
    background-color: ${props => 
        props.hasNewTickets ? 
        props.theme.palette.primary.main : 
        props.theme.palette.grey[200]};
    color: ${props => 
        props.hasNewTickets ? 
        'white' : 
        props.theme.palette.grey[600]};
    border: none;
    border-radius: 20px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-height: 28px;
    
    &:hover {
        background-color: ${props => 
            props.hasNewTickets ? 
            props.theme.palette.primary.dark : 
            props.theme.palette.grey[300]};
    }

    &:active {
        transform: scale(0.98);
    }
`;

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
`;

export default Tickets;
