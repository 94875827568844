import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createOptions, goFetch } from '../../../api/cirugiasApi';
import { errorMessage} from '../../../app/messagesReducer';
import { updateProductosArray } from '../../../app/productosReducer';
import useCheckForm from '../../../hooks/useCheckForm';
import useSuccessButton from '../../../hooks/useSuccessButton';
import { apiUrl } from '../../../shared/url';
import { isMaxLength, validate } from '../../../shared/validators';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import TextInput from '../../Atoms/TextInput';




const ProductosEditar = ({
    producto,
    close
})=>{

    console.log(producto);

    const dispatch = useDispatch()    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();

    const [values, setValues] = React.useState({
        codigo: producto?.codigo,
        descripcion: producto?.descripcion,
        fabricante: producto?.fabricante,
        deactivated: producto?.deactivated,
        competitorNames: producto?.competitorNames || '',
        extendedDescription: producto?.extendedDescription || ''
    })

    const [errors, setErrors] = React.useState({
        codigo: "",
        descripcion: "",
        fabricante: "",
        deactivated: "",
        competitorNames: "",
        extendedDescription: ""
    })

    const onChangeHandler = (event)=>{
    const name = event.target.name;
    const value = event.target.value;
    setValues(prevState => ({...prevState, [name]:value}))
    } 


    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target
        const error = validate(null, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    const [formCompleted, errorCount] = useCheckForm(values,errors, [
        "codigo",
        "descripcion"
        ])

    console.log(errorCount)
    console.log(formCompleted)

    const onSubmit= async(event)=>{
        event.preventDefault();
        setIsLoading(true)

        const editarInfo={
            codigo: values.codigo,
            descripcion: values.descripcion,
            fabricante: values.fabricante,
            deactivated: values.deactivated,
            competitorNames: values.competitorNames,
            extendedDescription: values.extendedDescription
        }

     
        const options = createOptions("POST", editarInfo)
        try{
            const response = await goFetch(`${apiUrl}productos/edit/${producto._id}`, options)
            responseStateChanges(response, ()=>dispatch(updateProductosArray(response.producto)), ()=>close())                                            
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        

    }

    return(
        <StyledFormWrapper onSubmit={onSubmit}>
            <FormTitle>Editar producto</FormTitle>
            <StyledFormBody>
                <TextInput 
                    label="Codigo"
                    name="codigo"
                    value={values.codigo}
                    onChange={onChangeHandler}
                    onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(20,values.codigo)])}
                    helperText={!errors.codigo ? "Campo Obligatorio": errors.codigo}
                    error={errors.codigo}                    
                ></TextInput>
                <TextInput 
                    label="Descripcion"
                    name="descripcion"
                    value={values.descripcion}
                    onChange={onChangeHandler}
                    onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(200,values.descripcion)])}
                    helperText={!errors.descripcion ? "Campo Obligatorio": errors.descripcion}
                    error={errors.descripcion}                     
                ></TextInput>
                <TextInput 
                    label="Fabricante"
                    name="fabricante"
                    value={values.fabricante}
                    onChange={onChangeHandler}
                    onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(20,values.fabricante)])}
                    helperText={errors.fabricante}
                    error={errors.fabricante}                     
                ></TextInput>
                <TextInput 
                    label="Productos Competitivos"
                    name="competitorNames"
                    value={values.competitorNames}
                    onChange={onChangeHandler}
                    onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(500,values.competitorNames)])}
                    helperText="Lista de productos competitivos separados por comas"
                    error={errors.competitorNames}                     
                ></TextInput>
                <TextInput 
                    label="Descripción Extendida"
                    name="extendedDescription"
                    value={values.extendedDescription}
                    onChange={onChangeHandler}
                    onBlurValidation={(event)=>onBlur(event, [()=>isMaxLength(2000,values.extendedDescription)])}
                    helperText="Descripción detallada del producto"
                    error={errors.extendedDescription}
                    multiline
                    rows={8}                     
                ></TextInput>
            </StyledFormBody>
            <FormActionsBasic
                close={close}
                cancelText="Volver"                                
                loading={isLoading}
                successEvent={successEvent}   
                type="submit"
                as="button"       
                disabled={!formCompleted || errorCount>0}  
            />
            
        </StyledFormWrapper>
    )

}


const StyledFormWrapper = styled(FormWrapper)`
    padding-left:12px;
    padding-right:12px;
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }

`

const StyledFormBody = styled(FormBody)`
flex:2;
 margin-bottom: 18px;
`


export default ProductosEditar;