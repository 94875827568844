import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import { errorMessage } from '../../../app/messagesReducer';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import TextInput from "../../Atoms/TextInput"






const ProductosAprobados = ({ 
    cotizacion,
    cotizaciones,
    setCotizaciones,
    close
}) => {

    const dispatch = useDispatch();
    console.log(cotizacion.productos)
    const [productosArray, setProductosArray] = React.useState(cotizacion?.productos[cotizacion?.productos.length - 1]);    
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);
    const accionMaker = useAccionMaker();

    const onChangeHandler = (event, index) => {
        const cantidadAprobada = event.target.value;

        const newProductosArray= [...productosArray];
        console.log(index)
        console.log(newProductosArray[index])
        newProductosArray[index].cantidadAprobada = cantidadAprobada;
        setProductosArray(newProductosArray);               
    }


    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion = accionMaker(`Ingreso de productos aprobados`);

        //filter productosArray to get only the ones with cantidadAprobada. If no productos have cantidadAprobada console error.  Create new object with _id, descripcion, codigo, cantidadAprobada
        const productosAprobados = productosArray.filter(producto => producto.cantidadAprobada).map(producto => {
            return {
                _id: producto._id,
                codigo: producto?.codigo,
                descripcion: producto?.descripcion,                
                cantidadAprobada: producto?.cantidadAprobada
            }
        })

        if(productosAprobados.length === 0){            
            dispatch(errorMessage('Se debe colocar por lo menos un producto aprobado'))
            return
        }


        const productosAprobadosInfo ={
            productosAprobados: productosAprobados,
            cotizacionId: cotizacion._id,
            accion
        }

        console.log(productosAprobadosInfo)

        updateCotizacion('update-productos-aprobados', productosAprobadosInfo)
    }




    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Productos Aprobados</FormTitle>
            <FormBody>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableHeadRow>
                                <TableHeadCell>Codigo</TableHeadCell>
                                <TableHeadCell>Descripcion</TableHeadCell>
                                <TableHeadCell>Cotizado</TableHeadCell>
                                <TableHeadCell>Aprobado</TableHeadCell>                                
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {
                                productosArray.map((producto, index) => {
                                    return(
                                        <TableRow key={`${producto._id}${index}`}>
                                            <TableCell>{producto?.codigo}</TableCell>
                                            <TableCell>{producto?.descripcion}</TableCell>
                                            <TableCell style={{textAlign: "center"}}>{producto?.quantity || 0}</TableCell>
                                            <TableCell style={{textAlign: "center"}}>
                                                <SmallTextInput                                                     
                                                    name="cantidadAprobada"                                                    
                                                    value={productosArray[index]?.cantidadAprobada || ""} 
                                                    onChange={(event)=>onChangeHandler(event, index)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>                                                        
            </FormBody>
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
        </FormWrapper>
    )

}




const SmallTextInput = styled(TextInput)`
    max-width: 35px;
    padding: 0;
    text-align: end;
`


const TableContainer = styled.div`
    
    border-radius: 4px;
    width: var(--container-width);
    overflow: auto;
`

const Table = styled.table`
    border-collapse: collapse;
    width:100%
`

const TableHead = styled.thead`
`

const TableHeadRow = styled.tr`
 border-top: 1px solid ${p=>p.theme.palette.grey[400]};
    
`
const TableHeadCell = styled.th`
    padding: 16px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    font-size: 0.875rem;
    text-align: left;
    white-space: wrap;
    
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    
`

const TableCell = styled.td`
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    padding: 16px;
    font-size: 0.875rem;


`






export default ProductosAprobados