

export const rolePermissions = {
    isAdmin: [
            "can_crear_cirugias",
            "can_agendar",
            "can_aceptar_cirugias", 
            "can_editar_cirugias" ,            
            "can_suspender_cirugias", 
            "can_concluir_cirugias", 
            "can_edit_documentacion",
            "can_edit_reporte", 
            "can_reset_reporte", 
            "can_see_all_cirugias", 
            "can_update_operaciones_stages",
            "can_update_facturacion_stages",
            "can_view_facturacion_stages",
            "can_autorizar_cirugias",
            "can_editar_pacientes",
            "can_crear_productos",
            "can_crear_editar_invitar_doctores",
            "can_invite_users", 
            "can_asignar_ec",
            "can_see_all_reportes",
            "can_see_cotizacion_and_autorizacion",
            "can_crear_pacientes",
            "can_receive_cirugia_reporte_emails",   
            "can_crear_cotizaciones",
            "can_editar_cotizaciones",
            "can_editar_productos_and_ventas_cotizaciones",
            "can_ver_cotizaciones",
            "can_ver_todas_cotizaciones",     
            "can_cancelar_cirugias",
            "can_reactivar_cirugias",
            "can_view_operaciones_menu",
            "can_view_tickets",
            "can_assign_tickets"
         ],
    isOperaciones: ["can_see_all_cirugias", "can_update_operaciones_stages",],
    isVentas: [
            "can_crear_cirugias",
            "can_agendar",             
            "can_editar_cirugias" ,            
            "can_suspender_cirugias",
            "can_see_cotizacion_and_autorizacion",            
            "can_crear_pacientes",
            "can_ver_cotizaciones",
            "can_cancelar_cirugias"
    ],          
    isEC: [
        "can_suspender_cirugias", 
        "can_concluir_cirugias", 
        "can_edit_reporte"
    ],
    isServicio: [
        "can_crear_cirugias",
        "can_agendar",
        "can_aceptar_cirugias", 
        "can_editar_cirugias" ,            
        "can_suspender_cirugias", 
        "can_concluir_cirugias",
        "can_edit_documentacion", 
        "can_update_facturacion_stages",
        "can_view_facturacion_stages",
        "can_see_cotizacion_and_autorizacion",
        "can_see_all_cirugias", 
        "can_crear_pacientes",
        "can_crear_cotizaciones",
        "can_editar_cotizaciones",
        "can_editar_productos_and_ventas_cotizaciones",
        "can_ver_cotizaciones",
        "can_ver_todas_cotizaciones",
        "can_view_tickets",        
    ],          
    
    isObservador: ["can_see_all_cirugias"]
}



export const permissionsDef = {
    canCrearCirugias: 'can_crear_cirugias',
    canAgendar: 'can_agendar',
    canAceptarCirugias: 'can_aceptar_cirugias',
    canEditarCirugias: 'can_editar_cirugias',
    canSuspenderCirugias: 'can_suspender_cirugias',
    canConcluirCirugias: 'can_concluir_cirugias',
    canEditDocumentacion: 'can_edit_documentacion',
    canEditReporte: 'can_edit_reporte',
    canResetReporte: 'can_reset_reporte',
    canSeeAllCirugias: 'can_see_all_cirugias',
    canUpdateOperacionesStages: 'can_update_operaciones_stages',
    canUpdateFacturacionStages: 'can_update_facturacion_stages',
    canViewFacturacionStages: 'can_view_facturacion_stages',
    canAutorizarCirugias: 'can_autorizar_cirugias',
    canEditarPacientes: 'can_editar_pacientes',
    canCrearProductos: 'can_crear_productos',
    canInviteUsers: 'can_invite_users',
    canAccessBilling: 'can_access_billing',
    canAsignarEc:'can_asignar_ec',
    canSeeAllReportes: 'can_see_all_reportes',
    canSeeCotizacionAndAutorizacion: 'can_see_cotizacion_and_autorizacion',
    canCrearEditarInvitarDoctores: 'can_crear_editar_invitar_doctores',
    canCrearPacientes: 'can_crear_pacientes',
    canViewDashboard: 'can_view_dashboard',
    canReceiveCirugiaReporteEmails: 'can_receive_cirugia_reporte_emails',
    canCrearCotizaciones: 'can_crear_cotizaciones',
    canEditarCotizaciones: 'can_editar_cotizaciones',
    canEditarProductosAndVentasCotizaciones: 'can_editar_productos_and_ventas_cotizaciones',
    canVerCotizaciones: 'can_ver_cotizaciones',
    canVerTodasCotizaciones: 'can_ver_todas_cotizaciones',
    canCancelarCirugias: 'can_cancelar_cirugias',
    canReactivarCirugias: 'can_reactivar_cirugias',
    canViewOperacionesMenu: 'can_view_operaciones_menu',
    canViewTickets: 'can_view_tickets',
    canAssignTickets: 'can_assign_tickets',
    isAgent: 'isAgent'
}

