import React from 'react';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import CentrosAutocomplete from '../../Forms/CustomAutocomplete/CentrosAutocomplete';




const ModifyCentroCotizacion = ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    
    const isTablet = useMediaQuery('tablet');    
    const [selectedCentro, setSelectedCentro] = React.useState(null);        
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);        
    const accionMaker = useAccionMaker();
    
    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion
        if(cotizacion?.centro?.nombre){
             accion = accionMaker(`Centro cambio de ${cotizacion?.centro?.nombre} a ${selectedCentro?.nombre}`);
        }else{
            accion = accionMaker(`Centro agregado a la cotizacion`); 
        }
                
        let infoCentro = {
            centroId: selectedCentro._id,
            nombre: selectedCentro.nombre,
            ciudad: selectedCentro.ciudad,
            zona: selectedCentro.zona,
            cotizacionId: cotizacion._id,            
            accion:accion
          }          
    
          updateCotizacion('update-centro',infoCentro)
            
    }        
           
    const onChangeHandler = (event, option)=>{
        if(option){
            setSelectedCentro(option)
        }else{
            setSelectedCentro("")
        }
    }

    return(
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet} >Modificar Centro</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <CentrosAutocomplete
                    onChange={onChangeHandler}
                />                  
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyCentroCotizacion