




export const cleanAttachmentName = (filename) => {
    // If filename contains "tickets", split at the last "-"
    if (filename.includes('tickets')) {
        const parts = filename.split('-');
        return parts[parts.length - 1]; // Return the last part after the last "-"
    }
    return filename; // Return original if no "tickets" found
};