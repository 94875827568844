import { TextField, useMediaQuery } from "@mui/material";
import React from "react";
import styled from '@emotion/styled';
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../app/messagesReducer";
import useCheckForm from "../../../hooks/useCheckForm";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { isCedula, isEmail, isLength, isNSS, isTelefono, validate } from "../../../shared/validators";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";
import useUpdateCotizacion from "../../../hooks/useUpdateCotizacion";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import CardInfo from "../../Atoms/CardInfo";





const ModifyPacienteCotizacion = ({
    cotizacion,
    close,    
    cotizaciones,
    setCotizaciones,
    cotizacionId
}) => {
    const isLaptop = useMediaQuery('laptop');
    const isTablet = useMediaQuery('tablet');
    const dispatch = useDispatch();    
    const [checkDatabase, setCheckDatabase] = React.useState(false);    
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);        
    const accionMaker = useAccionMaker();

    const pacienteInfo = cotizacion.paciente
    const isPacienteCreado = pacienteInfo?._id ? true : false;

    const initialState = { 
        firstname: pacienteInfo?.firstname || '',
        secondname: pacienteInfo?.secondname || '',
        lastname: pacienteInfo?.lastname || '',
        secondLastname: pacienteInfo?.secondLastname || '',
        cedula: pacienteInfo?.cedula || '',
        nss: pacienteInfo?.nss || '',        
        telefono: pacienteInfo?.telefono || '',
        email: pacienteInfo?.email || '',   
        contacto: {
            firstnameC: pacienteInfo?.contacto?.firstnameC || '',
            lastnameC:  pacienteInfo?.contacto?.lastnameC || '',
            relacionC: pacienteInfo?.contacto?.relacionC || '',
            telefonoC: pacienteInfo?.contacto?.telefonoC || '',
            emailC: pacienteInfo?.contacto?.emailC || '',
          }       
      }

    const [values, setValues] = React.useState(initialState)
    

      const [errors, setErrors] = React.useState({
        firstname: '',
        secondname: '',
        lastname: '',
        secondLastname: '',
        cedula: '',
        nss: '',        
        telefono: '',
        email: '',
        
      })

      const [formCompleted, errorCount] = useCheckForm(values,errors, ["firstname", "lastname", "nss"])

      const textFieldStyle = {
        '& .MuiOutlinedInput-root':{
            borderRadius: '12px'
        }
    }

     
    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
    }

    const onFocus = (event, validators)=>{
        event.target.setAttribute('autocomplete', 'off');
                    
    }

    const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target        
        const error = validate(value, validators)        
        setErrors(prevState=>({...prevState, [name]:error}))
        return error
    }

    const onBlurNss = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    const onBlurCedula = async (event, validators)=>{
        const error = onBlur(event,validators)
        if(!error){
            //no need to check database as we are not creating a new paciente
        }
    }

    



    const onSubmit = async (event)=>{
        event.preventDefault();
        //check if form is completed and has no errors setPaciete info, if not show error message
        if(formCompleted && errorCount===0){
            const accion = accionMaker(`Informacion de paciente modificada`);
            const infoPaciente = {
                paciente:{
                    firstname: values.firstname,
                    secondname: values.secondname,
                    lastname: values.lastname,
                    secondLastname: values.secondLastname,
                    cedula: values.cedula,
                    nss: values.nss,
                    telefono: values.telefono,
                    email: values.email,
                },                
                cotizacionId: cotizacionId,
                accion: accion
            }            
            updateCotizacion("update-paciente", infoPaciente)

        }else{
            dispatch(errorMessage('Formulario no completado'))
            console.log('Form is not completed')
        }             
    }
  
    const disableForm = pacienteInfo?._id ? true : false

    // not using onSubimt as we are not sending to the database yet. We are updating the state of the cotizaciones Object
    return (
        <StyledFormWrapper onSubmit={onSubmit} >
            
            <FormTitle style={{marginBottom:'20px'}} isTablet={isTablet}>{'Informacion Paciente'}</FormTitle>
            {isPacienteCreado && <CardInfo fontSize={"0.8rem"}>Paciente ya creado o asignado, no se puede modificar</CardInfo>}
            <br/>
            <StyledFormBody isTablet={isTablet} style={{marginTop: '8px'}}>
                <TextField 
                    value = {values.firstname} label = "Primer Nombre" name = "firstname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.firstname ? "Campo Obligatorio": errors.firstname}
                    error={errors.firstname}
                    required={true}
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isLength])}
                    spellCheck="false"
                    disabled={disableForm}
                />
                <TextField 
                    value = {values.secondname} label = "Segundo Nombre" name = "secondname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondname}
                    error={errors.secondname}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                    disabled={disableForm}
                />
                <TextField 
                    value = {values.lastname} label = "Primer Apellido" name = "lastname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={!errors.lastname ? "Campo Obligatorio": errors.lastname}
                    error={errors.lastname}
                    required
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                    disabled={disableForm}
                />
                <TextField 
                    value = {values.secondLastname} label = "Segundo Apellido" name = "secondLastname"
                    onChange = {(event)=>onChangeValues(event,[isLength])}
                    variant = "outlined"
                    helperText={errors.secondLastname}
                    error={errors.secondLastname}
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    spellCheck="false"
                    disabled={disableForm}
                />
                 <TextField 
                    value = {values.cedula} label = "Cedula" name = "cedula"
                    onChange = {(event)=>onChangeValues(event,[isCedula],true)}
                    variant = "outlined"
                    helperText={!errors.cedula ? "Campo Obligatorio": errors.cedula}
                    required
                    error={errors.cedula}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlurCedula(event, [isCedula])}
                    spellCheck="false"
                    disabled={disableForm}
                />
                 <TextField 
                    value = {values.nss} label = "NSS" name = "nss"
                    onChange = {(event)=>onChangeValues(event,[isNSS], true)}
                    variant = "outlined"
                    helperText={!errors.nss ? "Campo Obligatorio": errors.nss}
                    required
                    error={errors.nss}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlurNss(event, [isNSS])}
                    spellCheck="false"
                    disabled={disableForm}
                />
                 <TextField 
                    value = {values.telefono} label = "Telefono (888)888-8888" name = "telefono"
                    onChange = {(event)=>onChangeValues(event,[isTelefono], true)}
                    variant = "outlined"
                    helperText={errors.telefono}
                    error={errors.telefono}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isTelefono])}
                    spellCheck="false"
                    disabled={disableForm}
                />
                 <TextField 
                    value = {values.email} label = "Email" name = "email"
                    onChange = {(event)=>onChangeValues(event,[isEmail], true)}
                    variant = "outlined"
                    helperText={errors.email}
                    error={errors.email}                    
                    sx={textFieldStyle}
                    onFocus={onFocus}
                    onBlur={(event)=>onBlur(event, [isEmail])}
                    spellCheck="false"
                    disabled={disableForm}
                />
                
            </StyledFormBody>
            
            <FormActionsBasic
                
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                disabledSuccess={!formCompleted || errorCount>0 || checkDatabase || isPacienteCreado}
                type="submit"
                as="button"
            />
            
        </StyledFormWrapper>
    )
    
}

//Guardar is disabled if Paciente was already created or assigned. We know this if the cotizacion has a pacienteId

const StyledFormWrapper = styled(FormWrapper)`
    
    @media ${p=>p.theme.media.tablet}{
        min-width:500px;
    }
`


const StyledFormBody = styled(FormBody)`
    margin-bottom: 85px;
    gap: 12px;
    @media ${p=>p.theme.media.tablet}{
        margin-bottom: 20px;
    }

`

const FormSubtitle = styled.h3`

    font-size: 1.2rem;
    font-weight: 400;
    color: ${p=>p.theme.palette.grey[600]};
    margin-bottom: 8px;
    @media ${p=>p.theme.media.tablet}{
        font-size: 1.4rem;
    }
`


export default ModifyPacienteCotizacion