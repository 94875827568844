import styled from '@emotion/styled';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale'; // Import Spanish locale


const TimeTeller = ({ date }) => {

    const getRelativeTime = (date) => {
        
        return formatDistanceToNow(new Date(date), { 
            addSuffix: true,
            locale: es 
        });
    };

    const formatDate = (date) => {
        const options = { 
            day: 'numeric', 
            month: 'long', 
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return new Date(date).toLocaleDateString('es-ES', options);
    };

    return (
        <TimeWrapper>
            <span>{getRelativeTime(date)}</span>
            <TimeSeparator>•</TimeSeparator>
            <TimeStamp>{formatDate(date)}</TimeStamp>
        </TimeWrapper>
    )
}




const TimeWrapper = styled.div`
    display: flex;
    gap: 4px;
    color: ${props => props.theme.palette.text.secondary};
    font-size: 0.75rem;
    font-style: italic;
`;

const TimeSeparator = styled.span`
    color: ${props => props.theme.palette.text.secondary};
`;

const TimeStamp = styled.span`
    color: ${props => props.theme.palette.text.secondary};
`;


export default TimeTeller;