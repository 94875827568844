import React from 'react';
import styled from '@emotion/styled';
import { Menu, MenuItem } from "@mui/material";
import IconButton from "../../Atoms/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material";

const AssigneeDropDown = ({ onAssigneeChange, isClosed, agents, loading }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (agent) => {
        onAssigneeChange(agent);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <FontAwesomeIcon 
                    icon={faHeadphones} 
                    size="1x" 
                    color={isClosed ? theme.palette.grey[400] : theme.palette.grey[700]}
                />
            </IconButton>
            <StyledMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {loading ? (
                    <MenuItem disabled>
                        <MenuItemWrapper>
                            <Text>Cargando agentes...</Text>
                        </MenuItemWrapper>
                    </MenuItem>
                ) : (
                    <div>
                    {agents.length === 0 && (
                        <MenuItem onClick={() => handleSelect(null)}>
                            <MenuItemWrapper>
                                <Text>Sin asignar</Text>
                            </MenuItemWrapper>
                        </MenuItem>
                        )}
                        {agents.map((agent) => (
                            <MenuItem 
                                key={agent._id} 
                                onClick={() => handleSelect(agent)}
                            >
                                <MenuItemWrapper>
                                    {agent.profilePicture ? (
                                        <AgentAvatarImage src={agent.profilePicture} alt={agent.firstname} />
                                    ) : (
                                        <AgentAvatar>
                                            {agent.firstname.charAt(0).toUpperCase()}
                                        </AgentAvatar>
                                    )}
                                    <Text>{agent.firstname}</Text>
                                </MenuItemWrapper>
                            </MenuItem>
                        ))}
                    </div>
                )}
            </StyledMenu>
        </div>
    );
};

const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        min-width: 200px;
    }
`;

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p => p.theme.palette.grey[700]};
`;

const AgentAvatar = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${p => p.theme.palette.primary.main};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
`;

const AgentAvatarImage = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
`;

export default AssigneeDropDown; 