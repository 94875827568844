import styled from '@emotion/styled';
import DoctorsAutocomplete from '../../Forms/CustomAutocomplete/DoctorsAutocomplete';
import CentrosAutocomplete from '../../Forms/CustomAutocomplete/CentrosAutocomplete';
import React from 'react';
import ProductosAutocomplete from '../../Forms/CustomAutocomplete/ProductosAutocomplete';
import Button from '../../Atoms/Button';
import { FormActions, FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import IconButton from '../../Atoms/IconButton';
import { useTheme } from '@mui/material';
import XCircle from '../../Icons/X-circle';
import Plus from '../../Icons/Plus';
import Chip from '../../Atoms/Chip';
import { capitalizeFirstLetter, getMonthNameInSpanish } from '../../../shared/helpers';
import ClientsAutocomplete from '../../Forms/CustomAutocomplete/ClientsAutocomplete';






const CotizacionesFiltro = ({
    close,
    setFiltro,
    selectedFiltroValues,
    setSelectedFiltroValues
}) => {


    const theme = useTheme();

    const [clear, setClear] = React.useState(false)

    const [values, setValues] = React.useState({
        doctor: "",
        centro: "",
        client: "",
        producto: []
    })

    const [producto, setProducto] = React.useState("")


    const onChangeValuesId = (name, option, type)=>{
        console.log('Running on change')

        console.log(option)

        let info = {
            _id: option._id,
            firstname: option.firstname,
            secondname: option.secondname,
            lastname: option.lastname,
            secondLastname: option.secondLastname,
        }

        if(type ==="doctors") info.mdId = option?.connected?.mdId

        setValues(prevState => ({...prevState, [name]:info}))
        setValues(prevState=> {console.log(prevState); return prevState})
    }

    const onChangeClient = (event, option)=>{
        console.log('Running on change client')
        console.log(option)
        let info = {
            _id: option._id,
            name: option.name
        }

        setValues(prevState => ({...prevState, client:info}))
    }


     //version for MUI Autocomplete
     const onChangeValuesAutocomplete = (event, option, name)=>{

        let info;

        if(name==="centro" && option){
            info = {
                _id : option._id,
                nombre : option.nombre,
                ciudad : option.ciudad,
                zona : option.zona 
            }            
        }else if(option){
            info = {
                _id: option._id,
                firstname: option.firstname,
                secondname: option.secondname,
                lastname: option.lastname,
                secondLastname: option.secondLastname
            }
        }

        if(option){
            setValues(prevState => ({...prevState, [name]:info}))
        }else{
            setValues(prevState => ({...prevState, [name]:""}))
        }
        
    }

    const onChangeHandlerProducto = (event,option)=>{    
                
        console.log(option)
        console.log(`On change handler producto`)
        if(!option)return;
        
        //setValues(prevState => ({...prevState, producto:option}))

        // setValues(prevState => ({
        //     ...prevState, 
        //     producto: [...prevState.producto, option]
        // }));
        // setClear(true)

        setProducto(option)
    }

    const onAddProducto = ()=>{
        console.log(`On add producto`)
        console.log(producto)
        setValues(prevState => ({
            ...prevState,
            producto: [...prevState.producto, producto]
        }))
        setClear(true)
    }



    const onSubmit = (e)=>{
        e.preventDefault();
        console.log(values)

        const filterValues = {
            doctor: values.doctor?._id,
            centro: values.centro?._id,
            client: values.client?._id,
            producto: values.producto ? values.producto.map(product => product._id) : []
        }
        


        const filterObject = Object.fromEntries(
            Object.entries(filterValues).filter(([key, value]) => 
                value !== undefined && (typeof value !== "string" || value.trim() !== "")
            )
        );
        
        
        
        console.log(filterObject)

        setFiltro(filterObject)
        setSelectedFiltroValues(values)
        close()
    
    }


    const disableFiltrarButton = ()=>{
        
        if(values.doctor || values.centro|| values.client || values.producto.length > 0){
            return false
        }
        return true
    }


     //create function to determine what month we are on

     // Get the current month name
    // const currentMonth = getMonthNameInSpanish(new Date().getMonth())   
    // const lastMonth = getMonthNameInSpanish(new Date().getMonth()-1)    
    // const twoMonthsAgo = getMonthNameInSpanish(new Date().getMonth()-2)
    
 


    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Filtrar Cotizaciones</FormTitle>
                <FormBody>
                {/*<DateMenuWrapper>
                <Chip
                outline={true}
                label={currentMonth}
                />
                <Chip
                outline={true}
                label={lastMonth}
                />
                <Chip
                outline={true}
                label={twoMonthsAgo}
                />
                </DateMenuWrapper> */}

                <DoctorsAutocomplete onChange={(event, option)=>onChangeValuesId(event, option, 'doctors')}/>                
                <CentrosAutocomplete onChange={(event, option)=>onChangeValuesAutocomplete(event, option, "centro")} />   
                <ClientsAutocomplete onChange={(event, option)=>onChangeClient(event, option, 'clients')}/>                                
                
                <ProductoWrapper>
                    <ProductoAutocompleteContainer>
                        <ProductosAutocomplete onChange={onChangeHandlerProducto} clear={clear} setClear={setClear}/> 
                    </ProductoAutocompleteContainer>
                    
                    <IconButton
                    type="button"
                    onClick={onAddProducto}
                    ><Plus color={theme.palette.grey[600]}/></IconButton>
                </ProductoWrapper>
                
                
                
                {values.producto.length > 0 && values.producto.map((producto, index)=>{
                    return (
                        <ProductItemContainer key={`${producto._id} ${index}`}>
                            <Text>{`${index+1}. ${producto.descripcion}`}</Text>
                            
                            <IconButton type="button" size="small"><XCircle color={theme.palette.error.main} size={18} onClick={()=>setValues(prevState => ({
                                ...prevState, 
                                producto: prevState.producto.filter((item, i)=> i !== index)
                            }))}/></IconButton>
                        </ProductItemContainer>
                    )
                
                })}     
                  
            </FormBody>
           
           <FormActions>
                <Button
                as={"button"}
                fullsize={true}
                success
                type="submit"
                disabled={disableFiltrarButton()}
                >Filtrar</Button>
           
           </FormActions> 
            
        </FormWrapper>
    )

}



const Wrapper = styled.div`
    display: flex;        
    flex-direction: column;
    gap: 25spx;
    max-width: 350px;
    padding: 25px;
    background-color: ${props => props.theme.palette.background.default};
    border-radius: 25px;
    box-shadow: ${props => props.theme.shadows[5]};

    @media ${p=>p.theme.media.tablet}{
        max-width: 500px;
    }
        
`

const Text = styled.p`

    font-size: 0.82rem;  
    font-weight: 400;
    color: ${props => props.theme.palette.text.primary};
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    
    
`

const ProductItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;

`

const ProductoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
`

const ProductoAutocompleteContainer = styled.div`
    width: 100%;
    flex:3;
`

const DateMenuWrapper = styled.div`
    display:flex;
    width:100%;
    overflow-x: scroll;
    margin-right: 8px;
    margin-bottom: 8px;
`



export default CotizacionesFiltro;