import { useState, useRef } from 'react';

import Modal from '../../Atoms/Modal';
import styled from '@emotion/styled';
import X from '../../Icons/X';
import ChevronRight from '../../Icons/ChevronRight';
import ChevronLeft from '../../Icons/ChevronLeft';
import { cleanAttachmentName } from '../ticketsHelpers/ticketHelperFunctions';
import Cotizaciones from '../../cotizaciones/Cotizaciones';
import CrearCotizaciones from '../../cotizaciones/CrearCotizaciones';
import IconButton from '../../Atoms/IconButton';

// Utility function to check if a URL is signed
function isUrlSigned(url) {
  try {
    const parsedUrl = new URL(url);
    // Check for the AWS signing query parameters
    return parsedUrl.searchParams.has('X-Amz-Signature') || parsedUrl.searchParams.has('Signature');
  } catch (error) {
    console.error('Invalid URL provided:', error);
    return false;
  }
}

function AttachmentViewer({ 
    attachments, 
    initialIndex = 0,
    message,
    setOpenModal,
    setFormType,
    ticket,
    handleGeneratePdf,
    isAttaching,
    attachStatus,
    refreshTicket
}) {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);
    const [isZoomed, setIsZoomed] = useState(false);    
    const imageRef = useRef(null);
    const [openAttachmentWindow, setOpenAttachmentWindow] = useState(!!attachments?.length);
    const [isPanelOpen, setIsPanelOpen] = useState(!attachments?.length);

    
    const handleNext = () => {
        setCurrentIndex((prev) => (prev + 1) % attachments.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prev) => (prev - 1 + attachments.length) % attachments.length);
    };

    //if (!attachments || attachments.length === 0) return null;

    const currentAttachment = attachments[currentIndex];

    // Updated helper function to get the proper URL
    const getAttachmentUrl = (attachment) => {
        // Always prefer signedUrl if available
        if (attachment.signedUrl) {
            console.log("Using signed URL:", attachment.signedUrl);
            return attachment.signedUrl;
        }
        
        // Fallback to regular url
        console.log("Falling back to regular URL:", attachment.url);
        return attachment.url;
    };

    const renderAttachmentContent = (attachment) => {
        const url = attachment.blob ? 
            URL.createObjectURL(attachment.blob) : 
            getAttachmentUrl(attachment);
        
        const handleMouseMove = (e) => {
            if (!isZoomed || !imageRef.current) return;
            
            const image = imageRef.current;
            const { left, top, width, height } = image.getBoundingClientRect();
            
            // Calculate relative position of the cursor within the image (0 to 1)
            const x = (e.clientX - left) / width;
            const y = (e.clientY - top) / height;
            
            // Calculate the transform origin based on cursor position
            const transformOriginX = `${x * 100}%`;
            const transformOriginY = `${y * 100}%`;
            
            image.style.transformOrigin = `${transformOriginX} ${transformOriginY}`;
        };

        console.log("Rendering attachment:", {
            filename: attachment.filename,
            url,
            signedUrl: attachment.signedUrl,
            mimetype: attachment.mimetype
        });

        // Check if it is an image
        if (
            (attachment.mimetype && attachment.mimetype.startsWith('image/')) ||
            /\.(jpg|jpeg|png|gif|webp)$/i.test(attachment.filename)
        ) {
            return (
                <ImageContainer>
                    <StyledImage 
                        ref={imageRef}
                        src={url} 
                        alt={cleanAttachmentName(attachment.filename)}
                        onClick={() => setIsZoomed(!isZoomed)}
                        onMouseMove={handleMouseMove}
                        className={isZoomed ? 'zoomed' : ''}
                        onError={(e) => {
                            console.error("Error loading image:", {
                                url,
                                error: e
                            });
                            e.target.onerror = null;
                        }}
                    />
                </ImageContainer>
            );
        } else if (
            (attachment.mimetype === 'application/pdf') || 
            (attachment.filename && attachment.filename.toLowerCase().endsWith('.pdf'))
        ) {
            return (
                <PDFViewer>
                    <iframe
                        src={`${url}#view=FitH`}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        title={cleanAttachmentName(attachment.filename)}
                        style={{ border: 'none' }}
                        onLoad={() => {
                            // Clean up blob URL if it was created
                            if (attachment.blob) {
                                setTimeout(() => URL.revokeObjectURL(url), 1000);
                            }
                        }}
                    />
                </PDFViewer>
            );
        } else {
            return (
                <iframe
                    src={url}
                    title={cleanAttachmentName(attachment.filename)}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                />
            );
        }
    };

    console.log("Current attachment data:", {
        currentAttachment,
        allAttachments: attachments,
        currentIndex
    });
    

    return (
        <ExternalWrapper>
            <ModalContent openAttachmentWindow={openAttachmentWindow}>
                
                {openAttachmentWindow && attachments?.length > 0 && (
                    <ViewerContainer>
                        <NavigationButton 
                            onClick={handlePrevious}
                            disabled={attachments.length <= 1}
                            style={{ left: 20 }}
                        >
                            <ChevronLeft />
                        </NavigationButton>

                        <ContentSection>
                            <AttachmentContent>
                                {renderAttachmentContent(currentAttachment)}
                            </AttachmentContent>
                            
                            <AttachmentInfo>
                                <FileName>{cleanAttachmentName(currentAttachment.filename)}</FileName>
                                <FileSize>
                                    {(currentAttachment.size / 1024).toFixed(1)} KB
                                </FileSize>
                            </AttachmentInfo>
                        </ContentSection>

                        <NavigationButton 
                            onClick={handleNext}
                            disabled={attachments.length <= 1}
                            style={{ right: 20 }}
                        >
                            <ChevronRight />
                        </NavigationButton>
                        <PanelToggleButton onClick={() => setIsPanelOpen(!isPanelOpen)}>
                    {isPanelOpen ? <ChevronRight /> : <ChevronLeft />}
                </PanelToggleButton>
                    </ViewerContainer>
                )}
                
                {isPanelOpen && (
                    <RightPanel>
                        <CrearCotizaciones                         
                            ticket={ticket}
                            handleGeneratePdf={handleGeneratePdf}
                            setOpenAttachmentWindow={setOpenAttachmentWindow}
                            openAttachmentWindow={openAttachmentWindow}
                            isAttaching={isAttaching}
                            attachStatus={attachStatus}
                            setOpenAttachmentModal={setOpenModal}
                            refreshTicket={refreshTicket}
                        />
                    </RightPanel>
                )}
            </ModalContent>
        </ExternalWrapper>
    );
}

const ExternalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
`;

const ModalContent = styled.div`
    width: ${props => props.openAttachmentWindow ? '95vw' : 'auto'};
    height: ${props => props.openAttachmentWindow ? '95vh' : 'auto'};
    background: ${props => props.theme.palette.background.paper};
    display: flex;
    position: relative;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1200;
    color: ${props => props.theme.palette.text.primary};
`;

const ViewerContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${props => props.theme.palette.background.default};
`;

const ContentSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 10px;
`;

const AttachmentContent = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
`;

const StyledImage = styled.img`
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    display: block;
    cursor: zoom-in;
    transition: transform 0.3s ease;

    &.zoomed {
        transform: scale(2.5);
        cursor: zoom-out;
        position: relative;
        z-index: 1000;
        transition: transform 0.3s ease, transform-origin 0s;
    }

    &:hover:not(.zoomed) {
        transform: scale(1.05);
    }
`;

const NavigationButton = styled(IconButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: ${props => props.theme.palette.background.paper};
    &:hover {
        background: ${props => props.theme.palette.action.hover};
    }
`;

const AttachmentInfo = styled.div`
    text-align: center;
    padding: 16px;
`;

const FileName = styled.div`
    font-size: 16px;
    color: ${props => props.theme.palette.text.primary};
    margin-bottom: 4px;
`;

const FileSize = styled.div`
    font-size: 14px;
    color: ${props => props.theme.palette.text.secondary};
`;

const PanelToggleButton = styled(IconButton)`
    position: absolute;
    top: 5%;
    right: ${props => props.isPanelOpen ? 'auto' : '0'};
    left: ${props => props.isPanelOpen ? 'calc(100% - 40px)' : 'auto'};
    transform: translateY(-50%);
    z-index: 1100;
    background: ${props => props.theme.palette.background.paper};
    border-radius: ${props => props.isPanelOpen ? '4px 0 0 4px' : '4px'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &:hover {
        background: ${props => props.theme.palette.action.hover};
    }
`;

const RightPanel = styled.div`
    flex: 2;
    border-left: 1px solid ${props => props.theme.palette.divider};
`;

const PDFViewer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    object {
        width: 100%;
        height: 100%;
        border: none;
    }
`;

const PDFFallback = styled.div`
    text-align: center;
    padding: 20px;
    color: ${props => props.theme.palette.text.primary};
`;

const DownloadButton = styled.a`
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    text-decoration: none;
    border-radius: 4px;
    
    &:hover {
        background-color: ${props => props.theme.palette.primary.dark};
    }
`;

export default AttachmentViewer;
