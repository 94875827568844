import html2pdf from 'html2pdf.js';
import { createRoot } from 'react-dom/client';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import customTheme from '../util/Theme';

const useGeneratePDFFromComponent = () => {
    const [isAttaching, setIsAttaching] = useState(false);
    const [attachStatus, setAttachStatus] = useState(null); // 'success', 'error', or null
    
    // Use refs to track the latest state values
    const isAttachingRef = useRef(isAttaching);
    const attachStatusRef = useRef(attachStatus);
    
    // Update refs when state changes
    useEffect(() => {
        isAttachingRef.current = isAttaching;
        console.log('isAttaching state updated:', isAttaching);
    }, [isAttaching]);
    
    useEffect(() => {
        attachStatusRef.current = attachStatus;
        console.log('attachStatus state updated:', attachStatus);
    }, [attachStatus]);

    const generatePDFFromComponent = useCallback(async (Component, props, filename) => {
        console.log('Starting PDF generation process');
        
        // Force state update and ensure it's reflected
        setIsAttaching(true);
        setAttachStatus(null);
        
        // Log the current state values from refs
        console.log('State before generation - isAttaching:', isAttachingRef.current, 'attachStatus:', attachStatusRef.current);
        
        // Create temporary iframe
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        
        // Create root and render using modern React approach
        const root = createRoot(iframe.contentDocument.body);
        root.render(
            <ThemeProvider theme={customTheme}>
                <Component {...props} />
            </ThemeProvider>
        );
        
        try {
            // Wait longer for images to load and styles to apply
            await new Promise(resolve => setTimeout(resolve, 2000));
            
            // Generate PDF with improved settings
            const pdfBlob = await html2pdf().set({
                margin: [5, 0, 5, 0], // Increased margins
                filename: filename,
                image: { 
                    type: 'jpeg', 
                    quality: 1.0 // Maximum image quality
                },
                html2canvas: { 
                    scale: 2, // Higher scale for better quality
                    useCORS: true,
                    logging: true,
                    allowTaint: true
                },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            })
            .from(iframe.contentDocument.body)
            .outputPdf('blob');
    
            // Create a new blob with the correct type
            const modifiedBlob = new Blob([pdfBlob], { 
                type: 'application/pdf;inline=true'
            });
    
            // Clean up
            root.unmount();
            document.body.removeChild(iframe);
            
            

            setIsAttaching(false);
            setAttachStatus('success');
            setTimeout(() => {
                setAttachStatus(null);
            }, 2000);
            
            return modifiedBlob;
    
        } catch (error) {
            console.error('PDF Generation Error:', error);
            
            // Clean up
            if (root) root.unmount();
            document.body.removeChild(iframe);
            
            
            setIsAttaching(false);
            setAttachStatus('error');
            setTimeout(() => {
                setAttachStatus(null);
            }, 2000);
            
            throw error;
        }
    }, []);

    // Return both the state values and the function
    return { 
        generatePDFFromComponent, 
        isAttaching, 
        attachStatus 
    };
};

export default useGeneratePDFFromComponent;