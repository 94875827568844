import { useEffect, useState } from 'react';
import socket from '../shared/socketConnection';

export const useQuoteSocket = (ticketId, ticket) => {
    
    const [quoteStatus, setQuoteStatus] = useState({
        status: ticket?.cotizacion?.status || 'pending',        
        result: null,
        error: null,
        lastUpdate: Date.now() // Add timestamp to track updates
    });
    console.log('quoteStatus', quoteStatus);

    useEffect(() => {
        console.log('useQuoteSocket mounted for ticket:', {
            ticketId,
            socketStatus: {
                isConnected: socket?.connected,
                namespace: socket?.nsp,
                id: socket?.id,
                hasSocket: !!socket
            }
        });

        if (!socket || !ticketId) {
            console.log('No socket or ticketId available:', { 
                hasSocket: !!socket, 
                ticketId,
                socketInstance: socket 
            });
            return;
        }

        // Track socket connection state
        const handleConnect = () => {
            console.log('Socket connected in useQuoteSocket:', {
                socketId: socket.id,
                namespace: socket.nsp,
                ticketId
            });
            // Re-emit clientTenantId on reconnection
            if (socket.nsp) {
                const tenantId = socket.nsp.substring(1); // Remove leading slash
                console.log('Re-emitting clientTenantId after connect:', tenantId);
                socket.emit('clientTenantId', tenantId);
            }
        };

        const handleDisconnect = (reason) => {
            console.log('Socket disconnected in useQuoteSocket:', {
                reason,
                socketId: socket.id,
                ticketId
            });
        };

        const handleProcessing = (data) => {
            console.log('Client received quote:processing event:', {
                receivedProgress: data.progress,
                ticketId: data.ticketId
            });
            
            if (data.ticketId === ticketId) {
                setQuoteStatus({
                    status: 'processing',
                    progress: data.progress || 0,
                    result: null,
                    error: null
                });
            }
        };

        const handleProgress = (data) => {
            console.log('Client received quote:progress event:', {
                receivedProgress: data.progress,
                ticketId: data.ticketId
            });
            
            if (data.ticketId === ticketId) {
                setQuoteStatus({
                    status: 'processing',
                    progress: data.progress,
                    result: null,
                    error: null
                });
            }
        };

        const handleCompleted = (data) => {
            console.log('Client received quote:completed event:', {
                data,
                currentTicketId: ticketId,
                matches: data.ticketId === ticketId,
                currentStatus: quoteStatus
            });
            if (data.ticketId === ticketId) {
                setQuoteStatus(prev => ({
                    ...prev,
                    status: 'completed',
                    progress: 100,
                    result: data.result
                }));
            }
        };

        const handleError = (data) => {
            console.log('Client received quote:error event:', {
                data,
                currentTicketId: ticketId,
                matches: data.ticketId === ticketId,
                currentStatus: quoteStatus
            });
            if (data.ticketId === ticketId) {
                setQuoteStatus(prev => ({
                    ...prev,
                    status: 'error',
                    error: data.error
                }));
            }
        };

        // Subscribe to socket state events
        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);

        // Subscribe to quote events
        socket.on('quote:processing', handleProcessing);
        socket.on('quote:progress', handleProgress);
        socket.on('quote:completed', handleCompleted);
        socket.on('quote:error', handleError);

        console.log('Quote event listeners attached for ticket:', {
            ticketId,
            socketId: socket.id,
            currentStatus: quoteStatus
        });

        // If socket is already connected, emit clientTenantId
        if (socket.connected && socket.nsp) {
            const tenantId = socket.nsp.substring(1); // Remove leading slash
            console.log('Socket already connected, sending tenant ID:', {
                tenantId,
                socketId: socket.id,
                ticketId,
                currentStatus: quoteStatus
            });
            socket.emit('clientTenantId', tenantId);
        }

        // Cleanup function
        return () => {
            console.log('Cleaning up quote event listeners for ticket:', {
                ticketId,
                socketId: socket.id,
                finalStatus: quoteStatus
            });
            socket.off('connect', handleConnect);
            socket.off('disconnect', handleDisconnect);
            socket.off('quote:processing', handleProcessing);
            socket.off('quote:progress', handleProgress);
            socket.off('quote:completed', handleCompleted);
            socket.off('quote:error', handleError);
        };
    }, [ticketId]); // Only re-run if ticketId changes

    return quoteStatus;
}; 