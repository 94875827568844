import { styled, useTheme } from "@mui/material";
import { StepButtonWrapper, StepHeading, StepWrapper } from "./StepStyles";
import Chip from "../../../Atoms/Chip";
import IconButton from "../../../Atoms/IconButton";
import Search from "../../../Icons/Search";
import Plus from "../../../Icons/Plus";
import FadeIn from "../../../Atoms/FadeIn";
import BeatLoader from "react-spinners/BeatLoader";

const ClientSelection = ({
    quickClients,
    isLoadingQuickClients,
    otroCliente,
    setOtroCliente,
    onClickClientChip,
    selectedClient,
    setFormType,
    setOpenModal,    
}) => {
    const theme = useTheme()


    
    return (
        <StepWrapper>
                    <StepHeading style={{minHeight: '50px'}}>Seleccionar cliente
                    {otroCliente &&
                        <FadeIn duration={600}>
                            <div style={{display: 'flex'}}>
                            <IconButton size={'small'}>
                            <Search size={20} onClick={()=>{setFormType('searchClients');setOpenModal(true)}}/>
                            </IconButton>
                            <IconButton size="small">
                            <Plus size={20} onClick={()=>{setFormType('addClientePrivado')
                                                setOpenModal(true)}}/>
                            </IconButton>
                            </div>
                        </FadeIn>
                    }    
                    

                    </StepHeading>
                    <StepButtonWrapper>
                    {isLoadingQuickClients && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}

                    {!isLoadingQuickClients &&
                        quickClients?.map(client => {
                            const primary = client.name === selectedClient?.name                            
                            return (   
                                <Chip 
                                    key={client.id} 
                                    primary={primary} 
                                    outline={!primary}                             
                                    label={client.quickClient.shortDescription} 
                                    onClick={()=>{
                                        onClickClientChip(client)
                                        otroCliente && setOtroCliente(false)
                                    }}
                                />
                            
                        )})                        
                    }
                       <Chip 
                            label="Otro"
                            outline={!otroCliente}
                            primary={otroCliente}
                            onClick={()=>{
                                setOtroCliente(!otroCliente)
                                onClickClientChip(null)
                            }}
                       /> 
                        
                    </StepButtonWrapper>
                </StepWrapper>
    )
}



export default ClientSelection; 

