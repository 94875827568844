import { createOptions, goFetch } from "../api/cirugiasApi";
import { apiUrl } from "../shared/url";
import { useAccionMaker } from "./useAccionMaker";

const useTicketActions = (updateTicket) => {

    const accionMaker = useAccionMaker();


    const handleTicketStatusChange = async (ticketId, newStatus) => {

        let accion = accionMaker(`Estado de ticket actualizado`);

        const info = {
            status: newStatus,
            accion
        }

        try {
            const options = createOptions('PUT', info, true);
            const response = await goFetch(`${apiUrl}tickets/status/${ticketId}`, options);
            
            if (response.success) {
                updateTicket(ticketId, response.ticket);
            }
        } catch (error) {
            console.error('Error updating ticket status:', error);
        }
    };

    const handleTicketAgentChange = async (ticketId, newAgent) => {

        let accion = accionMaker(`Agente de ticket actualizado`);

        const agent={
            _id: newAgent?._id || null,     
            firstname: newAgent?.firstname || null,
            lastname: newAgent?.lastname || null,
            email: newAgent?.email || null,
            profilePicture: newAgent?.profilePicture || null
        }


        const info = {
            agent,
            accion
        }

        try {
            const options = createOptions('PUT', info, true);
            const response = await goFetch(`${apiUrl}tickets/agent/${ticketId}`, options);
            
            if (response.success) {
                updateTicket(ticketId, response.ticket);
            }
        } catch (error) {
            console.error('Error updating ticket agent:', error);
        }
    };

    const handleTicketPriorityChange = async (ticketId, newPriority) => {

        let accion = accionMaker(`Prioridad de ticket actualizada`);

        const info = {
            priority: newPriority,
            accion
        }
        
        try {
            const options = createOptions('PUT', info, true);
            const response = await goFetch(`${apiUrl}tickets/priority/${ticketId}`, options);
            
            if (response.success) {
                updateTicket(ticketId, response.ticket);
            }
        } catch (error) {
            console.error('Error updating ticket priority:', error);
        }
    };

    return {
        handleTicketStatusChange,
        handleTicketAgentChange,
        handleTicketPriorityChange
    };
};

export default useTicketActions; 