import styled from '@emotion/styled/macro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOptions, goFetch } from '../../api/cirugiasApi'
import { fetchVentas } from '../../app/thunkActions/usersActions'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import useMediaQuery from '../../hooks/useMediaQuery'
import useModalAndDrawer from '../../hooks/useModalAndDrawer'
import { apiUrl } from '../../shared/url'
import Modal from '../Atoms/Modal'
import EditProductoCotizacion from './EditProductoCotizacion'
import { especialidades} from './InfoPruebas'
import ClientePrivadoInput from './molecules/ClientePrivadoInput'
import CotizacionInformationSummary from './molecules/CotizacionInformationSummary'
import DescuentoTotalInput from './molecules/DescuentoTotalInput'
import DoctorAndCentroInput from './molecules/DoctorAndCentroInput'
import PacienteInput from './molecules/PacienteInput'
import ProductoInput from './molecules/ProductoInput'
import CurrencyRadio from '../molecules/CurrencyRadio'
import SearchClients from './molecules/SearchClients'
import NotaCotizacion from './molecules/NotaCotizacion'
import useSuccessButton from '../../hooks/useSuccessButton'
import { getCookie } from '../../shared/authHelpers'
import { createCotizacionObject, recalculateSelectedProductos, createSelectedProductObject, newTotalCotizacionCalc } from './CotizacionesHelpers'
import ClientSelection from './molecules/crearCotizaciones/ClientSelection'
import ProductSelection from './molecules/crearCotizaciones/ProductSelection'
import ProductGroupsSelection from './molecules/crearCotizaciones/ProductGroupsSelection'
import VentasSelection from './molecules/crearCotizaciones/VentasSelection'
import InformacionAdicionalSelection from './molecules/crearCotizaciones/InformacionAdicionalSelection'
import PostCrear from './molecules/crearCotizaciones/PostCrear'
import Info from '../Icons/Info'
import Tooltip from '@mui/material/Tooltip';
import { styled as muiStyled } from '@mui/material/styles';
import Chip from '../Atoms/Chip'







        


const CrearCotizaciones = ({
    setIsNewCotizacion, 
    ticket = {cotizacion: {}}, 
    handleGeneratePdf,
    setOpenAttachmentWindow,
    isAttaching,
    attachStatus,
    openAttachmentWindow,
    setOpenAttachmentModal,
    refreshTicket
}) => {

    const user = useCurrentUser()
    const [selectedEspecialidad, setSelectedEspecialidad] = React.useState(null);    
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();        
    const[newCotizacion, setNewCotizacion] = React.useState(null)    
    const [totalCotizacion, setTotalCotizacion] = React.useState({
        totalSinItbis: 0,
        descuento: 0, 
        subtotal: 0,
        exentoItbis: 0,
        totalGravado: 0,
        itbis: 0,
        totalGeneral: 0              
    });
        
    const smallBottomSwipeDrawers = []
    const drawerOffsetPercentInputArray = ["addPaciente", "editProducto", "addProducto", "addClientePrivado", "addDoctorAndCentro", "descuentoTotal", "mobileInfoSummary"]
    
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();    
    const [files, setFiles] = React.useState([])
    


    //crear cliente state
    const [otroCliente, setOtroCliente] = React.useState(false);
    const [quickClients, setQuickClients] = React.useState([])
    const [isLoadingQuickClients, setIsLoadingQuickClients] = React.useState(true)
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [clientePrivado, setClientePrivado] = React.useState(null);

    //crear producto state
    const [quickProductos, setQuickProductos] = React.useState([])
    const [isLoadingQuickProduct, setIsLoadingQuickProduct] = React.useState(false)
    const [selectedProductos, setSelectedProductos] = React.useState([]);
    const [productoId, setProductoId] = React.useState(null);
    const [selectedProductoEdit, setSelectedProductoEdit] = React.useState(null);    


    //crear paciente state
    const [pacienteInfo, setPacienteInfo] = React.useState()
    const [doctor, setDoctor]   = React.useState(null)
    const [centro, setCentro]   = React.useState(null)
    const [nota, setNota] = React.useState(null)

    //ventas
    const [ventasSelected, setVentasSelected] = React.useState(false);
    const {isLoading:isLoadingVentas} = useSelector(state=>state.users)

    //other state
    const [currency, setCurrency] = React.useState("pesos");   
    const [isButtonClicked, setIsButtonClicked] = React.useState(false); 
    const [isUploading, setIsUploading] = React.useState(false)


    //check if using phone
    const isPhone = useMediaQuery('phone')

    
    const dispatch = useDispatch();
    const ventas = useSelector(state=>state.users.ventas)
    
    React.useEffect(()=>{
        const fetchQuickClients = async () => {
            setIsLoadingQuickClients(true)
            const options = createOptions("GET",{});
            const response = await goFetch(`${apiUrl}clients/fetch-clients/quick-clients`,options);
            if(response){
                setQuickClients(response)
                setIsLoadingQuickClients(false)
            }
        }
        fetchQuickClients()
        dispatch(fetchVentas())

    },[dispatch])
    
    //const ventas = []



    //check if this useEffect is still necesary since we added setTotalCotizacion to ProductoInput when product is added at guardar
    React.useEffect(() => {      
        //go through the selectedProducts array and update totalCotizacion object
        const newTotalCotizacion = newTotalCotizacionCalc(selectedProductos)
        setTotalCotizacion(newTotalCotizacion)        
    }, [selectedProductos])

    // Add useEffect to handle ticket data
    React.useEffect(() => {
        if (ticket?.cotizacion) {
            // Safely access nested properties with optional chaining
            const content = ticket?.cotizacion?.cotizacionId 
                ? ticket?.cotizacion?.final?.content 
                : ticket?.cotizacion?.draft?.content;

            // Only proceed if we have content
            if (content) {
                createCotizacionObject(
                    content,
                    selectedClient,
                    currency,
                    setSelectedClient,
                    setOtroCliente,
                    setSelectedProductos,
                    setTotalCotizacion,
                    setPacienteInfo,
                    setDoctor,
                    setFiles,
                    selectedProductos
                );
            }
        }
    }, [ticket]);

    const onClickClientChip = (client) => {        
        
        //check if the client is already selected, if so, unselect it, if not, select it, and unselect the previous client, if any, and recalculate the selected productos array
        let clientSelected = selectedClient?.name === client?.name 
        if(!clientSelected){
            setSelectedClient(client)
        }else{            
            setSelectedClient(null)    
            //Since the client is unselected, set the client to null as it will go to the recalculateSelectedProductos function. This is so the price of the product will be calculated using the Base price list                    
            client = null
        }
    
        //if there are selected productos, recalculate the selected productos array in order to get new prices
        if(selectedProductos.length > 0){
            const newSelectedProductos = recalculateSelectedProductos(selectedProductos, client,currency)
            console.log(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
        }

    }

 

    const onClickEspecialidadChip = async (especialidad) => {
        
        setIsLoadingQuickProduct(true)
        

        if(especialidad.name === "Neurocirugia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosNeuro);
        }else if(especialidad.name === "Tiroide"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosTiroide);        
        }else if(especialidad.name === "Cardiologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosCardio);
        }else if(especialidad.name === "Maxilo"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosMaxilo);
        }else if(especialidad.name === "Cirugia General"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosCirugiaGeneral); 
        }else if(especialidad.name === "Ginecologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosGinecologia);
        }else if(especialidad.name === "Artroscopia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosArtroscopia);
        }else if(especialidad.name === "Ortopedia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosOrtopedia);
        }else if(especialidad.name === "Urologia"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosUrologia);
        }else if(especialidad.name === "ORL"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosORL);
        }else if(especialidad.name === "Columna"){
            setSelectedEspecialidad(especialidad)
            //setQuickProductos(productosColumna);
        }else if(especialidad.name === "Grapado Abierto"){
            setSelectedEspecialidad(especialidad)
        }else if(especialidad.name === "Grapado TriStaple"){
            setSelectedEspecialidad(especialidad)
        }else if(especialidad.name === "General"){
            setSelectedEspecialidad(especialidad)         
        }else{
            setSelectedEspecialidad(null)
            setQuickProductos([]);
        }

        // fetch the productos that have the selected especialidad and quickProductos is active
        const options = createOptions('GET')
        try {
            const response = await goFetch(`${apiUrl}productos/fetch-quickproductos/${especialidad.name}`, options)            
            setIsLoadingQuickProduct(false)
            setQuickProductos(response)    
        } catch (error) {
            console.log(error)
        }
        
        
    }

    const onClickQuickProduct = (producto) => {
        
        // add the product to the selectedProducts array if it is not already in the array, if it is in the array, remove it
        const newSelectedProductos = selectedProductos.filter((productoItem) => {
            return productoItem._id !== producto._id;
        }
        );

        if (newSelectedProductos.length === selectedProductos.length) {
            producto = createSelectedProductObject(producto, selectedClient, currency)            
            setSelectedProductos([...selectedProductos, producto]);
        }
        else {
            setSelectedProductos(newSelectedProductos);
        }
  
    }

    //function to check if product is already in the selectedProducts array
    const isProductSelected = (producto) => {
        const isProductSelected = selectedProductos.find((productoItem) => {
            return productoItem._id === producto._id;
        });
        return isProductSelected;
    };
    
    const onClickVentas = (user) => {
        let selectedVentas = ventasSelected?._id === user?._id 
        if(!selectedVentas){
            setVentasSelected(user)
        }else{
            setVentasSelected(null)
        }

    }



    //when click on crear cotizacion creates a new cotizacion object which includes the client info, products, totalCotizacion object and the date
    //then we send it to the backend to be saved in the database
    const onClickCrearCotizacion = async (event) => {
               
        if(isButtonClicked){
            return
        }
        setIsButtonClicked(true)
                
        setIsLoading(true)
        //take out prices key for each object in the selectedProductos array. Price was added in order to allow for recalculation given changes in client and currency
                
        const newSelectedProductos = selectedProductos.map(producto => {
            const {price, ...newProducto} = producto
            return newProducto
        })
        
        const newCotizacion = {
            company:{
                _id: user.company._id,
                name: user.company.name,
            },
            currency: currency,
            client: {
                _id: selectedClient._id,
                name: selectedClient.name,                
                email: selectedClient.email,
                phone: selectedClient.phone,
                address: selectedClient.address,
                cedula: selectedClient.cedula,
                rnc: selectedClient.rnc,
                tipo: selectedClient.tipo,
                priceList: selectedClient.priceList,                            
            },
            productos: [newSelectedProductos],
            paciente: pacienteInfo,
            doctor: doctor,
            centro: centro,
            nota: nota,
            totalCotizacion: [totalCotizacion],
            ventas: {
                _id: ventasSelected._id,
                firstname: ventasSelected.firstname,
                secondName: ventasSelected.secondName,
                lastname: ventasSelected.lastname,
                secondLastname: ventasSelected.secondLastname,
                email: ventasSelected.email                
            },
            creadoPor:{
                _id: user._id,
                firstname: user.firstname,
                secondName: user.secondName,
                lastname: user.lastname,
                secondLastname: user.secondLastname,
                email: user.email,
                signature: user?.signature || null
            },
            dateModified: [new Date()],
            ticketId: ticket?._id
        }
        
        
        //send the newCotizacion object to the backend to be saved in the database
        const options = createOptions("POST", newCotizacion)

        try {
            const response = await goFetch(apiUrl + "cotizaciones/create-cotizacion", options)
            responseStateChanges(response,()=>{}, ()=>{
                console.log('in responseStateChanges')
                close();
                setNewCotizacion(response.cotizacion)
                // check if we have setIsNewCotizacion available
                if(typeof setIsNewCotizacion === 'function'){
                    setIsNewCotizacion(true)
                }
                if(typeof setOpenAttachmentWindow === 'function'){
                    setOpenAttachmentWindow(false)
                }
                // Only refresh ticket if we're in a ticket context (ticket prop exists)
                if(ticket && typeof refreshTicket === 'function'){
                    refreshTicket()
                }
            })                        
        } catch (error) {
            console.log(error)
        }                
    }

    const onClickVerCotizacion = () => {
        console.log(formType)
        console.log(openModal)
        setFormType("showCotizacion")        
        setOpenModal(true);            

    }

    const onChangeCurrency = (event) => {
        setCurrency(event.target.value)
        
        if(selectedProductos.length > 0){
            const newSelectedProductos = recalculateSelectedProductos(selectedProductos, selectedClient, event.target.value)
            console.log(newSelectedProductos)
            setSelectedProductos(newSelectedProductos)
        }
    }
    
    

    if(newCotizacion){
        return (
            <PostCrear
                newCotizacion={newCotizacion}
                onClickVerCotizacion={onClickVerCotizacion}
                openModal={openModal}
                setOpenModal={setOpenModal}
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                formType={formType}            
                handleGeneratePdf={handleGeneratePdf}
                isAttaching={isAttaching}
                attachStatus={attachStatus}
                setOpenAttachmentModal={setOpenAttachmentModal}
            />
        )                    
    }
    
    // console.log(formType)
    // console.log(clientePrivado)
    // console.log(selectedClient)
    
    const uploadFiles = async (files) => {
        setIsUploading(true)
        const formData = new FormData()
        files.forEach(file => {
            formData.append('files', file)
        })
        const token = getCookie('token');
        
        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        try {
            const response = await goFetch(apiUrl + "ai/create-cotizacion-ai", options)
            if(response.success) {
                createCotizacionObject(response, selectedClient, currency, setSelectedClient, setOtroCliente, setSelectedProductos, setTotalCotizacion, setPacienteInfo, setDoctor, setFiles, selectedProductos)
            }
        } catch (error) {
            console.log(error)
            alert('Error processing medical prescription')
        } finally {
            setIsUploading(false)
        }
    }

    const handleFileUpload = async (event) => {
        const uploadedFiles = Array.from(event.target.files)
        
        const maxFileSize = 5 * 1024 * 1024 // 5MB
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        
        const validFiles = uploadedFiles.filter(file => {
            if (file.size > maxFileSize) {
                alert(`File ${file.name} is too large. Maximum size is 5MB`)
                return false
            }
            if (!allowedTypes.includes(file.type)) {
                alert(`File ${file.name} is not an allowed type. Please upload images or PDFs`)
                return false
            }
            return true
        })

        if (validFiles.length > 0) {
            await uploadFiles(validFiles)
        }
    }

    return (
        <>
        <ExternalWrapper>
            <SideWrapper>
                <StepWrapper>
                    <CurrencyRadio currency={currency} onChangeRadio={onChangeCurrency}/>
                    <InfoWrapper>
                    {ticket.cotizacion.cotizacionId &&
                    <Chip label={`Cotizacion ${ticket.cotizacion.cotizacionNumber}`} success/>
                    }
                    {ticket?.cotizacion && (
                        <ConfidenceTooltip 
                            title={<ConfidenceDetails ticket={ticket} />}
                            placement="bottom-end"
                        >
                            <Info 
                                size={20}
                                style={{ 
                                    color: 'var(--colors-action-active)',
                                    cursor: 'pointer',
                                    '&:hover': { color: 'var(--colors-primary-main)' }
                                }} 
                            />
                        </ConfidenceTooltip>
                    )}
                    </InfoWrapper>
                </StepWrapper>                
                <ClientSelection
                    quickClients={quickClients}
                    isLoadingQuickClients={isLoadingQuickClients}
                    otroCliente={otroCliente}
                    setOtroCliente={setOtroCliente}
                    onClickClientChip={onClickClientChip}
                    selectedClient={selectedClient}
                    setFormType={setFormType}
                    setOpenModal={setOpenModal}
                />
                <ProductGroupsSelection
                    especialidades={especialidades}
                    selectedEspecialidad={selectedEspecialidad}
                    onClickEspecialidadChip={onClickEspecialidadChip}
                />
                <ProductSelection
                    quickProductos={quickProductos}
                    isLoadingQuickProduct={isLoadingQuickProduct}
                    isProductSelected={isProductSelected}
                    onClickQuickProduct={onClickQuickProduct}
                    setFormType={setFormType}
                    setOpenModal={setOpenModal}

                />
                <VentasSelection
                    ventas={ventas}
                    ventasSelected={ventasSelected}
                    isLoadingVentas={isLoadingVentas}
                    onClickVentas={onClickVentas}
                />
                <InformacionAdicionalSelection
                    setFormType={setFormType}
                    setOpenModal={setOpenModal}
                    isPhone={isPhone}
                    newCotizacion={newCotizacion}
                    onClickCrearCotizacion={onClickCrearCotizacion}
                    successEvent={successEvent}
                    isUploading={isUploading}
                    handleFileUpload={handleFileUpload}
                    cotizacionRealizada={ticket.cotizacion.cotizacionId}
                />
            </SideWrapper>
            {!isPhone &&                        
                <SideWrapper style={{maxHeight:'700px', overflow: 'auto'}}>
                    <CotizacionInformationSummary                                                            
                        selectedClient={selectedClient}
                        pacienteInfo={pacienteInfo}
                        doctor={doctor}
                        centro={centro}
                        selectedProductos={selectedProductos}
                        setFormType={setFormType}
                        setOpenModal={setOpenModal}
                        setProductoId={setProductoId}
                        setSelectedProductoEdit={setSelectedProductoEdit}
                        totalCotizacion={totalCotizacion}
                        currency={currency} 
                        nota={nota}                   
                    />
                </SideWrapper>
}
        </ExternalWrapper>
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
                drawerOffsetPercentInput={drawerOffsetPercentInputArray.includes(formType) && "12%"}      
                  
            >
                {formType === "editProducto"  && <EditProductoCotizacion  
                                                    close={close}
                                                    productoId={productoId} 
                                                    selectedProductoEdit={selectedProductoEdit} 
                                                    selectedProductos={selectedProductos} 
                                                    setSelectedProductos={setSelectedProductos}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    currency={currency}
                                                />}            
                
                {formType === "addProducto" && <ProductoInput 
                                                    setProductos={setSelectedProductos} 
                                                    createSelectedProductObject={createSelectedProductObject}
                                                    selectedClient={selectedClient}
                                                    close={close}
                                                    currency={currency}
                                                    productos={selectedProductos}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    />}
                {formType === "addPaciente" && <PacienteInput pacienteInfo={pacienteInfo} setPacienteInfo={setPacienteInfo} close={close}/>}
                {formType === "addDoctor&Centro" && <DoctorAndCentroInput
                                                        setDoctor = {setDoctor}
                                                        setCentro = {setCentro}
                                                        close={close}
                                                    />}                
                
                {formType === 'addNota' && <NotaCotizacion
                                                nota={nota}
                                                setNota={setNota}
                                                close={close}
                                            />}
                {formType === "descuentoTotal" && <DescuentoTotalInput  
                                                    selectedProductos={selectedProductos} 
                                                    setSelectedProductos={setSelectedProductos} 
                                                    close={close}
                                                   />}

                 {formType === "addClientePrivado" && <ClientePrivadoInput
                                                        clientePrivado={clientePrivado}
                                                        setClientePrivado={setClientePrivado}
                                                        setSelectedClient={setSelectedClient}
                                                        close={close}
                                                      />}                                  
                
               {formType === "mobileInfoSummary" && <SideWrapper><CotizacionInformationSummary
                                                        selectedClient={selectedClient}
                                                        pacienteInfo={pacienteInfo}
                                                        doctor={doctor}
                                                        centro={centro}
                                                        selectedProductos={selectedProductos}
                                                        setFormType={setFormType}
                                                        setOpenModal={setOpenModal}
                                                        setProductoId={setProductoId}
                                                        setSelectedProductoEdit={setSelectedProductoEdit}
                                                        totalCotizacion={totalCotizacion}  
                                                        formType={formType}
                                                        productoId={productoId}
                                                        selectedProductoEdit={selectedProductoEdit}
                                                        setSelectedProductos={setSelectedProductos}
                                                        setTotalCotizacion={setTotalCotizacion}
                                                        currency={currency}
                                                        nota={nota}
                                                        /></SideWrapper>
                                                        }

                 {formType === "searchClients" && <SearchClients close={close} setSelectedClient={setSelectedClient}/>}                                       

            </Modal> 
     </>  
    )   
}



const ExternalWrapper = styled.div`
    display:flex;
    flex-direction: column;
    
    gap: 16px;
    padding: 16px;
    max-width: 1200px;
    @media ${p=>p.theme.media.tablet}{
        flex-direction: row;
    }

`
const SideWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    gap: 16px;
`

const StepWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
`


const StepHeading = styled.div` 
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
    background-color: ${p=>p.theme.palette.grey[100]};
    border-radius: 12px;
    padding: 4px 8px;
`

const StepButtonWrapper = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    align-items: center;
`

const TotalLabel = styled.span`
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    font-size: 0.8rem;
`


const InfoAdicionalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
`


const Value = styled.span`
    font-size: 0.8rem;    
    
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    flex:2;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`


const ConfidenceTooltip = muiStyled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    '& .MuiTooltip-tooltip': {
        maxWidth: 300,
        fontSize: '0.8rem',
        padding: '12px',
    },
});

const ConfidenceDetails = ({ ticket }) => {
    // Check for both draft and final content confidence scores
    const draftConfidence = ticket?.cotizacion?.draft?.content?.confidenceAssessment;
    const finalConfidence = ticket?.cotizacion?.final?.content?.metadata?.confidence?.score;
    
    // If no confidence data available, return null
    if (!draftConfidence && !finalConfidence) return null;
    
    // Use final score if available, otherwise use draft assessment
    const { overall, details } = draftConfidence || {};
    
    return (
        <div>
            {typeof finalConfidence === 'number' && (
                <div>
                    <strong>AI Score:</strong> {Math.round(finalConfidence * 100)}%
                    <br/>
                    <br/>
                </div>
            )}
            <div><strong>Overall Confidence:</strong> {overall}</div>
            <br/>
            <div><strong>Details:</strong></div>
            <div>Patient ID: {details?.patientIdentification}</div>
            <div>Doctor Match: {details?.doctorMatch}</div>
            <div>Client Match: {details?.clientMatch}</div>
            <div>Product Matches: {details?.productMatches}</div>
            <br/>
            <div><strong>Reasoning:</strong></div>
            <div>{details?.reasoning}</div>
        </div>
    );
};

export default CrearCotizaciones

