import React from 'react';
import styled from '@emotion/styled/macro';
import useColor from '../../hooks/useColor';
import { useTheme } from '@mui/system';


//externalActive is used to control active state from outside this component. Used in Autocomplete

const TextInput = React.forwardRef(({
    label, 
    name, 
    id, 
    color="primary", 
    type="text",
    autocomplete="off",
    setValue,
    value,
    onFocus,
    onBlur,
    onChangeHandler,
    externalActive = true,
    setExternalActive,
    frontIcon,
    endIcon,
    error,
    helperText,
    onBlurValidation,
    popperRef,
    disabled,
    placeholder,
    multiline = false,
    rows = 1,
    ...otherProps
}, ref)=>{
    

    
    const theme = useTheme();
    const [internalValue, setInternalValue] = React.useState('');
    const [active, setActive] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    let labelFontSize = '.875rem';
    let labelTop = '18px';

    //const inputElement = React.useRef();
    //inputRef comes from Forwarded Ref
    const inputElement = ref;

    const inputElementInternal = React.useRef();
    let [selectedColor] = useColor(color);

    if(error){
        selectedColor = theme.palette.error.main;
    }

    //this handler is used if no external handler is supplied
    const internalOnChangeHandler =(e)=>{
        e.preventDefault();
        setInternalValue(e.target.value)
    }

    //fix to keep active state when rerendering without loosing state. Example Ars/ privado
    if(value && !active) setActive(true);
    
    
    // if Front Icon is passed, label should remain on top of field
    if(frontIcon){
        labelFontSize='.65rem'
        labelTop= '4px'
    }
   
    

    React.useEffect(()=>{
        if(!externalActive){
            setActive(false)
        }
    },[externalActive])

    const onFocusHandler=(event)=>{        
        //used to disable autocomplete from browswer
        if(autocomplete==="off"){
            event.target.setAttribute('autocomplete', 'off');
        }
       
        setFocus(true)
        setActive(true)
        if(setExternalActive){
            setExternalActive(true)
        }
        
        //if another component passes function to be run on focus
        if(onFocus) onFocus()
    }

    const onBlurHandler =(event)=>{
        onBlurValidation && onBlurValidation(event)
        
        //if not controlled by another component use internal value to determine when blur occurs
        if(setValue===undefined && onChangeHandler === undefined){
            console.log('passed test')
            if(internalValue==='' || internalValue===undefined){
                setActive(false);
                setFocus(false);
                return;
            }
            console.log('need to set focus to false')
            setFocus(false)
            return
        }

        
    //when controlled by another component
        
        if(value==='' || value===undefined || value===null){
            setActive(false);
            setFocus(false)
            return;
        }

        setFocus(false)
        //blur action supplied by external component
        onBlur && onBlur(setActive);
    }

    if(active){
        labelFontSize='.65rem'
        labelTop= '4px'
    }
    
    
    
    return(
        
        <MainWrapper>
            <InputWrapper 
                active={active} 
                focus={focus} 
                disabled={disabled} 
                error={error} 
                ref={popperRef} 
                multiline={multiline}
                style={{'--selected-color': selectedColor}}
            >  
                {frontIcon &&
                    <IconWrapper>
                        {frontIcon}
                    </IconWrapper>            
                }     
                <InnerWrapper>
                    {multiline ? (
                        <TextArea
                            autocomplete={autocomplete}
                            autoCapitalize="none"
                            spellCheck="false"
                            name={name} 
                            id={id}
                            ref={ref ? inputElement : inputElementInternal}
                            value={value || internalValue}
                            onChange={onChangeHandler || internalOnChangeHandler}
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            disabled={disabled}
                            placeholder={placeholder}
                            rows={rows}
                            {...otherProps}
                        />
                    ) : (
                        <Input
                            autocomplete={autocomplete}
                            autoCapitalize="none"
                            spellCheck="false"
                            name={name} 
                            id={id}
                            type={type}
                            ref={ref ? inputElement : inputElementInternal}
                            value={value || internalValue}
                            onChange={onChangeHandler || internalOnChangeHandler}
                            onFocus={onFocusHandler}
                            onBlur={onBlurHandler}
                            disabled={disabled}
                            placeholder={placeholder}                        
                            {...otherProps}
                        />
                    )}
                    {label &&           
                        <Label 
                            htmlFor={id}
                            active={active}
                            focus={focus}
                            error={error}
                            disabled={disabled}
                            style={{
                                '--label-font-size': labelFontSize,
                                '--label-top': labelTop,
                                '--selected-color': selectedColor
                            }}
                        >{label}</Label>    
                    }                        
                </InnerWrapper>
                {endIcon &&
                <IconWrapper>
                    {endIcon}
                </IconWrapper>     
            }  
                
                
            </InputWrapper>
            {
                helperText &&
                <HelperText><Text error={error} style={{'--selected-color': selectedColor}}>{helperText}</Text></HelperText>     
            }
            
    </MainWrapper>
    )
})

const MainWrapper = styled.div`
    display:flex;
    flex-direction:column;
    
`

const InnerWrapper = styled.div`
    
    flex:2;
  `

const Label=styled.label`
    position:absolute;
    font-size:var(--label-font-size);
    top:var(--label-top);
    left: 12px;
    opacity: 1;
    font-weight:500;
    transition: 0.1s all ease-in-out;    
    color: ${p=>p.active && p.focus ?'var(--selected-color)' : (!p.error ? p.theme.palette.grey[600] : 'var(--selected-color)')};
    color: ${p=>p.disabled && p.theme.palette.grey[500]};
    pointer-events: none;
`



const InputWrapper = styled.div`
    position:relative;
    width: 100%;
    height: ${p => p.multiline ? 'auto' : '56px'};
    min-height: 56px;
    border:  ${p=>p.active && p.focus ? '2px solid var(--selected-color)': '1px solid '+ (!p.error ? p.theme.palette.grey[500] :'var(--selected-color)') };
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    
    
    &:hover{
        
        border:  ${p=>(p.active)  ? '2px solid var(--selected-color)':'1px solid '+ (!p.error? p.theme.palette.grey[900] :'var(--selected-color)')};
        border: ${p=>p.disabled && "1px solid" + p.theme.palette.grey[500]};
        ${Label}{
            color: ${p=>p.active ?'var(--selected-color)' : p.theme.palette.grey[900]};
            color: ${p=> p.disabled && p.theme.palette.grey[500] }; 
        }
    }

    
`

const Input = styled.input`
    width: 100%;
    height:56px;
    outline: none;
    border:none;
    line-height: normal;
    padding: 0px 12px;     
    font-size: 1rem;

    &:disabled{
        color: ${p=>p.theme.palette.grey[400]}
    }

    &::placeholder{
        color: ${p=>p.theme.palette.grey[400]}
    }
`

const TextArea = styled.textarea`
    width: 100%;
    min-height: 56px;
    outline: none;
    border: none;
    line-height: normal;
    padding: 16px 12px;     
    font-size: 1rem;
    resize: none;
    font-family: inherit;

    &:disabled{
        color: ${p=>p.theme.palette.grey[400]}
    }

    &::placeholder{
        color: ${p=>p.theme.palette.grey[400]}
    }
`

const IconWrapper = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    flex:1;
    max-width: 70px;    
    padding: 8px 12px 8px 12px;
    gap: 8px;
    background-color: #ffff;
    line-height:0;
`

const HelperText = styled.div`
    margin-left:8px;
`
const Text = styled.p`
    padding:0;
    margin:0;
    font-size: .675rem;
    color: ${p=>!p.error ? p.theme.palette.grey[600] : 'var(--selected-color)'}
`


export default TextInput;