import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faHospital,
    faCalendarCheck, faUsersCog, faUserCircle, faSignOutAlt,
    faTachometerAlt,faSlidersH, faBell, faChartBar, faBed, faFileInvoice, faHandshake, faDolly,faHeadphones
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermissions from '../../hooks/usePermissions';
import useSubscriptionToken from '../../hooks/useSubscriptionToken';




const DrawerNavigation =({
    setOpenModal,
    close
})=>{
    // had to create the close div for the Doctors link as puttin in close inside an onclick function would trigger a refresh calling all the code again
    const navigate = useNavigate();
    const isTablet = useMediaQuery('tablet');

    const permissions = usePermissions()
    const subscription = useSubscriptionToken();

    const {
        canInvitarUsuarios,        
        canCrearProductos,
        canViewDashboard,
        canVerCotizaciones,
        canViewOperacionesMenu,
        canViewTickets,        
        isMaster        
    }=permissions


    // this is to check if the plan allows cotizaciones, for now will use check-in as proxy
    const planAllowsCotizaciones = subscription?.companyPermissions?.includes('checkIn');;
    

    console.log(subscription)

     const closeCheck =()=>{
         if(isTablet) return
         close()
     }

    

    return(
        <NavigationWrapper>
            
            <LinkButton to={"activity"}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                
                    <Icon>                    
                            <FontAwesomeIcon icon={faTachometerAlt} size="2x"/>                                        
                    </Icon>
                    <TextWrapper>
                        <Text>
                            Actividad
                        </Text>
                    </TextWrapper>    
                                        
            </LinkButton>
            

            
                <LinkButton to={'cirugias'}  onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faCalendarCheck} size="2x"/>
                    </Icon>
                    <Text>
                        Cirugias
                    </Text>
                </LinkButton>
            
                
                    <LinkButton to={"doctors"}  onClick={()=>{navigate("doctors", {state:{from: 'navigation'}}); closeCheck()}} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                        <Icon>
                        <FontAwesomeIcon icon={faUserMd} size="2x"/>
                        </Icon>

                        <TextWrapper>
                            <Text>
                                Doctors
                            </Text>
                        </TextWrapper>
                        
                    </LinkButton>
                

            
                <LinkButton to={"centros"}   onClick={closeCheck} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faHospital} size="2x"/>
                    </Icon>
                    <Text>
                        Centros
                    </Text>
                </LinkButton>
            

            <LinkButton to={"pacientes"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                    <FontAwesomeIcon icon={faBed} style={{fontSize: '1.8rem'}}/>
                </Icon>
                <Text>
                    Pacientes
                </Text>
            </LinkButton>

        {planAllowsCotizaciones && canVerCotizaciones &&
        <>
                <LinkButton to={"clients"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faHandshake} style={{fontSize: '1.8rem'}}/>
                    </Icon>
                    <Text>
                        Clientes
                    </Text>
                </LinkButton>
            
                <LinkButton to={"cotizaciones"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon >
                    <FontAwesomeIcon icon={faFileInvoice} style={{fontSize: '2.2rem'}}/>
                </Icon>
                <Text>
                    Cotizar
                </Text>
            </LinkButton>

            </>
            }

            {canViewOperacionesMenu &&
                <LinkButton to={"operaciones"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                    <FontAwesomeIcon icon={faDolly} size="2x"/>
                </Icon>
                <Text>
                    Operaciones
                </Text>
                </LinkButton>

            }

            {canViewTickets &&
                <LinkButton to={"tickets"}  onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                    <Icon>
                        <FontAwesomeIcon icon={faHeadphones} size="2x"/>
                    </Icon>
                    <Text>
                        Tickets
                    </Text>
                </LinkButton>
            }


            {(canInvitarUsuarios || canCrearProductos) &&
                <LinkButton to={"admin"} onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                    <FontAwesomeIcon icon={faUsersCog} size="2x"/>
                </Icon>
                <Text>
                    Admin
                </Text>
            </LinkButton>
            }
                        
           { (canViewDashboard || isMaster) && 
            <LinkButton to={"reporte"}  onClick={closeCheck}  className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                <Icon>
                    <FontAwesomeIcon icon={faChartBar} size="2x"/>
                </Icon>
                <Text>
                    Reporte
                </Text>
            </LinkButton>
            }
            


        </NavigationWrapper>
    )
    

}

const Close = styled.div`

`


const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width:100%;
  gap: 8px;
  padding:16px 0px;
`


const LinkButton = styled(NavLink)`
    /*border: 1px solid white:*/
    width:100%;
    height: 65px;
    padding: 8px 8px 8px 20px;
    line-height: 50px;
    color: ${p=>p.theme.palette.grey[400]};
    display:flex;
    gap:28px;
    font-weight: 700;
    text-decoration: none;

    &:hover{
        background-color: ${p=>p.theme.palette.grey[300]};
        color: ${p=>p.theme.palette.grey[800]};
    }

    &.active{
        background-color: ${p=>p.theme.palette.grey[300]};
        color: ${p=>p.theme.palette.grey[800]};
    }
`

const Icon = styled.div`
    /*border: 1px solid white;*/
    
    width:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    
`
const TextWrapper = styled.div`
   

`
const Text = styled.span`

`



export default DrawerNavigation;