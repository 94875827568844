export const createCotizacionObject = (
    response,
    selectedClient, 
    currency, 
    setSelectedClient, 
    setOtroCliente, 
    setSelectedProductos, 
    setTotalCotizacion, 
    setPacienteInfo, 
    setDoctor,
    setFiles,
    selectedProductos
) => {
    // Guard clause for null/undefined response
    if (!response) {
        console.warn('No response provided to createCotizacionObject');
        return;
    }

    console.log('ready to create cotizacion object')
    
    
    // Safely initialize files
    setFiles(Array.isArray(response.files) ? response.files : [])
    
    // Handle client data if returned by the AI
    let clientToUse = selectedClient
    if (response.client && typeof response.client === 'object') {
        try {
            const formattedClient = {
                _id: response.client._id || '',
                name: response.client.name || '',
                email: response.client.email || '',
                phone: response.client.phone || '',
                address: response.client.address || '',
                cedula: response.client.cedula || '',
                rnc: response.client.rnc || '',
                tipo: response.client.tipo || '',
                priceList: response.client.priceList || "Base"
            }
            // Update clientToUse for product processing
            clientToUse = formattedClient
            // Update the state
            setSelectedClient(formattedClient)
            setOtroCliente(false)
        } catch (error) {
            console.error('Error formatting client:', error);
        }
    }
    
    // Handle the products returned by the AI
    if (Array.isArray(response.productos) && response.productos.length > 0) {
        try {
            console.log('Client price list:', clientToUse?.priceList)
            const formattedProducts = response.productos
                .filter(producto => producto && typeof producto === 'object') // Filter out invalid products
                .map(producto => {
                    try {
                        const formattedProduct = createSelectedProductObject(producto, clientToUse, currency)
                        console.log('Product price:', formattedProduct.clientPrice)
                        return formattedProduct
                    } catch (error) {
                        console.error('Error formatting product:', error, producto);
                        return null;
                    }
                })
                .filter(Boolean); // Remove any null products from failed formatting
            
            if (formattedProducts.length > 0) {
                // Use a callback to ensure we're working with the latest state
                setSelectedProductos(formattedProducts)
                
                // Calculate new total after setting products
                const newTotalCotizacion = newTotalCotizacionCalc(formattedProducts)
                setTotalCotizacion(newTotalCotizacion)
            }
        } catch (error) {
            console.error('Error processing products:', error);
        }
    }

    // Handle patient data if returned by the AI
    if (response.paciente && typeof response.paciente === 'object') {
        try {
            const formattedPaciente = {
                firstname: response.paciente.firstname || '',
                secondname: response.paciente.secondname || '',
                lastname: response.paciente.lastname || '',
                secondLastname: response.paciente.secondLastname || '',
                cedula: response.paciente.cedula || '',
                nss: response.paciente.nss || '',
                telefono: response.paciente.telefono || '',
                email: response.paciente.email || '',                        
            }
            setPacienteInfo(formattedPaciente)
        } catch (error) {
            console.error('Error formatting patient:', error);
        }
    }

    // Handle doctor data if returned by the AI
    if (response.doctor && typeof response.doctor === 'object') {
        try {
            const formattedDoctor = {
                _id: response.doctor._id || '',
                firstname: response.doctor.firstname || '',
                secondname: response.doctor.secondname || '',
                lastname: response.doctor.lastname || '',
                secondLastname: response.doctor.secondLastname || '',                                                
                especialidad: response.doctor.especialidad || ''
            }
            setDoctor(formattedDoctor)
        } catch (error) {
            console.error('Error formatting doctor:', error);
        }
    }  
}

export const createSelectedProductObject = (producto, selectedClient, currency) => {
    console.log('Creating selected product object:', producto);
    
    let selectedProducto  ={}
    selectedProducto._id = producto._id;
    selectedProducto.codigo = producto.codigo;
    selectedProducto.descripcion = producto.descripcion;
    selectedProducto.subjectToItbis = producto.subjectToItbis;
    
    //check if the product has a price for the selected client
    //if not, check if the product has a price for the base price list
    //if not, set the price to 0
    
    if(producto?.price && producto?.price?.[selectedClient?.priceList]?.[currency]){
        selectedProducto.clientPrice = producto?.price[selectedClient?.priceList][currency];
    }else if(producto?.price && producto?.price["Base"][currency]){
        selectedProducto.clientPrice = producto?.price["Base"][currency];
    }else{
        selectedProducto.clientPrice = 0; 
    }
    
    selectedProducto.quantity = producto.cantidad || 1;  // Use the AI-provided quantity or default to 1
    selectedProducto.discount = 0;
    selectedProducto.percentDiscount = 0;        
    selectedProducto.subtotal = (selectedProducto.clientPrice - selectedProducto.discount) * selectedProducto.quantity;          
    selectedProducto.itbis = producto.subjectToItbis ? (selectedProducto.clientPrice *.18) : 0;    
    selectedProducto.total = selectedProducto.subtotal + selectedProducto.itbis; 
    selectedProducto.price = producto.price;
    selectedProducto.currency = currency;
    return selectedProducto
}

//called when editing a product that has already been selected to the product table, also when discount is applied to all products at once. 
export const updateSelectedProductObject = (producto, newPrice, quantity, percentDiscount) => {    
    let updatedProducto ={}
    updatedProducto.clientPrice = newPrice ? newPrice : producto.clientPrice;
    updatedProducto.quantity = quantity;
    updatedProducto.total = producto.clientPrice;
    updatedProducto.percentDiscount = percentDiscount ? percentDiscount : producto.percentDiscount;
    updatedProducto.discount = percentDiscount/100 * updatedProducto.clientPrice;       
    //if (producto.clientPrice != null && producto.discount != null && producto.quantity != null) {
        updatedProducto.subtotal = (updatedProducto.clientPrice - updatedProducto.discount) * updatedProducto.quantity;
    //    }
    updatedProducto.itbis = producto.subjectToItbis ? ((updatedProducto?.clientPrice - updatedProducto?.discount) * updatedProducto.quantity *.18) : 0;
    updatedProducto.total = updatedProducto.subtotal + updatedProducto.itbis;
    
    return {...producto,...updatedProducto}
}

export const newTotalCotizacionCalc = (selectedProductos) => {
    console.log('in newTotalCotizacionCalc')
    const newTotalCotizacion = selectedProductos.reduce((totalCotizacion, producto) => {
        //totalSinItbis includes the total of the products that are not subject to itbis            
        totalCotizacion.totalSinItbis += producto.clientPrice * producto.quantity
        totalCotizacion.descuento += producto.discount * producto.quantity
        totalCotizacion.subtotal += producto.subtotal
        totalCotizacion.exentoItbis += producto.itbis === 0 ? producto.subtotal : 0
        totalCotizacion.totalGravado += producto.itbis > 0 ? producto.subtotal : 0
        totalCotizacion.itbis += producto.itbis
        totalCotizacion.totalGeneral += producto.total
        return totalCotizacion
    }, {    
        totalSinItbis: 0,
        descuento: 0,
        subtotal: 0,
        exentoItbis: 0,
        totalGravado: 0,
        itbis: 0,
        totalGeneral: 0
    })

    return newTotalCotizacion
}

//recalculate selected productos array when client or currency changes
export const recalculateSelectedProductos = (selectedProductos, selectedClient, currency) => {

    const recalculatedSelectedProductos = selectedProductos.map(producto => {        
            let selectedProducto  ={}
            selectedProducto._id = producto._id;
            selectedProducto.codigo = producto.codigo;
            selectedProducto.descripcion = producto.descripcion;
            selectedProducto.subjectToItbis = producto.subjectToItbis;
            
            //check if the product has a price for the selected client
            //if not, check if the product has a price for the base price list
            //if not, set the price to 0 
            
            console.log('Recalculating product with client price list:', selectedClient?.priceList)
            const clientPriceList = selectedClient?.priceList ? selectedClient?.priceList : "Base"
                        
            if(producto.price && producto?.price?.[clientPriceList]?.[currency]){
                selectedProducto.clientPrice = producto?.price[clientPriceList][currency];
            }else if(producto?.price && producto?.price["Base"][currency]){
                selectedProducto.clientPrice = producto?.price["Base"][currency];
            }else{
                selectedProducto.clientPrice = 0; 
            }
            
            selectedProducto.quantity = producto.quantity || 1; // Preserve original quantity
            selectedProducto.discount = producto.discount || 0;
            selectedProducto.percentDiscount = producto.percentDiscount || 0;        
            selectedProducto.subtotal = (selectedProducto.clientPrice - selectedProducto.discount) * selectedProducto.quantity;          
            selectedProducto.itbis = producto.subjectToItbis ? (selectedProducto.clientPrice *.18) : 0;    
            selectedProducto.total = selectedProducto.subtotal + selectedProducto.itbis; 
            selectedProducto.price = producto.price;
            return selectedProducto
    })


    return recalculatedSelectedProductos
}


export const round = (value) => {   
    value = Number(value)    
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
}