import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tickets: [],
    nextCursor: null,
    previousCursor: null,
    hasMore: false,
    totalCount: 0,
    position: 0,
    rangeStart: 0,
    rangeEnd: 0,
    isLoading: false,
    newTicketsCount: 0
};

export const ticketsSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        setTicketsLoading(state) {
            state.isLoading = true;
        },
        setTicketsData(state, action) {
            state.tickets = action.payload.tickets;
            state.nextCursor = action.payload.nextCursor;
            state.previousCursor = action.payload.previousCursor;
            state.hasMore = action.payload.hasMore;
            state.totalCount = action.payload.totalCount;
            state.position = action.payload.position;
            state.rangeStart = action.payload.rangeStart;
            state.rangeEnd = action.payload.rangeEnd;
            state.isLoading = false;
        },
        updateTicket(state, action) {
            state.tickets = state.tickets.map(ticket => 
                ticket._id === action.payload._id ? action.payload : ticket
            );
        },
        resetTickets(state) {
            return initialState;
        },
        setNewTicketsCount(state, action) {
            state.newTicketsCount = action.payload;
        }
    }
});

export const { 
    setTicketsLoading, 
    setTicketsData, 
    updateTicket,
    resetTickets,
    setNewTicketsCount
} = ticketsSlice.actions;

export default ticketsSlice.reducer; 