import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { useTheme } from '@mui/system';
import debounce from 'lodash/debounce';
import Chip from '../../Atoms/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../app/ticketFilterReducer';
import AgentDropdown from './AgentDropdown';
import Search from '../../Icons/Search';
import { useNavigate } from 'react-router-dom';


/*
  FiltroTicket renders a filter area for tickets.
  It displays three sections:
    ● Status – chip buttons for "Abierto", "Trabajando", "Reabierto" and "Cerrado".
      In this version, multiple statuses can be selected.
    ● Priority – chip buttons for "Baja", "Media" and "Alta" (single-select).
    ● Agents – a "Todos" (all) option and one chip for each available agent (single-select).
  
  When you click on a status chip, it toggles the value in an array.
*/
const FiltroTickets = ({ agents = [], onApply, onFilter, onClose }) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = useSelector(state => state.ticketFilter.filter);
  console.log(filter)
  const {status, priority, agent} = filter;

  // Use an array to store multiple statuses.
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Add search term state
  const [searchTerm, setSearchTerm] = useState('');

  console.log(agents)   
  console.log(filter)

  // Toggle function for multi-select status filter.
  const toggleStatus = (status) => {
    setSelectedStatuses((prev) => {
      let newStatuses;
      if (prev.includes(status)) {
        // Remove the status if it's already selected.
        newStatuses = prev.filter(s => s !== status);
      } else {
        // Otherwise, add the status.
        newStatuses = [...prev, status];
      }
      // Pass null cursor to force fresh fetch
      dispatch(setFilter({ ...filter, status: newStatuses }));
      onFilter(null, 'next', {...filter, status: newStatuses});
      return newStatuses;
    });
  };

  //

  const togglePriority = (priority) => {
    setSelectedPriority((prev) => {
      let newPriority;
      if (prev.includes(priority)) {
        newPriority = prev.filter(p => p !== priority);
      } else {
        newPriority = [...prev, priority];
      }
      dispatch(setFilter({...filter, priority: newPriority}));
      onFilter(null, 'next', {...filter, priority: newPriority});
      return newPriority;
    });
  };

  const handleAgentChange = (newAgent) => {
    setSelectedAgent(newAgent);
    const newFilter = { ...filter, agent: newAgent?._id || null };
    dispatch(setFilter(newFilter));
    onFilter(null, 'next', newFilter);
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((term) => {
      if (term.length === 0 || term.length >= 3) {
        dispatch(setFilter({ ...filter, search: term }));
        onFilter(null, 'next', {...filter, search: term});
      }
    }, 100),
    [filter]
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  // Build the filter and trigger the onApply callback.
  const handleSearch = () => {
    const filter = {
      status: selectedStatuses,
      priority: selectedPriority,
      agent: selectedAgent?._id || null,
    };
    onApply ? onApply(filter) : console.log('Applied filter:', filter);
  };

  // (Optional) Update an external state or trigger side‑effects on filter change.
  useEffect(() => {
    // For example, update a global state
  }, [selectedStatuses, selectedPriority, selectedAgent]);

  return (
    <Wrapper>
      <Item>
        <SearchContainer>
          <SearchIconWrapper>
            <Search />
          </SearchIconWrapper>
          <SearchInput
            placeholder="Buscar tickets..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </SearchContainer>
      </Item>

      {/* Status section */}
      <Item>
        <StyledLabel>Status</StyledLabel>
        <ChipWrapper>
          <Chip
            label="Abierto"
            onClick={() => toggleStatus('Abierto')}
            primary={status.includes('Abierto')}
            outline = {!status.includes('Abierto')}
            
          />
          <Chip
            label="Trabajando"
            onClick={() => toggleStatus('Trabajando')}
            primary={status.includes('Trabajando')}
            outline = {!status.includes('Trabajando')}
            
          />
          <Chip
            label="Reabierto"
            onClick={() => toggleStatus('Reabierto')}
            primary={status.includes('Reabierto')}
            outline = {!status.includes('Reabierto')}
          />
          <Chip
            label="Cerrado"
            onClick={() => toggleStatus('Cerrado')}
            primary={status.includes('Cerrado')}
            outline = {!status.includes('Cerrado')}
          />
        </ChipWrapper>
      </Item>

      {/* Priority section */}
      <Item>
        <StyledLabel>Priority</StyledLabel>
        <ChipWrapper>
          <Chip
            label="Baja"
            onClick={() => togglePriority('Baja')}
            primary={priority.includes('Baja')}
            outline = {!priority.includes('Baja')}
          />
          <Chip
            label="Media"
            onClick={() => togglePriority('Media')}
            primary={priority.includes('Media')}
            outline = {!priority.includes('Media')}
          />
          <Chip
            label="Alta"
            onClick={() => togglePriority('Alta')}
            primary={priority.includes('Alta')}
            outline = {!priority.includes('Alta')}
          />
        </ChipWrapper>
      </Item>

      {/* Agents section */}
      <Item>
        <StyledLabel>Agent</StyledLabel>
        <AgentDropdown 
          agents={agents}
          selectedAgent={selectedAgent}
          onChange={handleAgentChange}
        />
      </Item>
    </Wrapper>
  );
};

/* Styled components */
const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  margin-bottom: 12px;
  flex: 1;
  background-color: #ffff;
  box-shadow: ${p =>
    p.isLaptop
      ? `0px 2px 1px -1px rgb(0 0 0 / 20%),
         0px 1px 1px 0px rgb(0 0 0 / 14%),
         0px 1px 3px 0px rgb(0 0 0 / 12%)`
      : 'revert'};
  border-radius: 12px;
  padding: 8px;
`;

const Item = styled.div`
  padding: 12px 4px;
  border-bottom: 1px solid ${p => p.theme.palette.grey[400]};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledLabel = styled.p`
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  font-size: 0.875rem;
  color: ${p => p.theme.palette.grey[600]};
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

// Add new styled components for search
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${p => p.theme.palette.grey[100]};
  border-radius: 8px;
  padding: 8px 12px;
  gap: 8px;
  border: 1px solid ${p => p.theme.palette.grey[300]};

  &:focus-within {
    border-color: ${p => p.theme.palette.primary.main};
    box-shadow: 0 0 0 2px ${p => p.theme.palette.primary.light}20;
  }
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.palette.grey[500]};
`;

const SearchInput = styled.input`
  border: none;
  background: none;
  width: 100%;
  padding: 4px 0;
  font-size: 14px;
  color: ${p => p.theme.palette.text.primary};
  outline: none;

  &::placeholder {
    color: ${p => p.theme.palette.grey[500]};
  }
`;

const StyledChip = styled(Chip)`
  border: 1px solid ${p => p.theme.palette.grey[300]};
`;

export default FiltroTickets;