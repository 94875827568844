import styled from '@emotion/styled';
import ProductosSearchBar from './ProductosSearchBar';
import IconButton from '../../Atoms/IconButton';
import Plus from '../../Icons/Plus';
import { useTheme } from '@mui/system';
import UploadCloud from '../../Icons/UploadCloud';
import Modal from '../../Atoms/Modal';
import React from 'react';
import ProductosUpload from './ProductosUpload';
import ProductosAdd from './ProductosAdd';
import BackButton from '../../Atoms/BackButton';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { CardBody, CardWrapper } from '../../Atoms/Card';
import { useDispatch, useSelector } from 'react-redux';
import MoreButtonProducts from './MoreButtonProductos';
import { initProductos } from '../../../app/productosReducer';
import ProductosEditar from './ProductosEditar';
import ProductoDesactivar from './ProductoDesactivar';
import usePermissions from '../../../hooks/usePermissions';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import ProductosPrecios from './ProductosPrecios';
import AccesoCot from './molecules/AccesoCot';
import DownloadCloud from '../../Atoms/DownloadCloud';
import ProductosDownload from './ProductosDownload';
import Eye from '../../Icons/Eye';


const Productos = ()=>{

    const theme = useTheme();
    const dispatch = useDispatch();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();  
    const [formType, setFormType] = React.useState();
    const [productoObj, setProductoObj] = React.useState();    
    const navigate = useNavigate();
    const isTablet= useMediaQuery('tablet');
    const productosArray = useSelector(state=>state.productos.productos);
    const permissions = usePermissions()
    const {canCrearProductos} = permissions;
    const [expandedProduct, setExpandedProduct] = React.useState(null);
    

    const handleOnClick = (formType)=>{
        setOpenModal(true);        
        setFormType(formType)
    }

    React.useEffect(()=>{
        return ()=>dispatch(initProductos());
    },[])

    
    

    const smallBottomSwipeDrawers = ["addProducto", "editar", "deactivate", "accesoCot"]

    return(
        <ExternalWrapper>
        <Wrapper>
            <Header>
                <BackButton onClick={()=>navigate(-1)} />
                <ProductosSearchBar />
                {
                    canCrearProductos &&
                    <>
                        <StyledIconButton  onClick={()=>handleOnClick('addProducto')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>
                        {isTablet && <StyledIconButton onClick={()=>handleOnClick('upload')}><UploadCloud color={theme.palette.grey[700]}/></StyledIconButton>}
                        {isTablet && <StyledIconButton onClick={()=>handleOnClick('download')}><DownloadCloud color={theme.palette.grey[700]}/></StyledIconButton>}
                    </>
                }
            </Header>

            <Body>

                {
                    productosArray.length > 0 && productosArray.map((producto, index)=>{
                        const isExpanded = expandedProduct === producto._id;
                        return(
                            <Card key={`${producto._id}`}>
                                <CardBody>
                                    <ProductWrapper>
                                        <Codigo deactivated={producto?.deactivated}>                                            
                                            {producto?.codigo}
                                            {producto?.deactivated && <SmallText>Desactivado</SmallText>}
                                        </Codigo>
                                        <Descripcion deactivated={producto?.deactivated}>
                                            {producto?.descripcion}
                                        </Descripcion>
                                        {isTablet &&
                                            <Fabricante deactivated={producto?.deactivated}>
                                                {producto?.fabricante}
                                            </Fabricante>
                                        }
                                        <StyledIconButtonNoBorder 

                                            onClick={() => setExpandedProduct(isExpanded ? null : producto._id)}
                                            title={isExpanded ? "Ocultar detalles" : "Ver detalles"}
                                        >
                                            <Eye color={isExpanded ? theme.palette.primary.main : theme.palette.grey[700]}/>
                                        </StyledIconButtonNoBorder>
                                        {canCrearProductos &&
                                            <MoreButtonProducts 
                                                setFormType={setFormType} 
                                                setOpenModal={setOpenModal}
                                                productoObj={producto}
                                                setProductoObj={setProductoObj}
                                            />
                                        }
                                    </ProductWrapper>
                                    
                                    {isExpanded && (
                                        <ExpandedInfo>
                                            {producto.competitorNames && (
                                                <InfoSection>
                                                    <InfoLabel>Productos Competitivos:</InfoLabel>
                                                    <InfoText>{producto.competitorNames}</InfoText>
                                                </InfoSection>
                                            )}
                                            {producto.extendedDescription && (
                                                <InfoSection>
                                                    <InfoLabel>Descripción Extendida:</InfoLabel>
                                                    <InfoText>{producto.extendedDescription}</InfoText>
                                                </InfoSection>
                                            )}
                                        </ExpandedInfo>
                                    )}
                                                                        
                                </CardBody>                                
                            </Card>   
                        )
                    })
                }
                

            </Body>

        

        </Wrapper>
         <Modal
            openModal={openModal}
            setOpenModal={setOpenModal} 
            inProp={inProp}
            setInProp={setInProp}
            close={close}
            smallDrawer = {smallBottomSwipeDrawers.includes(formType)}           
        >
            {formType === "addProducto" && <ProductosAdd  close={close}/>}            
            {formType === "upload" && <ProductosUpload  close={close}/>}  
            {formType === "download" && <ProductosDownload  close={close}/>}  
            {formType === "editar" && <ProductosEditar close={close} producto={productoObj}/>}          
            {formType === "deactivate" && <ProductoDesactivar close={close} producto={productoObj} setOpenModal={setOpenModal}/>}
            {formType === "precios" && <ProductosPrecios close={close} producto={productoObj}/>}                    
            {formType === 'accesoCot' && <AccesoCot close={close} producto={productoObj}/>}                
        </Modal>     
     </ExternalWrapper>
    )
}

const ExternalWrapper = styled.div`
        height:100%;
        display:flex;
        justify-content: center;
        margin-bottom: 36px;
`

const Wrapper = styled.div`
    width:100%;
    max-width: 1000px;
`

const Card = styled(CardWrapper)`
    position: relative;
`

const Header = styled.div`
    
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;
    margin-bottom: 16px;

`
const Body = styled.div`
    display:flex;
    flex-direction: column;
    
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`

const StyledIconButtonNoBorder = styled(IconButton)`
    border: none;
`

const ProductWrapper = styled.div`
    display:flex;
    gap:8px;
    align-items:center;
`

const Codigo =styled.div`
    flex:1;
    color: ${p=>p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[800]};
`

const SmallText = styled.p`
    margin:0;
    font-size: 0.75rem;
`
const Descripcion =styled.div`
    flex:4;
    color: ${p=>p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[900]};
`

const Fabricante =styled.div`
    flex:1;
    color: ${p=>p.deactivated ? p.theme.palette.grey[400] : p.theme.palette.grey[900]};
`

const Banner = styled.div`
    position: absolute;
    top:5px;
    left:10px;
    border: 1px solid;
    background-color: ${p=>p.theme.palette.error.light};
    color: white;
    border-radius: 8px;
    padding:4px;
    font-weight: 500;
    font-size: 0.75rem;

`

const ExpandedInfo = styled.div`
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid ${p => p.theme.palette.grey[200]};
`

const InfoSection = styled.div`
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
`

const InfoLabel = styled.div`
    font-weight: 600;
    color: ${p => p.theme.palette.grey[700]};
    margin-bottom: 4px;
    font-size: 0.875rem;
`

const InfoText = styled.div`
    color: ${p => p.theme.palette.grey[900]};
    font-size: 0.875rem;
    white-space: pre-wrap;
`

export default Productos;