import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useQuoteSocket } from '../../../hooks/useQuoteSocket';
import { LinearProgress, useTheme } from '@mui/material';
import Chip from '../../Atoms/Chip';
import AlertCircle from '../../Icons/AlertOctagon';
import CheckCircle from '../../Icons/CheckCircle';
import Loader from '../../Icons/Loader';

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const AnimatedLoader = styled(Loader)`
    animation: ${spin} 1s linear infinite;
`;

const QuoteProcessingStatus = ({ ticketId, ticket }) => {
    const quoteStatus = useQuoteSocket(ticketId, ticket);
    const theme = useTheme();

    useEffect(() => {
        console.log('QuoteProcessingStatus update:', {
            ticketId,
            status: quoteStatus.status,
            progress: quoteStatus.progress
        });
    }, [quoteStatus, ticketId]);

    if (quoteStatus.status === 'pending') {
        return (
            <StatusWrapper>
                <StatusHeader>
                    <StatusChip
                        icon={<AnimatedLoader size={16} color={'white'} />}
                        label="Esperando procesamiento"
                        {...getChipProps(quoteStatus.status)}
                    />
                </StatusHeader>
            </StatusWrapper>
        );
    }

    if (quoteStatus.status === 'processing') {
        return (
            <StatusWrapper>
                <StatusHeader>
                    <StatusChip
                        icon={<AnimatedLoader size={16} color={'white'} />}
                        label="Generando cotización"
                        {...getChipProps(quoteStatus.status)}
                    />
                </StatusHeader>                
            </StatusWrapper>
        );
    }

    return (
        <StatusWrapper>
            <StatusHeader>
                <StatusChip
                    icon={
                        quoteStatus.status === 'completed' ? (
                            <CheckCircle size={16} color={'white'} />
                        ) : quoteStatus.status === 'error' ? (
                            <AlertCircle size={16} color={'white'} />
                        ) : (
                            <AnimatedLoader size={16} color={'white'} />
                        )
                    }
                    label={
                        quoteStatus.status === 'completed'
                            ? 'Borrador de cotización generado por Asistente Virtual'
                            : quoteStatus.status === 'error'
                            ? 'Error al generar cotización'
                            : 'Generando cotización'
                    }
                    {...getChipProps(quoteStatus.status)}
                />
            </StatusHeader>                      
        </StatusWrapper>
    );
};

const StatusWrapper = styled.div`
    padding: 16px;
    background-color: ${props => props.theme.palette.background.paper};
    border-radius: 8px;
    border: 1px solid ${props => props.theme.palette.divider};
    margin: 8px 0;
`;

const StatusHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${props => props.showProgress ? '12px' : '0'};
`;

const StatusChip = styled(Chip)`
    font-weight: 500;
`;

const ErrorMessage = styled.div`
    color: ${props => props.theme.palette.error.main};
    font-size: 14px;
    margin-top: 8px;
`;

// Helper function to determine chip props based on status
const getChipProps = (status) => {
    switch (status) {
        case 'completed':
            return { success: true };
        case 'error':
            return { error: true };
        case 'processing':
            return { warning: true };
        default:
            return {};
    }
};

export default QuoteProcessingStatus; 