import React from "react"
import styled from '@emotion/styled';
import { createOptions, goFetch } from "../../../api/cirugiasApi"
import { apiUrl } from "../../../shared/url"
import CardInfo from "../../Atoms/CardInfo"
import FormActionsBasic from "../../Atoms/FormActionsBasic"
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"

import { CardTitle, CardWrapper } from "../../Atoms/Card";
import CardError from "../../Atoms/CardError";
import BeatLoader from 'react-spinners/BeatLoader'
import { useTheme } from "@mui/system";
import useUpdateCotizacion from "../../../hooks/useUpdateCotizacion";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../app/messagesReducer";
import { useAccionMaker } from "../../../hooks/useAccionMaker";





const CrearPacienteCotizacion = ({
    cotizacion,
    cotizaciones,
    setCotizaciones,
    close,
})=>{
    const dispatch = useDispatch()
    const [pacienteExists, setPacienteExists] = React.useState(false)
    const [pacienteId, setPacienteId] = React.useState(null)
    const [isLoadingPacienteExists, setIsLoadingPacienteExists] = React.useState(true)
    const [pacienteFromDatabase, setPacienteFromDatabase] = React.useState(null)
    const theme = useTheme();
    //const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);  
    //useSuccessButton(successEvent, close)
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const accionMaker = useAccionMaker();
    //check if paciente exists by nss when component loads

  
    const paciente = cotizacion?.paciente
    console.log(paciente)

   

    React.useEffect(()=>{

        if(!paciente?.nss || !paciente?.cedula){
            return
        }

        const checkIfPacienteExists = async(paciente)=>{
            setIsLoadingPacienteExists(true)
            const options = createOptions('POST', {nss:paciente.nss, cedula:paciente.cedula});
            const response = await goFetch(`${apiUrl}cotizaciones/check-paciente-nss-cedula`, options)
            if(response){
                if(response.exists){
                    setPacienteExists(true)
                    //setPacienteId(response.pacienteId)
                    setPacienteFromDatabase(response.paciente)
                    setIsLoadingPacienteExists(false)
                }else{
                    setPacienteExists(false)
                    //setPacienteId(null)                    
                    setIsLoadingPacienteExists(false)
                }
            }
        }
        if(successEvent)return
        checkIfPacienteExists(paciente)
    },[paciente, successEvent])


    if(!paciente?.firstname || !paciente?.lastname || !paciente?.nss || !paciente?.cedula){         
        return(
            <FormWrapper>
            <FormTitle>Crear Paciente</FormTitle>
            <FormBody>
                <CardError fontSize={"0.8rem"} style={{maxWidth:'400px'}}>Debes incluir el primer nombre, apellido, nss y cedula del paciente para poder crearlo.</CardError>
                <StyledCardWrapper>
                <LabelValueWrapper>
                    <Row>
                        <Label>Primer nombre:</Label>
                        <Value>{paciente?.firstname}</Value>
                    </Row>
                    <Row>
                        <Label>Segundo nombre:</Label>
                        <Value>{paciente?.secondLastname}</Value>                        
                    </Row>
                    <Row>
                        <Label >Apellido</Label>
                        <Value >{paciente?.lastname}</Value>                                                
                    </Row>
                    <Row>
                        <Label>Segundo Apellido</Label>
                        <Value>{paciente?.secondLastname}</Value>                                                
                    </Row>  
                    <Row>
                        <Label>NSS</Label>
                        <Value>{paciente?.nss}</Value>                                                
                    </Row> 
                    <Row>
                        <Label>Cedula</Label>
                        <Value>{paciente?.cedula}</Value>                                                
                    </Row>                   
                </LabelValueWrapper>
                
            </StyledCardWrapper>
            </FormBody>
        </FormWrapper>
        )
    }


    const onSubmit = async(e)=>{
        e.preventDefault();
        console.log(paciente)
       
        let accion = accionMaker(`Paciente creado`);
        const info = {            
            paciente,
            accion
        }

        const options = createOptions('PUT', info);
        console.log(options)
        try {
            setIsLoading(true)
            const response = await goFetch(`${apiUrl}cotizaciones/create-paciente/${cotizacion._id}`, options)   
            responseStateChanges(response, ()=>{
                console.log('success callback')
                const updatedCotizaciones = cotizaciones.map(cotizacion=>{
                    if(cotizacion._id === response._id){
                        return response
                    }else{
                        return cotizacion
                    }
                })
                setCotizaciones(updatedCotizaciones)
            }, ()=>close())                                            
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        
        
    }


    const onSubmitAsignarPaciente = async(e)=>{
        e.preventDefault();
        console.log(paciente)
       
        let accion = accionMaker(`Paciente ya creado asignado a cotizacion`);
        const info = {            
            pacienteId:pacienteFromDatabase._id,
            accion
        }

        const options = createOptions('PUT', info);
        console.log(options)
        try {
            setIsLoading(true)
            const response = await goFetch(`${apiUrl}cotizaciones/asignar-paciente-cotizacion/${cotizacion._id}`, options)   
            responseStateChanges(response, ()=>{
                console.log('success callback')
                const updatedCotizaciones = cotizaciones.map(cotizacion=>{
                    if(cotizacion._id === response._id){
                        return response
                    }else{
                        return cotizacion
                    }
                })
                setCotizaciones(updatedCotizaciones)
            }, ()=>close())                                            
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        
        
    }

    
    //if paciente exists show paciente from database together with current paciente information
    if(pacienteExists){


        return(
            <FormWrapper onSubmit={onSubmitAsignarPaciente}>
                <FormTitle>Crear Paciente</FormTitle>
                <FormBody style={{marginBottom:'25px'}}>                
                <CardError fontSize={'0.8rem'} style={{maxWidth:'500px'}}>Paciente con este NSS o Cedula ya existe. Puedes asignar este paciente ya creado a la cotizacion. Verifica que la informacion es la correcta antes de proceder. </CardError>               
                <StyledCardWrapper>
                    <CardTitle>Informacion de paciente en la cotizacion</CardTitle>
                    <LabelValueWrapper>
                        <Row>
                            <Label>Primer nombre:</Label>
                            <Value>{paciente?.firstname}</Value>
                        </Row>
                        <Row>
                            <Label>Segundo nombre:</Label>
                            <Value>{paciente?.secondLastname}</Value>
                        </Row>
                        <Row>
                            <Label >Apellido</Label>
                            <Value >{paciente?.lastname}</Value>
                        </Row>
                        <Row>
                            <Label>Segundo Apellido</Label>
                            <Value>{paciente?.secondLastname}</Value>
                        </Row>
                        <Row>
                            <Label>NSS</Label>

                            <Value>{paciente?.nss}</Value>
                        </Row>
                        <Row>
                            <Label>Cedula</Label>
                            <Value>{paciente?.cedula}</Value>
                        </Row>
                    </LabelValueWrapper>

                </StyledCardWrapper>
                <StyledCardWrapper>
                <CardTitle>Informacion de paciente ya creado que sera asignado al a cotizacion.</CardTitle>
                    <LabelValueWrapper>
                        <Row>
                            <Label>Primer nombre:</Label>
                            <Value>{pacienteFromDatabase?.firstname}</Value>
                        </Row>
                        <Row>
                            <Label>Segundo nombre:</Label>
                            <Value>{pacienteFromDatabase?.secondLastname}</Value>
                        </Row>
                        <Row>
                            <Label >Apellido</Label>
                            <Value >{pacienteFromDatabase?.lastname}</Value>
                        </Row>
                        <Row>
                            <Label>Segundo Apellido</Label>
                            <Value>{pacienteFromDatabase?.secondLastname}</Value>
                        </Row>
                        <Row>
                            <Label>NSS</Label>

                            <Value>{pacienteFromDatabase?.nss}</Value>
                        </Row>
                        <Row>
                            <Label>Cedula</Label>
                            <Value>{pacienteFromDatabase?.cedula}</Value>
                        </Row>
                    </LabelValueWrapper>

                </StyledCardWrapper>                
                </FormBody>
                <FormActionsBasic
                    successText= "Asignar Paciente a Cotizacion"
                    disabledSuccess= {isLoadingPacienteExists}
                    close={close}
                    successEvent={successEvent}
                    loading={isLoading}
                    type="submit"
                    as="button"
                />
            </FormWrapper>


        )

    }



    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Crear Paciente</FormTitle>
            <FormBody>
            {(!pacienteExists && !isLoadingPacienteExists) && <CardInfo fontSize={"0.8rem"}>Confirmar la informacion de paciente antes de crearlo</CardInfo>}
            {pacienteExists && <CardError fontSize={"0.8rem"}>Paciente con este NSS o Cedula ya existe. </CardError>}
            {isLoadingPacienteExists && <CardInfo fontSize={"0.8rem"}><BeatLoaderWrapper><BeatLoader size={8} color={theme.palette.grey[500]} /></BeatLoaderWrapper></CardInfo>}                    
            <StyledCardWrapper>
                <LabelValueWrapper>
                    <Row>
                        <Label>Primer nombre:</Label>
                        <Value>{paciente?.firstname}</Value>
                    </Row>
                    <Row>
                        <Label>Segundo nombre:</Label>
                        <Value>{paciente?.secondLastname}</Value>                        
                    </Row>
                    <Row>
                        <Label >Apellido</Label>
                        <Value >{paciente?.lastname}</Value>                                                
                    </Row>
                    <Row>
                        <Label>Segundo Apellido</Label>
                        <Value>{paciente?.secondLastname}</Value>                                                
                    </Row>  
                    <Row>
                        <Label>NSS</Label>
                        <Value>{paciente?.nss}</Value>                                                
                    </Row> 
                    <Row>
                        <Label>Cedula</Label>
                        <Value>{paciente?.cedula}</Value>                                                
                    </Row>                   
                </LabelValueWrapper>
                
            </StyledCardWrapper>
            
            </FormBody>
            <FormActionsBasic
                successText= "Crear Paciente"
                disabledSuccess={pacienteExists || isLoadingPacienteExists}
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
            />

            

        </FormWrapper>


    )
}





const StyledCardWrapper = styled(CardWrapper)`
    margin: 0;
    max-width: 500px;
    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
    }
`

const LabelValueWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    
    border-radius: 4px;
    flex:1;

    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
        
    }
`

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`   

const Label = styled.span`
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    font-size: 0.8rem;
`

const Value = styled.span`
    font-size: 0.8rem;    
    text-align: right;
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
`


const BeatLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export default CrearPacienteCotizacion