import { CardWrapper } from "../../Atoms/Card"
import styled from '@emotion/styled';
import { round } from "../CotizacionesHelpers";




const TotalsCard = ({ 
    totalCotizacion
}) => {


    return(
        <StyledCardWrapper>
                <Totals>
                    <TotalRow>
                        <TotalLabel bold>Total sin ITBIS</TotalLabel>
                        <TotalValue bold>{round(totalCotizacion.totalSinItbis)}</TotalValue>
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Descuento</TotalLabel>
                        <TotalValue>{round(totalCotizacion.descuento)}</TotalValue>                        
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel bold>Subtotal</TotalLabel>
                        <TotalValue bold>{round(totalCotizacion.subtotal)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Exento de ITBIS</TotalLabel>
                        <TotalValue>{round(totalCotizacion.exentoItbis)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Total Gravado</TotalLabel>
                        <TotalValue>{round(totalCotizacion.totalGravado)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>ITBIS</TotalLabel>
                        <TotalValue>{round(totalCotizacion.itbis)}</TotalValue>
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel bold>Total General</TotalLabel>
                        <TotalValue bold>{round(totalCotizacion.totalGeneral)}</TotalValue>
                    </TotalRow>
                </Totals>
            </StyledCardWrapper>
    )
}


const StyledCardWrapper = styled(CardWrapper)`
    margin: 0;
    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
    }
`

const Totals = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    
    border-radius: 4px;
    flex:1;

    @media ${p=>p.theme.media.tablet}{
        padding: 16px;
        border: 1px solid ${p=>p.theme.palette.grey[300]};
    }
`

const TotalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`   

const TotalLabel = styled.span`
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
    font-size: 0.8rem;
`

const TotalValue = styled.span`
    font-size: 0.8rem;    
    text-align: right;
    color: ${p=>p.bold? p.theme.palette.grey[800] : p.theme.palette.grey[700]};   
    font-weight: ${p=>p.bold? "800" : "400"};
`


export default TotalsCard