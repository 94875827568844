import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { createOptions, goFetch } from "../api/cirugiasApi";
import { addOneCirugia } from "../app/cirugiaReducer";
import { addUpdatedCirugia } from "../app/cirugiasReducer";
import { errorMessage, successMessage } from "../app/messagesReducer";
import { apiUrl } from "../shared/url";


//provides loading and success event and includes fetch for updating cirugias


// addUpdatedCirugia(state,action){     
//     state.cirugias.forEach((cirugia, index) => {
//         if(cirugia._id === action.payload._id){                 
//             state.cirugias[index] = action.payload
//         }
//         return                
//     });
//     state.isLoadingUpdate = false;

// In this case we are not using redux but instead we are using state from the component that is using this hook.  So we need to update the state of the component that is using this hook.  We do this by passing the state and the setState function as arguments to the hook.  Then we can use the state and setState function to update the state of the component that is using this hook.

const useUpdateCotizacion = (close, cotizaciones, setCotizaciones)=>{
    const dispatch = useDispatch()
    const [isLoading,setIsLoading] = React.useState(false);
    const [successEvent, setSuccessEvent] = React.useState(false);
    
    const updateCotizacion = async(url, values)=>{

        const options = createOptions('PUT', values)
        let response;

        try{
            if(isLoading || successEvent) return
            setIsLoading(true);
            response = await goFetch(`${apiUrl}cotizaciones/${url}/${values.cotizacionId}`, options, dispatch)
            if(response.error) throw new Error(response.error) 

            //find cotizacion in cotizaciones and update it and set the new state
            const updatedCotizaciones = cotizaciones.map(cotizacion=>{
                if(cotizacion._id === response._id){
                    return response
                }else{
                    return cotizacion
                }
            })
            setCotizaciones(updatedCotizaciones)
                        
            //added this in order to have available cirugia after refresh on cirugia or when coming from page other than cirugias list
            //dispatch(addOneCirugia(response.cirugia));
            setSuccessEvent(true);
            dispatch(successMessage('Cotizacion actualizada correctamente.'));
            setIsLoading(false);
            setTimeout(()=>{
                close && close();
                setSuccessEvent(false);
            }, 1500)
        }catch(error){
            console.log(error);
            setIsLoading(false)            
            dispatch(errorMessage(error.error || "Problema de servidor, por favor intente mas tarde."))
        }
    }


    return { 
        isLoading,
        setIsLoading,
        successEvent,
        setSuccessEvent,
        updateCotizacion

    }
}


export default useUpdateCotizacion;