import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { errorMessage } from "../../../app/messagesReducer";
import { useAccionMaker } from "../../../hooks/useAccionMaker";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { apiUrl } from "../../../shared/url";
import CardError from "../../Atoms/CardError";
import FormActionsBasic from "../../Atoms/FormActionsBasic"
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms"
import Check from "../../Icons/Check";
import X from "../../Icons/X";
import { is } from "date-fns/locale";






const CrearCirugiaCotizacion = ({
    cotizacion,
    cotizaciones,
    setCotizaciones,
    close
})=>{

    const theme = useTheme();
    const dispatch = useDispatch();
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton();
    const accionMaker = useAccionMaker()

    const requiredArray = [
        {"Doctor": cotizacion?.doctor?._id},
        {"Centro": cotizacion?.centro?._id},
        {"Paciente Creado": cotizacion?.paciente?._id},
        {"Productos Aprobados": cotizacion?.productosAprobados[0]?._id},
        {"Ventas": cotizacion?.ventas?._id},
        {"Especialidad": cotizacion?.especialidad},
        
    ]

    //if the cotizacion is an ars, then add the diferenciaPaciente to the requiredArray
    console.log(cotizacion?.tipo)
    if(cotizacion?.client?.tipo === 'Ars'){

        console.log('ars')
        requiredArray.push({"Diferencia Paciente": cotizacion?.diferenciaPaciente})
    }

    console.log(requiredArray)
    //if all the values for each key of requiredArray are true, then the user can create the surgery
    const canCreateCirugia = requiredArray.every((item)=>{
        return Object.values(item)[0]
                
    })

    console.log(canCreateCirugia)


    const onsubmit = async (e)=>{
        e.preventDefault();

        if(isLoading){
            return
        }
        
       
        let accion = accionMaker(`Cirugia creada`);
        const info = {                                    
            accion
        }

        const options = createOptions('POST', info);
        console.log(options)
        try {
            setIsLoading(true)
            const response = await goFetch(`${apiUrl}cotizaciones/create-cirugia/${cotizacion._id}`, options)   
            responseStateChanges(response, ()=>{
                console.log('success callback')
                const updatedCotizaciones = cotizaciones.map(cotizacion=>{
                    if(cotizacion._id === response._id){
                        return response
                    }else{
                        return cotizacion
                    }
                })
                setCotizaciones(updatedCotizaciones)
            }, ()=>close())                                            
        }catch(err){
            dispatch(errorMessage(err.error))
        }
        
       
    }




    return(
        <FormWrapper onSubmit={onsubmit} >
            <FormTitle>Crear Cirugia</FormTitle>
            <FormBody style={{marginBottom:'25px'}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>

            {// check each item in array, if true show the key with a checkmark next to it, if false show the key with an X next to it. Show the value every 1 second
            //if all the values are true, then the user can create the surgery
            requiredArray.map((item, index) => {
                return (
                  <div key={index} style={{ display: "flex", justifyContent:"space-between", minWidth:"250px"}}>                    
                      <div style={{alignSelf: "center"}}>
                      {Object.values(item)[0] ? (
                        <div style={{display:"flex", gap: '25px',}}>                            
                            <div>
                                <Check color={theme.palette.success.light} />
                            </div>
                            <div>
                                {Object.keys(item)[0]}
                            </div>
                        </div>
                        
                      ) : (

                       <div style={{display:"flex", gap: '25px',}}>                            
                            <div>
                                <X color={theme.palette.error.light} />
                            </div>
                            <div>
                                {Object.keys(item)[0]}
                            </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
                      })}


            </div>
            {!canCreateCirugia && <CardError style={{maxWidth: '400px'}} fontSize={'0.8rem'}>Para crear la cirugia debe estar toda la informacion guardada</CardError>}
            </FormBody>
            <FormActionsBasic
                successText= "Crear Cirugia"
                disabledSuccess= {!canCreateCirugia}
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
                />
        </FormWrapper>
    )    
}




export default CrearCirugiaCotizacion