import React from 'react';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import DoctorsAutocomplete from '../../Forms/CustomAutocomplete/DoctorsAutocomplete';


const ModifyDoctorCotizacion = ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    
    const isTablet = useMediaQuery('tablet');    
    const [selectedDoctor, setSelectedDoctor] = React.useState(null);        
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);
    const accionMaker = useAccionMaker();
      console.log(cotizacion)    
    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion
        if(cotizacion?.doctor?.lastname){
             accion = accionMaker(`Doctor cambio de ${cotizacion?.doctor?.lastname} a ${selectedDoctor?.lastname}`);
        }else{
            accion = accionMaker(`Doctor agregado a la cotizacion`); 
        }
                
        let infoDoctor = {
            doctorId: selectedDoctor._id,
            firstname: selectedDoctor.firstname,
            secondname: selectedDoctor.secondname || "",
            lastname: selectedDoctor.lastname,
            secondLastname: selectedDoctor.secondname || "",
            fullname: selectedDoctor.firstname + " " + selectedDoctor.lastname,
            especialidad: selectedDoctor?.especialidad,            
            cotizacionId: cotizacion._id,            
            accion: accion
          }

          updateCotizacion('update-doctor',infoDoctor)
            
        }        
      
       const onChangeHandler = (name,option)=>{                 
            setSelectedDoctor(option)
            setSelectedDoctor(prevState=> {console.log(prevState); return prevState})
       }

    return(
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet} >Modificar Doctor</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <DoctorsAutocomplete
                    onChange={onChangeHandler}
                />                  
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyDoctorCotizacion