import styled from '@emotion/styled/macro';
import LabelValue from '../Atoms/LabelValue';
import ReactToPrint from 'react-to-print';
import React from 'react';
import Button from '../Atoms/Button';
import dateFormatDR from '../../shared/dateFormatDR';
import getFullName from '../../shared/getFullname';
import { round } from './CotizacionesHelpers';

const marginTop = '16px';
const marginRight = 0;
const marginBottom = 0;
const marginLeft = 0;


const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };


  
//? what is wrong with the cotizacion prop?


const CotizacionLayout = React.forwardRef(({
    cotizacion,        
}, ref) => {  

    console.log(cotizacion)
    //access last item in productos and totalCotizacion array
    const client = cotizacion.client
    const productos = cotizacion.productos[cotizacion.productos.length - 1]
    const totales = cotizacion.totalCotizacion[cotizacion.totalCotizacion.length - 1]
    const paciente = cotizacion.paciente
    const doctor = cotizacion.doctor
    const centro = cotizacion.centro    
    const nota = cotizacion.nota
    const creadoPor = getFullName(cotizacion.creadoPor)
    const ventas = cotizacion.ventas &&getFullName(cotizacion.ventas)
    const firma = cotizacion.creadoPor?.signature || null
    console.log(firma)
    

    //format date to locale string in the dominican republic
    //const createdAtDate = new Date(cotizacion.createdAt) -- old way of getting date changed on May 21,2024
    // Will now use dateModified array to get the date of issue. Will use last date on the array

    const createdAtDate = new Date(cotizacion.dateModified[cotizacion.dateModified.length - 1])





    const fechaEmision = dateFormatDR(createdAtDate)
    //add 30 days to the date of issue
    const fechaVencimiento = dateFormatDR(createdAtDate.setDate(createdAtDate.getDate() + 30))
    

    return (
        <ExternalWrapper ref={ref}>
            <style>{getPageMargins()}</style>   
            <CotizacionHeader>
                <CompanyInfo>                    
                    <ImageLogo 
                        src={`https://cirugias-gallery.s3.amazonaws.com/Logo_Horizontal_400px.png?v=${Date.now()}`}
                        crossOrigin="anonymous"
                    />
                    <Text>Manuel de Jesus Troncoso, Esq. Calle 2, Plaza Alcazar, Local 103, Ensanche Piantini, Santo Domingo, Republica Dominicana</Text>            
                    <Text>Telf: 809-683-0014</Text>
                    <Text>RNC: 130963241</Text>            
                </CompanyInfo> 
                <CompanyInfo>
                <CotizacionNumber>{`Cotizacion # ${cotizacion?.cotizacionNumber || ""}`}</CotizacionNumber>                  
                <PaymentInfo>   
                
                <LocalPaymentInfo>
                    <PaymentTitle>Pagos Locales</PaymentTitle>
                    <PaymentText>Cheque: A nombre de Hospal Medica, SRL</PaymentText>
                    <PaymentText>Transferencia: Banco Popular Dominicano. Pesos. Cta. Corriente 784986341</PaymentText>                    
                </LocalPaymentInfo>
                
                <InternationalPaymentInfo>
                     <PaymentTitle>Pagos Internacionales o en Dolares</PaymentTitle>                                        
                    <PaymentText>Cuenta: 784986663</PaymentText>
                    <PaymentText>Banco Popular Dominicano</PaymentText>
                    <PaymentText>Avenida John F. Kennedy, esquina Avenida Maximo Gomez, Santo Domningo, Republica Dominicana</PaymentText>
                    <PaymentText>Telf: 809-544-5555</PaymentText>

                </InternationalPaymentInfo>
                


           </PaymentInfo>
                    
                    </CompanyInfo>               
            </CotizacionHeader>
            <ClientInfo>
                <InvoiceInfo>
                    <ToContainer><Heading>Dirigido a:</Heading></ToContainer>
                   <LabelValueContainer> 
                        <LabelValueRow >
                            <Label >Nombre:</Label>
                            <Value>{client?.name}</Value>                        
                        </LabelValueRow>  
                       {client?.rnc && 
                            <LabelValueRow >
                                <Label>RNC:</Label>
                                <Value>{client?.rnc}</Value>                                                
                            </LabelValueRow>   
                        }    
                        {
                         client?.cedula &&
                            <LabelValueRow >
                                <Label>Cedula:</Label>
                                <Value>{client?.cedula}</Value>                                                
                            </LabelValueRow>  
                        }                                  
                        <LabelValueRow >
                            <Label>Direccion:</Label>
                            <Value >{client?.address}</Value>                        
                        </LabelValueRow>                                      
                        <LabelValueRow >
                            <Label>Fecha:</Label>
                            <Value>{fechaEmision}</Value>
                        </LabelValueRow>
                        <LabelValueRow >
                            <Label>Vencimiento:</Label>
                            <Value>{fechaVencimiento}</Value>
                        </LabelValueRow>
                        <LabelValueRow >
                            <Label>Moneda:</Label>
                            <Value>{cotizacion.currency === 'pesos' ? 'DOP' : 'USD'}</Value>
                        </LabelValueRow>                                        
                    </LabelValueContainer>

                </InvoiceInfo>
                <SurgeryInfo>
                    <ToContainer><Heading>Informacion Cirugia</Heading></ToContainer>       
                    <LabelValueContainer>
                        <LabelValueRow>
                            <Label>Nombre Paciente:</Label>
                            <Value>{`${paciente?.firstname || ""} ${paciente?.secondname || ""} ${paciente?.lastname || ""} ${paciente?.secondLastname || ""}`}</Value>                            
                        </LabelValueRow>
                        <LabelValueRow>
                            <Label>Cedula Paciente:</Label>
                            <Value>{paciente?.cedula}</Value>
                        </LabelValueRow>
                        <LabelValueRow>
                            <Label>NSS Paciente:</Label>
                            <Value>{paciente?.nss}</Value>
                        </LabelValueRow>
                        <LabelValueRow>
                            <Label>Medico:</Label>
                            <Value>{`${doctor?.firstname || ""} ${doctor?.lastname || ""}`}</Value>
                        </LabelValueRow>
                        <LabelValueRow>
                            <Label>Centro:</Label>
                            <Value>{`${centro?.nombre || ""}`}</Value>
                        </LabelValueRow>
                    </LabelValueContainer>                    
                </SurgeryInfo>

            </ClientInfo>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableHeadRow>
                            <TableHeadCell>Codigo</TableHeadCell>
                            <TableHeadCell style={{minWidth:'200px'}}>Descripcion</TableHeadCell>
                            <TableHeadCell align='right'>Cantidad</TableHeadCell>
                            <TableHeadCell align='right'>Precio</TableHeadCell>
                            {totales?.descuento ? <TableHeadCell align='right'>Descuento</TableHeadCell> : null}
                            <TableHeadCell align='right'>Subtotal</TableHeadCell>   
                            <TableHeadCell align='right'>ITBIS</TableHeadCell>
                            <TableHeadCell align='right'>Total</TableHeadCell>                                                                                 
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>

                        {productos.map((product, index)=>(
                            <TableRow key={index}>
                                <TableCell>{product.codigo}</TableCell>
                                <TableCell>{product.descripcion}</TableCell>
                                <TableCell align='right'>{product.quantity}</TableCell>
                                <TableCell align='right'>{round(product.clientPrice)}</TableCell>
                               {totales?.descuento ? <TableCell align='right'>{`${round(product.discount)} (${product.percentDiscount}%) `}</TableCell>  : null}
                                <TableCell align='right'>{round(product.subtotal)}</TableCell>
                                <TableCell align='right'>{round(product.itbis)}</TableCell>
                                <TableCell align='right'>{round(product.total)}</TableCell>
                            </TableRow>
                        ))}                                                        
                    </TableBody>
                </Table>                        
            </TableContainer>
            <TotalsContainer>
                <AdditionalInfo>
                    <LabelValueContainer>
                        {nota && <LabelValueRow>
                            <NotaLabel>Nota:</NotaLabel>
                            <Value>{nota}</Value>
                        </LabelValueRow>}
                        <LabelValueRow>
                            <Label>Ejecutivo de ventas:</Label>
                            <Value>{ventas}</Value>
                        </LabelValueRow>
                        <LabelValueRow>
                            <Label>Preparado por:</Label>
                            <Value>{creadoPor}</Value>
                        </LabelValueRow>                        
                    </LabelValueContainer>
                    <Image 
                        src={`https://cirugias-gallery.s3.amazonaws.com/8076FEC8-8653-4584-A99F-ECA76C1588C8-removebg-preview.png?v=${Date.now()}`}
                        alt="Sello" 
                        crossOrigin="anonymous"
                    />
                  {firma && 
                    <Firma 
                        src={`${firma}?v=${Date.now()}`} 
                        alt="Firma" 
                        crossOrigin="anonymous"
                    />
                  }
                </AdditionalInfo>   

                
                <Totals>
                    <TotalRow>
                        <TotalLabel>Total sin ITBIS</TotalLabel>
                        <TotalValue>{round(totales.totalSinItbis)}</TotalValue>
                    </TotalRow>
                   {totales.descuento ? <TotalRow>
                        <TotalLabel>Descuento</TotalLabel>
                        <TotalValue>{round(totales.descuento)}</TotalValue>                        
                    </TotalRow> : null}
                    <TotalRow>
                        <TotalLabel>Subtotal</TotalLabel>
                        <TotalValue>{round(totales.subtotal)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Exento de ITBIS</TotalLabel>
                        <TotalValue>{round(totales.exentoItbis)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Total Gravado</TotalLabel>
                        <TotalValue>{round(totales.totalGravado)}</TotalValue>                                                
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>ITBIS</TotalLabel>
                        <TotalValue>{round(totales.itbis)}</TotalValue>
                    </TotalRow>
                    <TotalRow>
                        <TotalLabel>Total General</TotalLabel>
                        <TotalValue>{round(totales.totalGeneral)}</TotalValue>
                    </TotalRow>
                </Totals>
            </TotalsContainer>
            


            
        </ExternalWrapper>

    )


    })



const Cotizacion = ({cotizacion, createPDF})=>{
    const componentRef = React.useRef();

    const onAfterPrint = () => {
        document.title = "Xirugias_ Sistema para gestion de cirugias";
    }

    // ADD: Construct the title string here
    const cotizacionNumber = cotizacion?.cotizacionNumber || "";
    const firstname = cotizacion?.paciente?.firstname || "";
    const lastname = cotizacion?.paciente?.lastname || "";
    const nss = cotizacion?.paciente?.nss || "";
    const printDocumentTitle = `Cotizacion ${cotizacionNumber} ${firstname} ${lastname} NSS ${nss}`;

    if (createPDF){
        return (
            <CotizacionLayout ref={componentRef} cotizacion={cotizacion} />
        )
    }

    return (
        <>
        <ReactToPrint
            trigger={() => <Button primary>Bajar o Imprimir</Button>}
            content={() => componentRef.current}
            documentTitle={printDocumentTitle}
            onAfterPrint={()=>{onAfterPrint()}}
 
        />
        <CotizacionLayout ref={componentRef} cotizacion={cotizacion} />
      
        </>


       

    )   
        
        
        
        
}


const ExternalWrapper = styled.div`
    display:flex;
    width:100%;
    max-width: 1000px;
    flex-direction: column;
    justify-content: center;
    
    background-color: white;
       
`

const CotizacionHeader = styled.div`
    display:flex;
    gap:8px

    
    
`
//company info should be half the width of the cotizacion header

const CompanyInfo = styled.div`
    display:flex;
    flex:1;
    flex-direction: column;
    flex-wrap: wrap;        
    width:100%;
    padding: 16px 16px 16px 16px;
`


const CompanyName = styled.span`
 flex:1;
 font-size: 3rem;
`

const CotizacionNumber = styled.span`
  font-size: 2rem;
  line-height: 1.2;          /* or 1.1 or "normal" */
  letter-spacing: 0.03em;    /* small additional tracking */
`

const Text = styled.span`
    font-size: 0.7rem;
`

const ClientInfo = styled.div`
    display:flex;    
    padding: 24px 16px 16px 16px;
    gap: 16px;
    
`

const InvoiceInfo = styled.div`
   border: 1px solid black;
   padding: 16px;
   flex:1;
   
`

const SurgeryInfo = styled.div`
    border: 1px solid black;
    padding: 16px;
    flex:1;
    
`

const ToContainer = styled.div`
    background-color: ${p=>p.theme.palette.grey[100]};
    padding: 4px;
    margin: -16px -16px 16px -16px; 
`

const Heading= styled.span`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
    
`

const LabelValueContainer = styled.div`     
    gap: 8px;
    display: flex;
    flex-direction: column ;
`

const TableContainer = styled.div`
    
    border-radius: 4px;
    width: var(--container-width);
    overflow: auto;
    padding: 16px;
`

const Table = styled.table`
    border-collapse: collapse;
    width:100%;
    padding: 16px;
`

const TableHead = styled.thead`
`

const TableHeadRow = styled.tr`
    border-top: 1px solid ${p=>p.theme.palette.grey[400]};
    background-color: ${p=>p.theme.palette.grey[100]};   
`
const TableHeadCell = styled.th`
    padding: 8px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    font-size: 0.75rem;
    text-align: left;
    white-space: wrap;
    text-align: ${p=>p.align};
    
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    
`

const TableCell = styled.td`
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    padding: 8px;
    font-size: 0.75rem;
    text-align: ${p=>p.align};
`

const TotalsContainer = styled.div`  
    display: flex;
    
    gap: 16px;
    padding: 16px;
    page-break-inside: avoid;
`

const Totals = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border: 1px solid ${p=>p.theme.palette.grey[400]};
    border-radius: 4px;
    flex:1;
`

const TotalRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
`   

const TotalLabel = styled.span`
    font-weight: bold;
    font-size: 0.8rem;
    text-align: right;
`

const TotalValue = styled.span`
    font-size: 0.8rem;
    text-align: right;    
`

const PaymentInfo = styled.div` 
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 4px;
    
    border-radius: 4px;
    page-break-inside: avoid;

            
    
   
`

const LocalPaymentInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
const PaymentText = styled.span`  
    font-size: 0.5rem;
`
const InternationalPaymentInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const PaymentTitle = styled.span`
    font-weight: bold;
    font-size: 0.5rem;
`

const AdditionalInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;    
    border-radius: 4px;
    flex:1; 
    position: relative;
    
`
const LabelValueRow = styled.div`
        display: flex;
        flex-direction: row;
        gap: 8px;
    `

const Label= styled.span`
    font-weight: bold;
    font-size: 0.75rem;
    min-width: 100px;
`

const Value = styled.span`
    font-size: 0.75rem;
`

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    max-width: 250px;
    position: absolute;
    top: 30px;
    right: 20px;
    

    @media ${p=>p.theme.media.tablet}{
        width: revert;
    }

`

const Firma = styled.img`
    object-fit: cover;
    width: 100%;
    max-width: 250px;
    position: absolute;
    top: 1px;
    right: 20px;
    

    @media ${p=>p.theme.media.tablet}{
        width: revert;
    }

`


const ImageLogo = styled.img`
    object-fit: cover;
    width: 100%;
    max-width: 350px;
    padding-bottom: 8px;
    
    
    

    @media ${p=>p.theme.media.tablet}{
        width: revert;
    }

`

const NotaLabel = styled(Label)`
    min-width: 35px;

`



export default Cotizacion
