import React from 'react';
import styled from '@emotion/styled';
import { Menu, MenuItem } from "@mui/material";
import IconButton from "../../Atoms/IconButton";
import { useTheme } from "@mui/material";
import Flag from "../../Icons/Flag";

const PriorityDropDown = ({ onPriorityChange, isClosed }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (priority) => {
        onPriorityChange(priority);
        handleClose();
    };

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 'Baja':
                return theme.palette.success.main;
            case 'Media':
                return theme.palette.warning.main;
            case 'Alta':
                return theme.palette.error.main;            
            default:
                return theme.palette.grey[700];
        }
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Flag 
                    size={21}
                    color={isClosed ? theme.palette.grey[400] : theme.palette.grey[700]}
                />
            </IconButton>
            <StyledMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleSelect('Baja')}>
                    <MenuItemWrapper>
                        <PriorityDot color={getPriorityColor('Baja')} />
                        <Text>Baja</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('Media')}>
                    <MenuItemWrapper>
                        <PriorityDot color={getPriorityColor('Media')} />
                        <Text>Media</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('Alta')}>
                    <MenuItemWrapper>
                        <PriorityDot color={getPriorityColor('Alta')} />
                        <Text>Alta</Text>
                    </MenuItemWrapper>
                </MenuItem>                
            </StyledMenu>
        </div>
    );
};

const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        min-width: 200px;
    }
`;

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p => p.theme.palette.grey[700]};
`;

const PriorityDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.color};
`;

export default PriorityDropDown; 