import React from 'react';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import { round } from '../CotizacionesHelpers';
import { isNumber } from '../../../shared/validators';
import TextInput from '../../Atoms/TextInput';
import useForm from '../../../hooks/useForm';




const ModifyDiferencia= ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    
    const isTablet = useMediaQuery('tablet');    
    const [values, setValues] = React.useState({
        diferenciaPaciente: cotizacion?.diferenciaPaciente || 0,
    });   
    const [errors, setErrors] = React.useState({});
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);        
    const accionMaker = useAccionMaker();
    const {onChangeHandler, onChangeValuesValidation, onBlurValidate} = useForm({setValues, setErrors, errors})
         
           
    


    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion
        
        accion = accionMaker(`Diferencia Paciente cambio de ${cotizacion?.diferenciaPaciente} a ${values?.diferenciaPaciente}`); 
        
                
        let infoDiferenciaPaciente = {
            diferenciaPaciente: values.diferenciaPaciente,    
            cotizacionId: cotizacion._id,
            accion:accion
            }            
            updateCotizacion('update-diferencia-paciente',infoDiferenciaPaciente)            
    }   
    

    return(
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet} >Modificar Diferencia Paciente</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>
            <br/>
            <TextInput 
                    label={"Diferencia Paciente"}
                    name="diferenciaPaciente"
                    style={{textAlign: 'end'}}
                    value={typeof values?.diferenciaPaciente === 'number' ? round(values.diferenciaPaciente.toString()) : values.diferenciaPaciente} 
                    onChangeHandler={(event)=>onChangeValuesValidation(event, [isNumber], true)}
                    endIcon={cotizacion.currency === 'pesos'?<span>pesos</span> : <span>dolares</span>}
                    helperText={errors.price}
                    error={errors.price}
                    onBlurValidation={(event)=>onBlurValidate(event, [isNumber])}
                    
                    
                ></TextInput>               
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyDiferencia