import styled from '@emotion/styled';
import React from 'react';
//import { dateAndTimeFormatDR } from '../../../shared/dateFormatDR';
import IconButton from '../../Atoms/IconButton';
import ChevronLeft from '../../Icons/ChevronLeft';
import ChevronRight from '../../Icons/ChevronRight';
import ProductosTableCotizacion from "./ProductosTableCotizacion"
import TotalsCard from "./TotalsCard"
import useDateAndTimeFormatter from '../../../hooks/useDateAndTimeFormatter';


const VerProductosCotizacion = ({ 
    productos,
    setFormType,
    setOpenModal,
    setProductoId,
    setSelectedProductoEdit,
    totalCotizacion,
    dateModified,    
    viewOnly
}) => {

    const {dateAndTimeFormatDR} = useDateAndTimeFormatter()
    const numeroDeVersiones = productos.length
    //const version = productos[numeroDeVersiones - 1]
    const [version, setVersion] = React.useState(numeroDeVersiones)
    const [productosVer, setProductosVer] = React.useState(productos[version - 1])
    const [totalCotizacionVer, setTotalCotizacionVer] = React.useState(totalCotizacion[version - 1])
    const [dateModifiedVer, setDateModifiedVer] = React.useState(dateModified[version - 1])

    console.log(dateModified)
    console.log(dateModifiedVer)

    const onClickBack = () => {
        console.log('clicked back')
        if (version > 1) {
            setVersion(version - 1)
            setProductosVer(productos[version - 2])
            setTotalCotizacionVer(totalCotizacion[version - 2])
            setDateModifiedVer(dateModified[version - 2])
        }
    }

    const onClickForward = () => {
        if (version < numeroDeVersiones) {
            setVersion(version + 1)
            setProductosVer(productos[version])
            setTotalCotizacionVer(totalCotizacion[version])
            setDateModifiedVer(dateModified[version])
        }
    }

   

    return(
        <Wrapper>
            <VersionControl>
                <IconButton onClick={onClickBack}><ChevronLeft/></IconButton>
                {version === numeroDeVersiones ? <VersionWrapper>
                                                    <StyledVersion>{`Version actual - `}</StyledVersion>
                                                    <StyledDate>{`${dateAndTimeFormatDR(dateModifiedVer)}`}</StyledDate>
                                                    </VersionWrapper> : 
                                                <VersionWrapper>
                                                    <StyledVersion>{`Version ${version} de ${numeroDeVersiones} - `}</StyledVersion>
                                                    <StyledDate>{`${dateAndTimeFormatDR(dateModifiedVer)}`}</StyledDate>
                                                </VersionWrapper>}
                                                            
                <IconButton onClick={onClickForward}><ChevronRight/></IconButton>
            </VersionControl>
            <ProductosTableCotizacion
                productos={productosVer}
                setFormType={setFormType}
                setOpenModal={setOpenModal}
                setProductoId={setProductoId}
                setSelectedProductoEdit={setSelectedProductoEdit}
                viewOnly={viewOnly}
            />
            <TotalsCard totalCotizacion={totalCotizacionVer}/>
        </Wrapper>
    )

}


const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    gap: 8px;
    flex:1;
`
const VersionControl = styled.div`
    display: flex;  
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px 0px;
`
const VersionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`


const StyledVersion = styled.p`
    margin: 0;
    font-size: 1rem;
    color: ${p=>p.theme.palette.grey[800]};}};
`
const StyledDate = styled.p`
    margin: 0;
    font-size: 0.8rem;
    color: ${p=>p.theme.palette.grey[600]};}};
`



export default VerProductosCotizacion