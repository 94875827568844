import Chip from "../../../Atoms/Chip";
import IconButton from "../../../Atoms/IconButton";
import Search from "../../../Icons/Search";
import { StepButtonWrapper, StepHeading, StepWrapper } from "./StepStyles";
import BeatLoader from "react-spinners/BeatLoader";
import { useTheme } from "@mui/material";






const ProductSelection = ({
    setFormType,
    setOpenModal,
    isLoadingQuickProduct,
    quickProductos,
    isProductSelected,
    onClickQuickProduct
}) => {
    const theme = useTheme()

    return (
        <StepWrapper>
                    <StepHeading>Seleccionar productos
                        <div style={{display: 'flex'}}>
                            <IconButton size={'small'}>
                            <Search size={20} onClick={()=>{setFormType('addProducto');setOpenModal(true)}}/>
                            </IconButton>                            
                        </div>

                    </StepHeading>
                    <StepButtonWrapper>
                    
                        {isLoadingQuickProduct && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}
                        {!isLoadingQuickProduct && quickProductos.length >0 &&                         
                            quickProductos?.map(producto => (                                
                                <Chip 
                                    key={producto._id}
                                    success={isProductSelected(producto)} 
                                    outline={!isProductSelected(producto)}                                     
                                    label={producto.quickProducto.shortDescription} 
                                    onClick={()=>onClickQuickProduct(producto)}
                                />    ))}                                                             
                    </StepButtonWrapper>                    
                </StepWrapper>
    )
}

export default ProductSelection