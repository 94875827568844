import styled from "@emotion/styled";
import useModalAndDrawer from "../../../../hooks/useModalAndDrawer";
import { FormBody, FormTitle, FormWrapper } from "../../../Atoms/Forms";
import Modal from "../../../Atoms/Modal";
import Cotizacion from "../../Cotizacion";
import Button from "../../../Atoms/Button";
import React, { useEffect } from "react";
import { createRoot } from 'react-dom/client';
import html2pdf from "html2pdf.js";
import { ThemeProvider } from '@mui/system';
import customTheme from '../../../../util/Theme';
import { apiUrl } from '../../../../shared/url';



const PostCrear = ({
        newCotizacion, 
        onClickVerCotizacion, 
        openModal,
        setOpenModal,
        inProp,
        setInProp,
        close,
        formType,
        handleGeneratePdf,
        isAttaching,
        attachStatus,
        setOpenAttachmentModal
    }) => {

    const smallBottomSwipeDrawers = []

    
    const onClickCrearPDF = async () => {
        // Create a temporary iframe to render the Cotizacion component
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        
        //const componentRef = React.createRef();
        
        const root = createRoot(iframe.contentDocument.body);
        root.render(
            <ThemeProvider theme={customTheme}>
                <Cotizacion                    
                    cotizacion={newCotizacion} 
                    createPDF={true}
                />
            </ThemeProvider>
        );

        try {
            // Wait for images to load
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Generate PDF and trigger download
            await html2pdf()
                .set({
                    margin: [5, 0, 5, 0],
                    filename: `Cotizacion_${newCotizacion.cotizacionNumber}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { 
                        scale: 2,
                        useCORS: true,
                        logging: true,
                        allowTaint:true
                    },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                })
                .from(iframe.contentDocument.body)
                .save();

            // Clean up
            root.unmount(); // Properly cleanup React
            document.body.removeChild(iframe);
            console.log("PDF created successfully");

        } catch (error) {
            console.error('Error creating PDF:', error);
            if (root) root.unmount();
            document.body.removeChild(iframe);
        }
    };

    const onClickAdjuntarPDF = async () => {        
        const cotizacionNumber = newCotizacion?.cotizacionNumber || "";
        const firstname = newCotizacion?.paciente?.firstname || "";
        const lastname = newCotizacion?.paciente?.lastname || "";
        const nss = newCotizacion?.paciente?.nss || "";        
        const filename = `Cotizacion_${cotizacionNumber}___NSS_${nss}.pdf`;
        
        console.log('Starting PDF attachment process - Current state:', { isAttaching, attachStatus });
        
        try {
            await handleGeneratePdf(newCotizacion, filename);
            console.log('PDF attachment completed - Current state:', { isAttaching, attachStatus });  
            setTimeout(() => {
                setOpenAttachmentModal(false);
            }, 2000);
        } catch (error) {
            console.error('Error attaching PDF:', error);
        }
    }



    //we are keeping the Cread PDF button and logic for now, but we are not using it in the UI

    return (
        <>
        <FormWrapper>
            <FormTitle>Cotizacion Creada</FormTitle>
            <FormBody>
                <InfoAdicionalRow>
                    <TotalLabel>Cliente:</TotalLabel>
                    <Value>{newCotizacion?.client?.name}</Value>
                    
                </InfoAdicionalRow>
                <InfoAdicionalRow>
                    <TotalLabel>Numero cotizacion: </TotalLabel>
                    <Value>{newCotizacion?.cotizacionNumber}</Value>
                </InfoAdicionalRow>
                                
                {newCotizacion.client && <Button success onClick={onClickVerCotizacion}>Ver cotizacion</Button>}
                {false && <Button success onClick={onClickCrearPDF}>Crear PDF</Button>}
                {typeof handleGeneratePdf === 'function' && (
                    <Button 
                        success 
                        loading={isAttaching}
                        successEvent={attachStatus === 'success'} 
                        onClick={onClickAdjuntarPDF}
                    >
                        Adjuntar PDF
                    </Button>

                  
                )}
            </FormBody>
            
        </FormWrapper>
         <Modal
         openModal={openModal}
         setOpenModal={setOpenModal} 
         inProp={inProp}
         setInProp={setInProp}
         close={close}
         smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
           
           
     >            
         {formType === "showCotizacion" && <Cotizacion cotizacion={newCotizacion} test={'test'}/>}                 
     </Modal> 
    </>
        
    )
}       


const TotalLabel = styled.span`
    color: ${props => props.bold ? props.theme.palette.grey[800] : props.theme.palette.grey[700]};   
    font-weight: ${props => props.bold ? "800" : "400"};
    font-size: 0.8rem;
`


const InfoAdicionalRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
`


const Value = styled.span`
    font-size: 0.8rem;    
    
    color: ${props => props.bold ? props.theme.palette.grey[800] : props.theme.palette.grey[700]};   
    font-weight: ${props => props.bold ? "800" : "400"};
    flex:2;
`



export default PostCrear;