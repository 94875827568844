import styled from '@emotion/styled';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { CardBody, CardTitle, CardWrapper } from "../../Atoms/Card"
import { round } from '../CotizacionesHelpers';



const ProductosTableCotizacion = ({
    productos,
    setFormType,
    setOpenModal,
    setProductoId,
    setSelectedProductoEdit,
    viewOnly    
}) => {
    console.log(productos)
    //check if phone
    const isPhone = useMediaQuery('phone');

    const onClickTableRow = (producto) => {
        if(viewOnly) return;
        setFormType("editProducto")
        setOpenModal(true);
        setProductoId(producto._id)
        setSelectedProductoEdit(producto)
        console.log(producto)
    }


    if(isPhone){

        return(
            <CardWrapper style={{margin:0, padding:'8px'}}>
                <CardTitle>Productos</CardTitle>
                <ProductoWrapper>
                {productos.map((producto,index) => (
                    <Producto key={`${producto?._id} ${index}`} onClick={()=>onClickTableRow(producto)}>
                        <Codigo>{producto?.codigo}</Codigo>
                        <Description>{producto?.descripcion}</Description>
                        <Item>{`Cantidad: ${producto?.quantity || 0}`}</Item>
                        <Item style={{fontWeight:700}}>{`Precio: ${round(producto?.clientPrice)}`}</Item>
                        {producto?.discount > 0 && <Item>{`Descuento: ${round(producto?.discount)}`}</Item>}
                        <Item>{`Subtotal: ${round(producto?.subtotal)}`}</Item>
                        <Item>{`Itbis: ${producto?.itbis ? round(producto.itbis) : "0.00"}`}</Item>
                        <Item style={{fontWeight:700}}>{`Total: ${round(producto?.total)}`}</Item>
                    </Producto>
                ))}
                </ProductoWrapper>
            </CardWrapper>
        )
    }

    return(

        <CardWrapper >
                    <CardTitle>Productos</CardTitle>
                    <CardBody style={{overflowY:'auto'}}>

                    
                    <Table>
                    <TableHead>
                        <TableHeadRow>
                            <TableHeadCell>Codigo</TableHeadCell>
                            <TableHeadCell>Descripcion</TableHeadCell>
                            <TableHeadCell align='right'>Cantidad</TableHeadCell>
                            <TableHeadCell align='right'>Precio</TableHeadCell>
                            <TableHeadCell align='right'>Descuento</TableHeadCell>
                            <TableHeadCell align='right'>Subtotal</TableHeadCell>   
                            <TableHeadCell align='right'>ITBIS</TableHeadCell>
                            <TableHeadCell align='right'>Total</TableHeadCell>                                                                                 
                        </TableHeadRow>
                    </TableHead>
                    
                
                <TableBody>                                                                                                
                    {productos.map((producto,index) => (
                         <TableRow key={`${producto?._id} ${index}`} onClick={()=>onClickTableRow(producto)}>
                            <TableCell >{producto?.codigo}</TableCell>
                            <TableCell >{producto?.descripcion}</TableCell>
                            <TableCell align='right'>{producto?.quantity || 0}</TableCell>
                            <TableCell align='right'>{round(producto?.clientPrice)}</TableCell>
                            <TableCell align='right'>{round(producto?.discount)}</TableCell>
                            <TableCell align='right'>{round(producto?.subtotal)}</TableCell>
                            <TableCell align='right'>{producto?.itbis ? round(producto.itbis) : "0.00"}</TableCell>
                            <TableCell align='right'>{round(producto?.total)}</TableCell>                         
                     </TableRow>
                    ))}
                 </TableBody>  
            </Table>  
            </CardBody>
        </CardWrapper>


    )

}


const Table = styled.table`
    border-collapse: collapse;
    width:100%;
    padding: 16px;
`

const TableHead = styled.thead`
`

const TableHeadRow = styled.tr`
    border-top: 1px solid ${p=>p.theme.palette.grey[400]};
    background-color: ${p=>p.theme.palette.grey[100]};   
`
const TableHeadCell = styled.th`
    padding: 8px;
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    font-size: 0.75rem;
    text-align: left;
    white-space: wrap;
    text-align: ${p=>p.align};
    
`

const TableBody = styled.tbody`
`

const TableRow = styled.tr`
    
    &:hover{
        background-color: ${p=>p.theme.palette.grey[100]};
        cursor: pointer;
        
    }


    
`

const TableCell = styled.td`
    border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    padding: 8px;
    font-size: 0.75rem;
    text-align: ${p=>p.align};
`

const ProductoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    
  `  



const Description = styled.p`
    font-size: 0.85rem;
    
    margin: 0;
    font-weight: 700;
    margin-bottom: 4px;
    
`
const Item = styled.p`
    font-size: 0.75rem;
    margin: 0;
`
//border bottom except the last one and top border on first one
const Producto = styled.div`

    &:nth-of-type(1){
        border-top: 1px solid ${p=>p.theme.palette.grey[400]};
        padding-top: 12px;
    }
    &:not(:last-of-type){
        border-bottom: 1px solid ${p=>p.theme.palette.grey[400]};
    }    
    padding-bottom: 12px;

`

const Codigo = styled.p`
    font-size: 0.75rem;
    margin: 0;
    font-weight: 700;
    margin-bottom: 4px;
`


export default ProductosTableCotizacion