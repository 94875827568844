//create a component that will be used to chat with the ai
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { TextField, useTheme } from '@mui/material';
import Button from '../Atoms/Button';
import Send from '../Icons/Send';
import X from '../Icons/X';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import useCirugia from '../../hooks/useCirugia';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { addOneCirugia } from '../../app/cirugiaReducer';
import { addUpdatedCirugia } from '../../app/cirugiasReducer';




const AiChat = ({ cirugiaId, close }) => {
  const user = useCurrentUser();
  const theme = useTheme()  
  const [messages, setMessages] = useState([
    { role: 'assistant', content: `Hola ${user.firstname}, soy tu asistente virtual. ¿En qué puedo ayudarte con esta cirugía?` }
  ]);
  console.log(messages)
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const isTablet = useMediaQuery('tablet');
  const dispatch = useDispatch();
  const params = useParams();
  const inputRef = useRef(null);


  const cirugiaInfo = useCirugia(cirugiaId);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

 
  const updateCirugia = (response)=>{
  
    console.log(response._id)
    console.log(params.cirugiaId)
    if(response._id === params.cirugiaId){
        console.log('in cirugia page')
        dispatch(addOneCirugia(response))
        return
    }else{
        dispatch(addUpdatedCirugia(response));
        return
    }
  }




  const systemPrompt = `
      You are an AI assistant for Hospal Medica, a medical device distributor. 
      Your task is to provide information only about the following surgery (cirugia). 
      Be concise, do not just summarize all the information. Wait for specific questions. 
      Make sure to answer in Spanish in a friendly and professional way.

      The user of the chat is ${user.firstname} ${user.lastname} with id ${user._id}. 
      Use the user's name in your responses as appropriate.

      IMPORTANT TIMEZONE INSTRUCTIONS:
      - The Dominican Republic uses the America/Santo_Domingo timezone, which is 4 hours behind GMT.
      - Todays date in utc is: ${new Date().toLocaleDateString('es-DO', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })} a las ${new Date().toLocaleTimeString('es-DO', { timeZone: 'UTC', hour: 'numeric', minute: 'numeric', hour12: true })}.
     
      - Even if the user is in a different timezone (e.g., Florida), 
        **all references to dates or times must use America/Santo_Domingo**.
      - Do NOT use the current computer time to determine the date or time of the surgery; 
        rely only on America/Santo_Domingo time and any data provided in the surgery info object.

      SCHEDULING SURGERIES (agendar cirugias):
      - Times should always be in America/Santo_Domingo, in 12-hour format with "am" or "pm".
      - When calling the "agendar_cirugia" function, the "fecha" parameter must include 
        both date and time in ISO format with the time portion indicating America/Santo_Domingo, 
        e.g., "2024-03-21T14:00:00".

      -the surgery date is can be found in the Info Object of the cirugia if provided. Ignore comments about the surgery date, the only oficial information about the current time and
      date is in the Info Object of the cirugia.
      When providing the current date for the surgery, access it from the Info Object of the cirugia if provided.

      Remember:
      - Only provide details about the specified surgery.
      - Be concise, wait for specific questions.
      - Respond only in Spanish.
      - Use the user's name appropriately.
      - All times/dates are in the America/Santo_Domingo timezone.

`;
  
  
  const handleSend = async () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInput('');
      setIsLoading(true);
      
      const updatedMessages = [...messages, userMessage];
      
      const options = createOptions('POST', {
        cirugiaId, 
        messages: updatedMessages,
        systemPrompt: systemPrompt,
        cirugiaInfo: JSON.stringify(cirugiaInfo)
      });
      
      try {
        const response = await goFetch(apiUrl+ "ai/chatBot", options );

        if(response.updatedCirugia){
          updateCirugia(response.updatedCirugia);
        }
        
        const aiMessage = { role: 'assistant', content: response.message};
        setMessages(prevMessages => [...prevMessages, aiMessage]);
      } catch (error) {
        console.error("Error fetching AI response:", error);
        const errorMessage = { role: 'assistant', content: "Lo siento, hubo un error al procesar tu solicitud. Por favor, intenta de nuevo."};
        setMessages(prevMessages => [...prevMessages, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ChatContainer>
      {isTablet && (
        <CloseButton onClick={close}>
          <X color={theme.palette.error.light} />
        </CloseButton>
      )}
      <MessagesContainer>
        {messages.map((message, index) => (
          <Message key={index} isUser={message.role === 'user'}>
            {message.content}
          </Message>
        ))}
        {isLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <StyledTextField
          inputRef={inputRef}
          fullWidth
          variant="outlined"
          placeholder="Pregunta al IA sobre esta cirugia"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          disabled={isLoading}
        />
        <SendButton success outline onClick={handleSend} disabled={isLoading}>
          <Send />
        </SendButton>
      </InputContainer>
    </ChatContainer>
  );
};

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  min-height: 380px;
  min-width: 380px;
  max-width: 600px;
  position: relative;
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 90%;
  font-size: 14px;
  line-height: 1.4;
  ${props => props.isUser ? `
    align-self: flex-end;
    background-color: #1a73e8;
    color: white;
    border-bottom-right-radius: 4px;
  ` : `
    align-self: flex-start;
    background-color: #f1f3f4;
    color: #202124;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  `}
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  padding: 8px 16px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-input{
    padding: 8px;
  }
`;

const SendButton = styled(Button)`
  min-width: 36px;
  padding: 6px;
  margin-left: 8px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const LoadingContainer = styled.div`
 margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 70%;
  font-size: 14px;
  line-height: 1.4;
  align-self: flex-start;
    background-color: white;
    color: #202124;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default AiChat;
