import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import styled from '@emotion/styled';
import React from "react";
import ChevronDown from "../../Icons/ChevronDown";
import Button from "../../Atoms/Button";




const EditarDropDown = ({
    setOpenModal,
    setFormType,
    cotizacionId,
    setCotizacionId,
    canEditarProductosAndVentasCotizaciones,
    cotizacion
  })=>{
    let theme = useTheme();
    
    
    const onSelect = (formType)=>{
      setOpenModal(true);
      setFormType(formType);
      setCotizacionId(cotizacionId);
      setAnchorEl(null);
    }
  
    const [anchorEl, setAnchorEl] = React.useState(null)
      const open = Boolean(anchorEl);
  
     const onClickHandler = (event)=>{
         setAnchorEl(event.currentTarget)
     }
  
     const onCloseHandler = ()=>{
         setAnchorEl(null)
     }
  
    return (
        
                    <>
                    <Button
                      outline
                      postIcon={<ChevronDown size={20} color={theme.palette.grey[600]}/>}
                      onClick={onClickHandler}
                    >
                    <StyledSpan>Editar</StyledSpan>
                    
                    </Button>
                    <Menu
                      open={open}
                      anchorEl={anchorEl}
                      onClose={onCloseHandler}
                    >   
                        <MenuItem onClick={()=>onSelect("modifyPaciente")}>Paciente</MenuItem>                     
                        <MenuItem onClick={()=>onSelect("modifyDoctor")}>Doctor</MenuItem>
                        <MenuItem onClick={()=>onSelect("modifyCentro")}>Centro</MenuItem>                     
                        {canEditarProductosAndVentasCotizaciones && <MenuItem onClick={()=>onSelect("modifyProductos")}>Productos</MenuItem>}
                        {canEditarProductosAndVentasCotizaciones && <MenuItem onClick={()=>onSelect("modifyVentas")}>Ventas</MenuItem>   }
                        <MenuItem onClick={()=>onSelect("modifyEspecialidad")}>Especialidad</MenuItem>   
                        {cotizacion?.client?.tipo === "Ars" && <MenuItem onClick={()=>onSelect("modifyDiferencia")}>Diferencia Paciente</MenuItem>}                     
                    </Menu>
                </>
  
    )
  }
  
  export const StyledSpan = styled.span`
  font-weight: 600;
  color: var(--button-color);
  font-size: .875rem;
  line-height: 1.75;
  `

  export default EditarDropDown