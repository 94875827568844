import io from 'socket.io-client';
import { socketUrl } from './url';

const user = JSON.parse(localStorage.getItem('user'));
//let socket = io(socketUrl);

let socket;
if(user?.company?._id) {
    console.log('Initializing socket connection for company:', user.company._id);

    // Close existing socket if it exists
    if(socket) {
        console.log('Closing existing socket connection');
        socket.close();
        socket.removeAllListeners();
    }

    // Create new socket connection
    socket = io(socketUrl + '/' + user.company._id, {
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
        pingTimeout: 30000,
        pingInterval: 10000,
        transports: ['websocket', 'polling'], // Try WebSocket first, fallback to polling
        forceNew: true, // Add this to ensure a clean connection
        auth: { // Add authentication data
            tenantId: user.company._id
        }
    });

    // Connection event handlers
    socket.on('connect', () => {
        console.log('Socket connected to namespace:', {
            namespace: user.company._id,
            socketId: socket.id,
            readyState: socket.connected ? 'connected' : 'disconnected'
        });
        
        // Immediately send tenant ID after connection
        socket.emit('clientTenantId', user.company._id);
    });

    socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        // Don't close the socket here, let it try to reconnect
    });

    socket.on('disconnect', (reason) => {  
        console.log('Socket disconnected:', reason);
        if (reason === 'io server disconnect') {
            // Server disconnected us, try to reconnect
            console.log('Server disconnected socket, attempting to reconnect');
            socket.connect();
        }
    });

    socket.on('messageFromServer', (dataFromServer) => {
        try {
            console.log('Received message from server:', dataFromServer);
            socket.emit('messageToServer', {data: "This is from the client"});
        } catch (error) {
            console.error('Error sending message to server:', error);
        }
    });

    socket.on('sendTenantIdToServer', (data) => {
        try {
            console.log('Server requested tenant ID, sending:', user.company._id);
            socket.emit('clientTenantId', user.company._id);
        } catch (error) {
            console.error('Error sending tenant ID:', error);
        }
    });

    // Add reconnection logging
    socket.on('reconnect', (attemptNumber) => {
        console.log('Socket reconnected after attempt:', attemptNumber);
        socket.emit('clientTenantId', user.company._id);
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
        console.log('Socket reconnection attempt:', attemptNumber);
    });

    // Force an immediate connection attempt
    socket.connect();
}

//console.log(socket)
export default socket;
