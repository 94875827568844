import React from 'react';
import styled from '@emotion/styled';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useModalAndDrawer from '../../../hooks/useModalAndDrawer';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import IconButton from '../../Atoms/IconButton';
import Modal from '../../Atoms/Modal';
import EditProductoCotizacion from '../EditProductoCotizacion';
import ProductoInput from './ProductoInput';
import ProductosTableCotizacion from './ProductosTableCotizacion';
import Plus from '../../Icons/Plus';
import { useTheme } from '@mui/system';
import TotalsCard from './TotalsCard';
import { createSelectedProductObject } from '../CotizacionesHelpers';







const ModifyProductosCotizacion = ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    console.log(cotizacion)
    const isTablet = useMediaQuery('tablet');    
    const theme = useTheme();    
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);        
    const accionMaker = useAccionMaker();
    const {openModal, setOpenModal, inProp, setInProp, close:closeSecond} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();
    const smallBottomSwipeDrawers = [ "editProducto"]
    const drawerOffsetPercentInputArray = ["addProducto",]
    const [productoId, setProductoId] = React.useState(null);
    const [selectedProductoEdit, setSelectedProductoEdit] = React.useState(null);
    const [productos, setProductos] = React.useState(cotizacion.productos[cotizacion.productos.length-1]);
    const [totalCotizacion, setTotalCotizacion] = React.useState(cotizacion.totalCotizacion[cotizacion.totalCotizacion.length-1]);
    
    console.log(cotizacion)

    
    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion
        
        accion = accionMaker(`Productos modificados`);
             
        let infoProductos = {
            productos: productos,
            totalCotizacion: totalCotizacion,
            dateModified: new Date(),            
            cotizacionId: cotizacion._id,            
            accion:accion
          }          
    
          console.log(infoProductos)
          updateCotizacion('update-productos',infoProductos)                                                
    }

    const handleAddProducto = (formType) => {
        setFormType(formType)
        setOpenModal(true)  
    }


    return(
        <>
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet}>
                <TitleWrapper>
                    Modificar Productos
                    
                <StyledIconButton type="button" onClick={()=>handleAddProducto('addProducto')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>
                </TitleWrapper>
                
            </FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

               <ProductosTableCotizacion 
                    productos={productos}
                    setFormType={setFormType}
                    setOpenModal={setOpenModal}
                    setProductoId={setProductoId}
                    setSelectedProductoEdit={setSelectedProductoEdit}                       
                /> 
                <TotalsCard totalCotizacion={totalCotizacion}/>         
                
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
        <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={closeSecond}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
                drawerOffsetPercentInput={drawerOffsetPercentInputArray.includes(formType) && "12%"}      
                  
            >
                {formType === "editProducto" && <EditProductoCotizacion  
                                                    close={closeSecond}
                                                    productoId={productoId} 
                                                    selectedProductoEdit={selectedProductoEdit} 
                                                    selectedProductos={productos} 
                                                    setSelectedProductos={setProductos}
                                                    setTotalCotizacion={setTotalCotizacion} 
                                                    currency={cotizacion.currency}
                                                />}            
                
                {formType === "addProducto" && <ProductoInput 
                                                    setProductos={setProductos} 
                                                    createSelectedProductObject={createSelectedProductObject}
                                                    selectedClient={cotizacion.client}
                                                    close={closeSecond}
                                                    currency={cotizacion.currency}
                                                    setTotalCotizacion={setTotalCotizacion}
                                                    productos={productos}
                                                    />}
            </Modal> 
        </>
    )
}


const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`

export default ModifyProductosCotizacion;


