import Chip from "../../../Atoms/Chip"
import { StepButtonWrapper, StepHeading, StepWrapper } from "./StepStyles"


const ProductGroupsSelection = ({
    especialidades,
    selectedEspecialidad,
    onClickEspecialidadChip
}) => {


return (        
        <StepWrapper>
            <StepHeading>Seleccionar grupo de productos</StepHeading>
            <StepButtonWrapper>
            {
                especialidades.map(especialidad => (   
                    <Chip
                        key={especialidad.id} 
                        primary={especialidad === selectedEspecialidad}
                        outline={especialidad !== selectedEspecialidad}
                        label={especialidad.name} 
                        onClick={()=>onClickEspecialidadChip(especialidad)}
                    />
                    
                ))
                }
            </StepButtonWrapper>
        </StepWrapper   >
    )
}

export default ProductGroupsSelection