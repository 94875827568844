import styled from "@emotion/styled";
import { Menu, MenuItem, useTheme} from "@mui/material";
import React from "react";
import Button from "../../Atoms/Button";
import Send from "../../Icons/Send";


const ReplyDropDown = ({
    onReplyChange,
    message,
    isLoading
}) => {  
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleSelect = (e, replyStatus) => {
        e.stopPropagation();
        onReplyChange(e, replyStatus);
        handleClose();
    };

    return (
        <div>
            <Button 
                preIcon={<Send color={!message.trim() ? theme.palette.grey[600] : 'white'} size={16} />}
                success     
                onClick={handleClick}
                disabled={!message.trim() || isLoading}
                loading={isLoading}
            >
                {isLoading ? 'Enviando...' : 'Enviar'}
            </Button>
            <StyledMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => handleSelect(e, 'Cerrado')}>
                    <MenuItemWrapper>
                        <Text>Enviar y cerrar</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={(e) => handleSelect(e, 'Trabajando')}>
                    <MenuItemWrapper>
                        <Text>Enviar y trabajando</Text>
                    </MenuItemWrapper>
                </MenuItem>                            
            </StyledMenu>
        </div>
    );
};


const StyledMenu = styled(Menu)`
    .MuiPaper-root {
        min-width: 200px;
    }
`;

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p => p.theme.palette.grey[700]};
`;



export default ReplyDropDown;