import React from 'react';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import DoctorsAutocomplete from '../../Forms/CustomAutocomplete/DoctorsAutocomplete';
import EspecialidadSelect from '../../Forms/CustomAutocomplete/EspecialidadSelect';


const ModifyEspecialidadCotizacion = ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    
    const isTablet = useMediaQuery('tablet');    
    const [especialidad, setEspecialidad] = React.useState(cotizacion?.especialidad || "");        
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);
    const accionMaker = useAccionMaker();
      console.log(cotizacion)    
    const onSubmit = async (event) =>{
        event.preventDefault();

        
        const accion = accionMaker(`Especialidad modificada de ${cotizacion?.especialidad} a ${especialidad}`);        
                
        let infoEspecialidad = {            
            especialidad: especialidad,            
            cotizacionId: cotizacion._id,            
            accion: accion
          }

          updateCotizacion('update-especialidad',infoEspecialidad)
            
        }        
        
       const onChangeHandler = (event)=>{        
        setEspecialidad(event.target.value)
    }

    return(
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet} >Modificar Especialidad</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

            <EspecialidadSelect
                label="Especialidad"
                id="especialidad"
                name="especialidad"
                value={especialidad}
                onChange={onChangeHandler}    
            /> 
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyEspecialidadCotizacion