import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faBed, faCalendarCheck, faDolly, faEdit, faFileInvoice, faHospital, faIdCard, faMoneyBillWave, faUser, faUserMd} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import useModalAndDrawer from '../../hooks/useModalAndDrawer';
import formatDate from '../../shared/dateFormatter';
import { apiUrl } from '../../shared/url';
import Button from '../Atoms/Button';
import { CardActions, CardBody, CardHeader, CardWrapper } from "../Atoms/Card"
import CardInfo from '../Atoms/CardInfo';
import Chip from '../Atoms/Chip';
import IconButton from '../Atoms/IconButton';
import Modal from '../Atoms/Modal';
import Plus from '../Icons/Plus';
import Cotizacion from './Cotizacion';
import CrearCotizaciones from './CrearCotizaciones';
import CotizacionesSearchBar from './molecules/CotizacionesSearchBar';
import { CirugiaCardSkeleton } from '../cirugias/Molecules/CirugiasCardLoading';
import EditarDropDown from './molecules/EditarDropDown';
import ModifyDoctorCotizacion from './molecules/ModifyDoctorCotizacion';
import ModifyCentroCotizacion from './molecules/ModifyCentroCotizacion';
import ModifyVentasCotizacion from './molecules/ModifyVentasCotizacion';
import ModifyProductosCotizacion from './molecules/ModifyProductosCotizacion';
import VerProductosCotizacion from './molecules/VerProductosCotizacion';
import ModifyStatus from './molecules/ModifyStatus';
import ModifyPacienteCotizacion from './molecules/ModifyPacienteCotizacion';
import CrearPacienteCotizacion from './molecules/CrearPacienteCotizacion';
import CrearCirugiaCotizacion from './molecules/CrearCirugiaCotizacion';
import ModifyEspecialidadCotizacion from './molecules/ModifyEspecialidadCotizacion';
import ProductosAprobados from './molecules/ProductosAprobados';
import usePermissions from '../../hooks/usePermissions';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import useDateAndTimeFormatter from '../../hooks/useDateAndTimeFormatter';
import ModifyDiferencia from './molecules/ModifyDiferencia';
import Layers from '../Icons/Layers';
import HistoriaCotizacion from './molecules/HistoriaCotizacion';
import FilterIcon from '../Icons/FilterIcon';
import CotizacionesFiltro from './molecules/CotizacionesFiltro'
import SelectedFilterCotizacionesChips from './molecules/SelectedFiltersCotizacionesChips';
import BarChart from '../Icons/BarChart';
import ReporteCotizaciones from './molecules/ReporteCotizaciones';
import { round } from './CotizacionesHelpers';



const CotizacionSummaryCard = ({
    cotizacion,
    setFormType,
    setOpenModal,
    setCotizacionId,
    cotizaciones,
    setCotizaciones,
    canEditarCotizaciones,
    canEditarProductosAndVentasCotizaciones    

}) => {

    const theme = useTheme();
    const isCotizacionAprobada = cotizacion?.status === 'Aprobada'
    const isCirugiaCreada = cotizacion?.cirugia?._id
    const isPacienteCreado = cotizacion?.paciente?._id ? true : false
    const location = useLocation();
    const {formatTime} = useDateAndTimeFormatter()

    const handleOnClick = (formType)=>{
        setOpenModal(true);
        setCotizacionId(cotizacion._id)
        setFormType(formType)
    }
    let colorObj

    if(cotizacion?.status === 'Creada'){
        colorObj = {warning: true}
    }else if(cotizacion?.status === 'Aprobada'){
        colorObj = {success: true}
    }else if(cotizacion?.status === 'Rechazada'){
        colorObj = {error: true}
    }else if(cotizacion?.status === 'Cancelada'){
        colorObj = {error: true}    
    }

    console.log(cotizacion)
    return(
        <StyledCardWrapper colorObj={colorObj}>
            <CardHeader>
            <HeaderContainer>
                <PacienteContainer>
                    <ClientName>{cotizacion?.client?.name}</ClientName>                     
                    <Subtitle>{`Cotizacion: ${cotizacion?.cotizacionNumber}`}</Subtitle>
                    <Subtitle>{`Creada: ${formatDate(cotizacion?.createdAt)}, ${formatTime(cotizacion?.createdAt)}`}</Subtitle>
                </PacienteContainer>
                <ChipWrapper> 
                    <Chip label={`${cotizacion?.especialidad || "Pendiente"}`} primary/>
                    <Chip label={`${cotizacion?.status}`} {...colorObj}/>
                    
                </ChipWrapper>
                
            </HeaderContainer>
                             
            </CardHeader>
            <CardBody>
            <Container>                            
                <Wrapper>                                        
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faHospital} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{cotizacion?.centro?.nombre}</Text>
                    </InfoWrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faIdCard} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{}</Text>
                    </InfoWrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faMoneyBillWave} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{round(cotizacion?.totalCotizacion[cotizacion.totalCotizacion.length - 1].totalGeneral)}</Text>
                    </InfoWrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faUserMd} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{`${cotizacion?.doctor?.firstname || ""} ${cotizacion?.doctor?.lastname || ""}`}</Text>
                    </InfoWrapper>
                    
                </Wrapper>
                <Wrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faBed} size="1x" color={theme.palette.grey[700]}/></IconWrapper>
                        <Text>{`${cotizacion?.paciente?.firstname || ""} ${cotizacion?.paciente?.lastname || ""}`}</Text>
                    </InfoWrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faUser} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{`${cotizacion?.ventas?.firstname || ""} ${cotizacion?.ventas?.lastname || ""}`}</Text>
                    </InfoWrapper>
                    <InfoWrapper>
                        <IconWrapper><FontAwesomeIcon icon={faEdit} size="1x" color= {theme.palette.grey[700]}/></IconWrapper>
                        <Text>{`${cotizacion?.creadoPor?.firstname || ""} ${cotizacion?.creadoPor?.lastname || ""}`}</Text>
                    </InfoWrapper>                                            
                </Wrapper>
            </Container>
                
            </CardBody>
            
            <CardActions>
                <ActionWrapper>
                    <IconButton onClick={()=>handleOnClick("verCotizacion")}>
                        <FontAwesomeIcon icon={faFileInvoice} style={{fontSize: "1.25rem"}} color= {theme.palette.grey[700]}/>                                                            
                    </IconButton>
                    {isCirugiaCreada && 
                        <IconButton 
                            to={`/app/cirugias/${cotizacion?.cirugia?._id}`}
                            state ={{path:location.pathname}}                        
                        >
                            <FontAwesomeIcon icon={faCalendarCheck} style={{fontSize: "1.25rem"}} color= {cotizacion.cirugia.realizada ? theme.palette.success.light : (cotizacion.cirugia.cancelada ? theme.palette.error.light : theme.palette.grey[700])}/>
                        </IconButton>
                    }
                    <IconButton onClick={()=>handleOnClick("verProductos")}>
                        <FontAwesomeIcon icon={faDolly} style={{fontSize: "1.25rem"}} color= {theme.palette.grey[700]}/>                                                            
                    </IconButton>
                    <IconButton onClick={()=>handleOnClick("verHistoria")}>
                        <Layers size={'20'}color={theme.palette.grey[700]}/>
                    </IconButton>
                    
                    {!isCirugiaCreada && <Button success onClick={()=>handleOnClick('modifyStatus')}>{"Status"}</Button>}
                    {!isPacienteCreado && isCotizacionAprobada && <Button success onClick={()=>handleOnClick('crearPaciente')}>{"Crear Paciente"}</Button>}
                    {isCotizacionAprobada && !isCirugiaCreada && <Button outline onClick={()=>handleOnClick('productosAprobados')}>{"Productos aprobados"}</Button>}
                    {!isCirugiaCreada && isCotizacionAprobada && <Button success onClick={()=>handleOnClick('crearCirugia')}>{"Crear Cirugia"}</Button>}
                  {canEditarCotizaciones && <EditarDropDown
                                                setOpenModal ={setOpenModal}
                                                setFormType ={setFormType}
                                                cotizacionId ={cotizacion._id}
                                                setCotizacionId = {setCotizacionId}
                                                canEditarProductosAndVentasCotizaciones = {canEditarProductosAndVentasCotizaciones}
                                                cotizacion = {cotizacion}
                                            />
                    }

                </ActionWrapper>
                
            </CardActions>
            

        </StyledCardWrapper>
    )


}


const Cotizaciones = () => {
    const theme = useTheme();
    const user = useCurrentUser()
    const [cotizaciones, setCotizaciones] = React.useState([]);
    const navigate = useNavigate();
    const {openModal, setOpenModal, inProp, setInProp, close} = useModalAndDrawer();
    const [formType, setFormType] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const smallBottomSwipeDrawers = ['modifyDoctor', 'verHistoria']
    const [cotizacionId, setCotizacionId] = React.useState();
    const [isNewCotizacion, setIsNewCotizacion] = React.useState(false);
    const [nextCursor, setNextCursor] = React.useState(null);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [filtro, setFiltro] = React.useState({})
    //difference between filtro and filtroValues is that filtro only has the id of the selected value, while filtroValues has the whole object
    const [selectedFiltroValues, setSelectedFiltroValues] = React.useState({})
    const [cotizacionesCount, setCotizacionesCount] = React.useState(0)
    
    const permissions = usePermissions()
    const {
        canCrearCotizaciones,        
        canEditarCotizaciones,
        canEditarProductosAndVentasCotizaciones,
        canVerTodasCotizaciones
    }=permissions


    const restricted = !canVerTodasCotizaciones
    
    console.log(restricted)
    let ventasId
    if(restricted){
        ventasId = user._id
    }else{
        ventasId = "null"
    }

    //const response = await goFetch(apiUrl + `cotizaciones/fetch-cotizaciones/${restricted}/${ventasId}`,options)


    const fetchCotizaciones= useCallback(async(nextCursor, filtro)=>{
        nextCursor ? setIsLoadingMore(true): setIsLoading(true)

        const filterParams = {
            restricted,
            ventasId,
            ...filtro
        }

        const options = createOptions('POST',{filterParams, nextCursor})
        
        
        try {
            

            const response = await goFetch(`${apiUrl}cotizaciones/fetch-cotizaciones`,options)
            console.log(response)
            if(nextCursor){
                setCotizaciones(prevState=>[...prevState, ...response.cotizaciones])
                setNextCursor(response.nextCursor)
                setIsLoadingMore(false)
                setCotizacionesCount(response.count)
            }else{
                setCotizaciones(response.cotizaciones)
                setNextCursor(response.nextCursor)
                setIsLoading(false)
                setCotizacionesCount(response.count)
            }
        } catch (error) {
            console.log(error)
            if(nextCursor){
                setIsLoadingMore(false)
            }else{
                setIsLoading(false)
            } 
        }                    
    },[restricted,ventasId])
   
    


    React.useEffect(()=>{
        
        fetchCotizaciones(undefined, filtro)
        if(isNewCotizacion){
            setIsNewCotizacion(false)
        }

    },[isNewCotizacion,restricted,ventasId, filtro])


     
    const handleClick = (formType)=>{
        setOpenModal(true);
        setFormType(formType)
    }

    const fetchMore = ()=>{
        setIsLoadingMore(true)
        fetchCotizaciones(nextCursor, filtro)
        setIsLoadingMore(false)
    }

    const onChipClick =(key)=>{
        setSelectedFiltroValues({key:""})
        setFiltro({key:""})
        
    }

    console.log(cotizacionesCount)

    return(
        <>
            <Header>
                <SearchBarWrapper>
                    <CotizacionesSearchBar setCotizaciones={setCotizaciones} setIsLoading={setIsLoading}/>
                    {canCrearCotizaciones && <StyledIconButton type="button" onClick={()=>handleClick('crearCotizacion')}><Plus color={theme.palette.grey[700]}/></StyledIconButton>}
                    {<StyledIconButton type="button" onClick={()=>handleClick('filter')}><FilterIcon color={theme.palette.grey[700]} size="20"/></StyledIconButton>}
                    {<StyledIconButton type="button" onClick={()=>handleClick('reporteCotizaciones')}><BarChart color={theme.palette.grey[700]} size="20"/></StyledIconButton>}
                </SearchBarWrapper>

                <SelectedFilterCotizacionesChips
                    selectedFiltroValues={selectedFiltroValues}
                    setSelectedFiltroValues={setSelectedFiltroValues}
                    setFiltro={setFiltro}
                />
               
            </Header>
            <br/>
            <ExternalWrapper>
            {cotizaciones?.length === 0 && !isLoading && <CardInfo>No se encontraron cotizaciones</CardInfo>}   
            <CotizacionesWrapper>
                {cotizaciones?.length === 0 && isLoading && [1,2,3,4,5,6].map((index)=>(<CirugiaCardSkeleton key={`${index}`}/>))} 

                {cotizaciones?.length > 0 && cotizaciones?.map((cotizacion)=>{                
                    return(                                    
                        <CotizacionSummaryCard
                            key={cotizacion._id}     
                            cotizacion={cotizacion}                        
                            setCotizacionId={setCotizacionId}
                            close={close}
                            setOpenModal={setOpenModal}
                            setFormType={setFormType}
                            cotizaciones={cotizaciones}
                            setCotizaciones={setCotizaciones}
                            canEditarCotizaciones={canEditarCotizaciones}
                            canEditarProductosAndVentasCotizaciones={canEditarProductosAndVentasCotizaciones}
                        />                                    
                    )})
                }
                 
            </CotizacionesWrapper>
            {nextCursor && <NextCursorButtonWrapper ><Button fullsize={true} primary onClick={fetchMore} loading={isLoadingMore}>Bajar mas Cotizaciones</Button></NextCursorButtonWrapper>}               
            </ExternalWrapper>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal} 
                inProp={inProp}
                setInProp={setInProp}
                close={close}
                smallDrawer = {smallBottomSwipeDrawers.includes(formType)}  
                
                  
            >
                {formType === "verCotizacion" && <Cotizacion cotizacion={cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}/>}
                {formType === "verProductos" && <VerProductosCotizacion 
                                                    productos={cotizaciones?.find(cotizacion=>cotizacion._id === cotizacionId)?.productos}
                                                    setFormType={setFormType}
                                                    setOpenModal={setOpenModal}                                                    
                                                    totalCotizacion={cotizaciones?.find(cotizacion=>cotizacion._id === cotizacionId)?.totalCotizacion}
                                                    dateModified={cotizaciones?.find(cotizacion=>cotizacion._id === cotizacionId)?.dateModified}
                                                    viewOnly={true}
                                                />}
                {formType === "crearCotizacion" && <CrearCotizaciones setIsNewCotizacion={setIsNewCotizacion} />}
                {formType === "modifyProductos" && <ModifyProductosCotizacion
                                                        cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                        setCotizacionId={setCotizacionId}  
                                                        cotizaciones={cotizaciones}
                                                        setCotizaciones={setCotizaciones}                                                      
                                                        close={close}
                                                    />}
                {formType === "status"}            
                {formType === "modifyCentro" && <ModifyCentroCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    setCotizacionId={setCotizacionId}  
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}                                                      
                                                    close={close}
                                                    
                                                />}
                {formType === "modifyDoctor" && <ModifyDoctorCotizacion                                                        
                                                        cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                        setCotizacionId={setCotizacionId}  
                                                        cotizaciones={cotizaciones}
                                                        setCotizaciones={setCotizaciones}                                                      
                                                        close={close}
                                                    />}
                {formType === "modifyVentas" && <ModifyVentasCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    setCotizacionId={setCotizacionId}  
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}                                                      
                                                    close={close}
                                                />}
                {formType === "modifyStatus"  && <ModifyStatus 
                                                    cotizacion={cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    cotizacionId={cotizacionId}
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}
                                                    close={close}
                                                 />}                                                
                {formType === "modifyPaciente" && <ModifyPacienteCotizacion
                                                        cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                        close={close}    
                                                        cotizaciones={cotizaciones}
                                                        setCotizaciones={setCotizaciones}
                                                        cotizacionId={cotizacionId}

                                                    />}
                {formType === "modifyEspecialidad" && <ModifyEspecialidadCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    setCotizacionId={setCotizacionId}  
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}                                                      
                                                    close={close}
                                                />}
                {formType === "crearPaciente" && <CrearPacienteCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}
                                                    close={close}
                                                />}
                                                
                {formType === "crearCirugia" && <CrearCirugiaCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    cotizaciones={cotizaciones}
                                                    setCotizaciones={setCotizaciones}
                                                    close={close}                                                    
                                                    />}
                {formType === "productosAprobados" && <ProductosAprobados
                                                        cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                        cotizaciones={cotizaciones}
                                                        setCotizaciones={setCotizaciones}
                                                        close={close}                                                    
                                                        />}

                {formType === "modifyDiferencia" && <ModifyDiferencia
                                                        cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                        cotizaciones={cotizaciones}
                                                        setCotizaciones={setCotizaciones}
                                                        close={close}
                                                    />}

                {formType === 'verHistoria' && <HistoriaCotizacion
                                                    cotizacion = {cotizaciones.find(cotizacion=>cotizacion._id === cotizacionId)}
                                                    close={close}
                                                />}

                {formType === 'filter' && <CotizacionesFiltro
                                            selectedFiltroValues={selectedFiltroValues}
                                            setSelectedFiltroValues={setSelectedFiltroValues}
                                             setFiltro={setFiltro}   
                                             close={close}
                                            />}
                {formType === 'reporteCotizaciones' && <ReporteCotizaciones
                                            cotizacionesCount={cotizacionesCount}
                                             close={close}
                                            />}


            </Modal> 
            
            </>
        
    )

}

const ExternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: center;
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;    
    gap:8px;
    flex:1;
`


const CotizacionesWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    
    

    @media ${p=>p.theme.media.tablet}{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;    
        max-width: 1250px;
        
    }
`



const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 8px;
    
`
const PacienteContainer=styled.div`
    display:flex;
    flex-direction: column;
    min-width:50%;
`



const ClientName = styled.p`
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[800]}    
`

const Subtitle = styled.p`
    margin:0;
    font-size: 0.7rem;
    font-weight: 500;
    color: ${p=>p.theme.palette.grey[800]};    
`

const ChipWrapper = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;     
    min-width: ${p=>p.isPhone?'50%': 'none'};

`
const Container = styled.div`
    display:flex;
    gap: 8px;
    
`




const CasaComercial = styled.div`
    display:flex;
    flex-direction:column;
    gap: 8px;
    flex:1;
`

const InfoWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`

const IconWrapper = styled.div`
    width: 20px;
    
`

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p=>p.theme.palette.grey[700]};
`

const FacturacionText = styled.p`
    margin: 0;
    font-size: 0.75rem;
    color: ${p=>p.theme.palette.grey[700]};
`


const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
`


const FacturacionWrapper = styled.div`
    display:flex;
    flex-direction: column;
`

const ActionWrapper = styled.div`
    padding: 16px;
    display:flex;
    gap: 8px;
    width:100%;
    justify-content: flex-end;
    align-items:center;
    flex-wrap: wrap;
`
const Header = styled.div`
    display: flex;
    gap:8px;
    justify-content: center;
    flex-direction: column;
`

const SearchBarWrapper = styled.div`
    width:100%;
    display: flex;
    gap:8px;
    justify-content: center;
    align-items:center;    
`

const StyledIconButton = styled(IconButton)`
    border: 1px solid ${p=>p.theme.palette.grey[500]};
    background-color: #ffff;
`

const StyledCardWrapper = styled(CardWrapper)`  
    margin: 0;
    border-radius: 12px;
    
    border: ${p=> {
        if(p.colorObj.success){
            return `4px solid ${p.theme.palette.success.light}`
        }
        else if(p.colorObj.error){
            return `4px solid ${p.theme.palette.error.light}`
        }
        // else if(p.colorObj.warning){
        //     return `2px solid ${p.theme.palette.warning.light}`
        // }
}}
`

const NextCursorButtonWrapper = styled.div`
    width: 100%;
    max-width: 1250px;
    padding: 12px 0px;
`

export default Cotizaciones