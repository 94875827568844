import Chip from "../../Atoms/Chip"
import XCircle from "../../Icons/X-circle"
import styled from '@emotion/styled';



const SelectedFilterCotizacionesChips = ({ 
    selectedFiltroValues,
    setSelectedFiltroValues,
    setFiltro,
    filtro
}) => {

    console.log(selectedFiltroValues)
    const selectedDoctor = selectedFiltroValues?.doctor?._id || ""
    const selectedCentro = selectedFiltroValues?.centro?._id || ""
    const selectedClient = selectedFiltroValues?.client?._id || ""
    //const selectedProducto = selectedFiltroValues?.producto?._id || ""


    const onChipClick =(e,key)=>{
        console.log(key)
        setSelectedFiltroValues({...selectedFiltroValues, [key]:""})
        setFiltro({...filtro, [key]:""})
        
    }

   
    return (
        <Wrapper>
       
        {selectedDoctor &&      <Chip 
                                primary 
                                label={selectedFiltroValues.doctor?.lastname} 
                                postIcon={<XCircle size='16' color="#ffff"/>}
                                onClick={(e)=>onChipClick(e,"doctor")}
                        />
        }

        {selectedCentro &&      <Chip 
                                primary 
                                label={selectedFiltroValues.centro?.nombre} 
                                postIcon={<XCircle size='16' color="#ffff"/>}
                                onClick={(e)=>onChipClick(e,"centro")}
                        />
        }

        {selectedClient &&      <Chip
                                primary
                                label={selectedFiltroValues.client?.name}
                                postIcon={<XCircle size='16' color="#ffff"/>}
                                onClick={(e)=>onChipClick(e,"client")}
                        />
        }
                                    

        {selectedFiltroValues.producto && selectedFiltroValues.producto.length > 0 && 
            selectedFiltroValues.producto.map((producto, index) => (
                <Chip 
                    key={index}
                    primary 
                    label={producto.descripcion}
                    postIcon={<XCircle size='16' color="#ffff"/>}
                    onClick={(e) => {
                        const updatedProductos = [...selectedFiltroValues.producto];
                        updatedProductos.splice(index, 1);
                        setSelectedFiltroValues({ ...selectedFiltroValues, producto: updatedProductos });
                        setFiltro({ ...filtro, producto: updatedProductos.map(p => p._id) });
                    }}
                />
            ))
        }


        </Wrapper>
    )
}

const Wrapper = styled.div`
    display:flex;
    width:100%;
    
    overflow-x: scroll;

`




export default SelectedFilterCotizacionesChips