import styled from '@emotion/styled';
import { useTheme } from '@mui/system';

const Chip = ({
    primary, 
    secondary, 
    success, 
    error, 
    warning,
    color,
    label,
    outline,
    textColor,
    icon,
    onClick,
    postIcon,
    ...deferred
})=>{
    let theme = useTheme();

    let chipColor;
    let _textColor;

    if(primary) chipColor = theme.palette.primary.main;
    if(secondary) chipColor = theme.palette.secondary;
    if(success) chipColor = theme.palette.success.light;
    if(warning) chipColor = theme.palette.warning.light;
    if(error) chipColor = theme.palette.error.light;
    if(color) chipColor = color;

    if (!chipColor) {
        chipColor = theme.palette.grey[700];
    }

    if(outline){
        _textColor = textColor || chipColor;
    }else if(!outline && !textColor){
        _textColor = "white"
    }else{
        _textColor = textColor
    }
   

    return (
        <StyledChip outline={outline} style={{'--chip-color': chipColor}} onClick={onClick} {...deferred}>
             {icon}   
             {label &&
                <StyledLabel style={{'--text-color': _textColor}} {...deferred}>
                     {label}
                </StyledLabel>
             }
             {postIcon} 
        </StyledChip>
    )

}

const StyledChip = styled.div`
    border-radius: 16px;
    background-color: ${p=>p.outline? "white" : "var(--chip-color)"};
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    max-height: ${p=>p.small ? "16px" : "28px"};
    border: 1px solid;
    border-color: ${p=>p.outline? "var(--chip-color)" : "transparent"};
    width: fit-content;
    margin: 2px;
    white-space: nowrap;
    gap:4px;

    cursor: ${p=>p.onClick && 'pointer'}
    
`

const StyledLabel = styled.span`
    color: var(--text-color);
    font-size: ${p=>p.small ? '0.7rem' : '0.8125rem'};
    font-weight: 600;
    margin: 2px;
    line-height:
`


export default Chip