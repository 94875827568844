import React from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";
import Chip from '../../Atoms/Chip';

const AgentChip = ({ agent, isClosed }) => {
    if (!agent) return null;

    const getInitials = (firstname, lastname) => {
        return `${firstname?.charAt(0) || ''}${lastname?.charAt(0) || ''}`.toUpperCase();
    };

    return (
        <StyledChip
            icon={
                agent.profilePicture ? (
                    <AgentAvatar 
                        src={agent.profilePicture} 
                        alt={`${agent.firstname} ${agent.lastname}`}
                        $isClosed={isClosed}
                    />
                ) : (
                    <AvatarInitials $isClosed={isClosed}>
                        {getInitials(agent.firstname, agent.lastname)}
                    </AvatarInitials>
                )
            }
            label={`${agent.firstname} ${agent.lastname}`}
            outline
            $isClosed={isClosed}
        />
    );
};

const StyledChip = styled(Chip)`
    background-color: ${p => p.theme.palette.background.paper};
    gap: 8px;
    opacity: ${p => p.$isClosed ? 0.6 : 1};
    border-color: ${p => p.$isClosed ? p.theme.palette.grey[400] : p.theme.palette.grey[500]};
    color: ${p => p.$isClosed ? p.theme.palette.grey[500] : p.theme.palette.grey[700]};
`;

const AgentAvatar = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    opacity: ${p => p.$isClosed ? 0.6 : 1};
`;

const AvatarInitials = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${p => p.$isClosed ? p.theme.palette.grey[400] : p.theme.palette.primary.main};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
`;

export default AgentChip; 