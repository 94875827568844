import React from "react";
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import usePermissions from "../../../hooks/usePermissions";
import { apiUrl } from "../../../shared/url";
import SearchBar from "../../Atoms/SearchBar";





const CotizacionesSearchBar = ({
    setCotizaciones,    
    setIsLoading
})=>{
    const user = useCurrentUser()
    const [searchValue, setSearchValue] = React.useState();
    const [isLoadingSearchBar, setIsLoadingSearchBar] = React.useState(false)

    const dispatch = useDispatch();

    const permissions = usePermissions()
    const {        
        canVerTodasCotizaciones
    }=permissions


    const restricted = !canVerTodasCotizaciones
    let ventasId
    if(restricted){
        ventasId = user._id
    }else{
        ventasId = "000"
    }

    const onChangeSearchValue = async (event)=>{
        event.preventDefault();
        
        const searchTerm = event.target.value
        setSearchValue(searchTerm)
        if(searchTerm.length>2){            
            //setIsLoading(true)
            setIsLoadingSearchBar(true)
            const options = createOptions('GET')
            let cotizaciones;
            const response = await goFetch(apiUrl+ "cotizaciones/search/" + searchTerm, options);
            if(response){
                cotizaciones = response;
              //  setIsLoading(false)
                setIsLoadingSearchBar(false)
                setCotizaciones(cotizaciones)                
            }

        }
        if(!searchTerm){            
            const options = createOptions('GET')
            let cotizaciones;
            const response = await goFetch(apiUrl + `cotizaciones/fetch-cotizaciones?restricted=${restricted}&ventasId=${ventasId}&&nextCursor=undefined`,options)
            if(response){
                cotizaciones = response.cotizaciones;
                //setIsLoading(false)
                setIsLoadingSearchBar(false)
                setCotizaciones(cotizaciones)
            }            
        }
         

    }

    return (
        <SearchBar 
        fullWidth={true}
        label="Buscar Cotizacion"
        value={searchValue}
        onChange={onChangeSearchValue}
        isLoading={isLoadingSearchBar}
    />
    )
}



export default CotizacionesSearchBar