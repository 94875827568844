import styled from "@emotion/styled";
import { CardBody, CardWrapper } from "../Atoms/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Chip from "../Atoms/Chip";
import Flag from "../Icons/Flag";
import React from "react";
import StatusDropDown from './molecules/StatusDropDown';
import AssigneeDropDown from './molecules/AssigneeDropDown';
import PriorityDropDown from './molecules/PriorityDropDown';
import AgentChip from './molecules/AgentChip';

import TimeTeller from "../molecules/TimeTeller";
import Paperclip from "../Icons/Paperclip";

const TicketCard = ({
    ticket, 
    agents, 
    agentsLoading, 
    onStatusChange,
    onAgentChange,
    onPriorityChange
}) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const isClosed = ticket.status === 'Cerrado';

    const getChipProps = () => {
        if(ticket.status === 'Abierto') return { success: true };
        if(ticket.status === 'Trabajando') return { warning: true };
        if(ticket.status === 'Reabierto') return { success: true };
        if(ticket.status === 'Cerrado') return { error: true };
        return { success: true }; // default
    };

    // Check if there's a response to our reply
    const hasResponse = () => {
        if (!ticket.messages || ticket.messages.length <= 1) return false;
        
        // Get the last message
        const lastMessage = ticket.messages[ticket.messages.length - 1];
        
        // Check if the last message is from a non-agent (external user)
        // and the ticket is not closed
        return !lastMessage.sender.isAgent && ticket.status !== 'Cerrado';
    };

    // Add helper function to check for attachments
    const hasAttachments = () => {
        return ticket.messages && ticket.messages.length > 0 && ticket.messages[0].attachments && ticket.messages[0].attachments.length > 0;
    };

    const handleStatusChange = async (status) => {
        onStatusChange(ticket._id, status);
    };

    const handleAssigneeChange = (agent) => {
        onAgentChange(ticket._id, agent);
    };

    const handlePriorityChange = (priority) => {
        onPriorityChange(ticket._id, priority);
    };

    const getPriorityColor = (priority) => {
        switch(priority) {
            case 'Baja':
                return theme.palette.success.main;
            case 'Media':
                return theme.palette.warning.main;                       
            case 'Alta':
                return theme.palette.error.main;
            default:
                return theme.palette.grey[400];
        }
    };

    const getPriorityLabel = (priority) => {
        switch(priority) {
            case 'Baja':
                return 'Baja';
            case 'Media':
                return 'Media';
            case 'Alta':
                return 'Alta';            
            default:
                return '';
        }
    };

    const getPriorityChipProps = (priority) => {
        switch(priority) {
            case 'Baja':
                return { success: true };
            case 'Media':
                return { warning: true };
            case 'Alta':
                return { error: true };
            default:
                return { success: true };
        }
    };


    

    return (
        <StyledCardWrapper 
            key={`${ticket._id}`} 
            $isClosed={isClosed}
        >
            <StyledCardBody>
                <CardBodyWrapper>
                    <HeaderWrapper onClick={() => navigate(`/app/tickets/${ticket._id}`)}>
                        <HeaderContent>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <SubjectText $isClosed={isClosed}>{ticket.subject}</SubjectText>
                                <TimeTeller date={ticket.createdAt} />
                            </div>
                            <ChipsWrapper>
                                {hasAttachments() && (
                                    <Paperclip size={14} color={isClosed ? theme.palette.grey[400] : theme.palette.grey[700]}/>
                                )}
                                {hasResponse() && (
                                    <Chip 
                                        label="Tiene respuesta" 
                                        primary
                                        outline
                                    />
                                )}
                                <Chip 
                                    label={ticket.status} 
                                    {...getChipProps()}
                                />
                                {ticket?.priority && ['Baja', 'Media', 'Alta'].includes(ticket?.priority) && (
                                    <Chip 
                                        icon={<Flag size={14} color={getPriorityColor(ticket?.priority)} />}
                                        label={getPriorityLabel(ticket?.priority)}
                                        outline
                                        {...getPriorityChipProps(ticket?.priority)}
                                    />
                                )}
                                {ticket.agent && (
                                    <AgentChip 
                                        agent={ticket.agent} 
                                        isClosed={isClosed}
                                    />
                                )}
                            </ChipsWrapper>
                        </HeaderContent>
                    </HeaderWrapper>
                    <BottomWrapper>
                        <ContentWrapper>
                            <InfoWrapper>   
                                <FontAwesomeIcon 
                                    icon={faEnvelope} 
                                    size="1x" 
                                    color={isClosed ? theme.palette.grey[400] : theme.palette.grey[700]}
                                />
                                <Text $isClosed={isClosed}>
                                    {`${ticket.creator.name} • ${ticket.creator.email}`}
                                </Text>
                            </InfoWrapper>
                        </ContentWrapper>
                        <ActionsWrapper>
                            <PriorityDropDown 
                                onPriorityChange={handlePriorityChange}
                                isClosed={isClosed}
                                currentPriority={ticket.priority}
                            />
                            <StatusDropDown 
                                onStatusChange={handleStatusChange} 
                                currentStatus={ticket.status}
                                isClosed={isClosed}
                            />
                            <AssigneeDropDown 
                                onAssigneeChange={handleAssigneeChange}
                                isClosed={isClosed}
                                agents={agents}
                                loading={agentsLoading}
                                currentAgent={ticket.agent}
                            />
                        </ActionsWrapper>
                    </BottomWrapper>
                </CardBodyWrapper>                
            </StyledCardBody>
        </StyledCardWrapper>                        
    );
};

const StyledCardWrapper = styled(CardWrapper)`
    opacity: ${props => props.$isClosed ? 0.6 : 1};
    background-color: ${props => props.$isClosed ? props.theme.palette.grey[50] : 'white'};
    transition: all 0.2s ease-in-out;
    border-radius: 12px;
    
    


    @media (min-width: 600px) {
        width: 100%;
        max-width: 100%;    
     
        &:hover {
        transform: translateX(4px);
        box-shadow: ${props => props.theme.shadows[2]};
    } 
    }
`;

const StyledCardBody = styled(CardBody)`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    
    @media (max-width: 600px) {
        flex-wrap: wrap;
        gap: 16px;
    }
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    @media (min-width: 600px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
`;

const CardBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InfoWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p => p.$isClosed ? p.theme.palette.grey[500] : p.theme.palette.grey[700]};
       
`;

const SubjectText = styled(Text)`
    font-size: 1.25rem;
    font-weight: 600;
    color: ${p => p.$isClosed ? p.theme.palette.grey[500] : p.theme.palette.grey[900]};
        
`;

const ChipsWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

const ActionsWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    
    @media (max-width: 600px) {
        width: 100%;
        justify-content: flex-end;
    }
`;



export default TicketCard;