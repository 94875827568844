import React from 'react';
import styled from '@emotion/styled';
import { Menu, MenuItem, useTheme } from "@mui/material";
import IconButton from "../../Atoms/IconButton";
import CheckCircle from "../../Icons/CheckCircle";

const StatusDropDown = ({ onStatusChange, isClosed }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (status) => {
        onStatusChange(status);
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <CheckCircle size={21} color={isClosed ? theme.palette.grey[400] : theme.palette.grey[700]}/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleSelect('Abierto')}>
                    <MenuItemWrapper>
                        <Text>Abierto</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('Trabajando')}>
                    <MenuItemWrapper>
                        <Text>Trabajando</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('Reabierto')}>
                    <MenuItemWrapper>
                        <Text>Reabierto</Text>
                    </MenuItemWrapper>
                </MenuItem>
                <MenuItem onClick={() => handleSelect('Cerrado')}>
                    <MenuItemWrapper>
                        <Text>Cerrado</Text>
                    </MenuItemWrapper>
                </MenuItem>
            </Menu>
        </div>
    );
};

const MenuItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Text = styled.p`
    margin: 0;
    font-size: 0.875rem;
    color: ${p => p.theme.palette.grey[700]};
`;

export default StatusDropDown; 