import React from "react";
import { useDispatch } from "react-redux";
import { createOptions, goFetch } from "../../../api/cirugiasApi";
import { apiUrl } from "../../../shared/url";
import Autocomplete from "../../Atoms/Autocomplete";
import Button from "../../Atoms/Button";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";
import { newTotalCotizacionCalc } from "../CotizacionesHelpers";












const ProductosAutocomplete = ({
    onChange,
    clear,
    setClear
})=>{

    const dispatch = useDispatch();    
    
    //productos coming from search
    const [productos, setProductos] = React.useState([]);
    
    // React.useEffect(()=>{
    //  //   dispatch(fetchProductos())
    // },[dispatch])

     //manages changes in keystroked in autocomplete to call up products

     console.log(productos)
     const onInputChange = async(e)=>{
        console.log(e.target.value.length);
        const search = e.target.value;
        if(search.length > 2){
            //dispatch(fetchProductos(e.target.value))

            const options = createOptions('GET')
            let productos;
              
            try{
               // productos = await goFetch(apiUrl+ "productos/" + search,options );
                productos = await goFetch(`${apiUrl}productos/${search}/active`,options );
                setProductos(productos)
            }catch(error){
                console.log(error);
            }
        
        }
    }
    

    return(
        <Autocomplete                  
            label="Productos"
            name="productos"
            id="productos"
            onChange={onChange}
            onInputChange={onInputChange}
            options={productos}
            clear={clear}
            setClear={setClear}
            getOptionLabel={(option)=>`${option.codigo} - ${option.descripcion}`}
        />
    )
}


const ProductoInput = ({    
    setProductos,
    createSelectedProductObject,
    selectedClient,
    close,
    currency,
    setTotalCotizacion,
    productos

}) => {

    const [producto, setProducto] = React.useState(null);
    const [productosNuevosCotizacion, setProductsNuevosCotizacion] = React.useState([]);
    const [clear, setClear] = React.useState();

    const onChangeHandlerProducto = (event,option)=>{    
        console.log(option)
        if(!option)return;
        setProducto(option)
    }

    const onClickAdd = ()=>{
        if(!producto)return;
        const productoObj = createSelectedProductObject(producto,selectedClient, currency)
        setProductsNuevosCotizacion([...productosNuevosCotizacion,productoObj])
        setProducto(null);
        setClear(true);        
    }

    const onClickGuardar = ()=>{
        console.log(productosNuevosCotizacion)
        const newProductos = [...productos, ...productosNuevosCotizacion]
        setProductos(newProductos)
        const newTotalCotizacion = newTotalCotizacionCalc(newProductos)
        setTotalCotizacion(newTotalCotizacion)        
        close();
    }


    console.log(producto)

    const onSubmit = async (event) =>{
        event.preventDefault();
    }

    return (
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle>Productos</FormTitle>
            <FormBody>

            
            <ProductosAutocomplete
                onChange={onChangeHandlerProducto}
                clear={clear}
                setClear={setClear}


            />
            <Button onClick={onClickAdd}>add</Button>
            <Button onClick={onClickGuardar}>Guardar</Button>
            {productosNuevosCotizacion.map((producto,index)=>{
                return(
                    <div key={index}>
                        {`${producto.codigo} - ${producto.descripcion}`}
                    </div>
                )
            })}
            </FormBody>
        </FormWrapper>
    )

}




export default ProductoInput;