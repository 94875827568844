import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import cirugiasReducer from './cirugiasReducer';
import cirugiaReducer from './cirugiaReducer'
import pacientesReducer from './pacientesReducer';
import doctorsReducer from './doctorsReducer';
import centrosReducer from './centrosReducer';
import productosReducer from './productosReducer';
import usersReducer from './usersReducer';
import authReducer from './authReducer';
import filterReducer from './filterReducer';
import messagesReducer from './messagesReducer';
import mdReduceer from './mdReducer';
import activityReducer from './activityReducer';
import clientsReducer from './clientsReducer';
import activosFijosReducer from './activosFijosReducer';
import ticketsReducer from './ticketsReducer';
import ticketFilterReducer from './ticketFilterReducer';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    cirugias: cirugiasReducer,
    cirugia: cirugiaReducer,
    pacientes: pacientesReducer,
    doctors: doctorsReducer,
    centros: centrosReducer,
    productos: productosReducer,
    users:usersReducer,
    auth: authReducer,
    filter: filterReducer,
    messages: messagesReducer,
    mds: mdReduceer,
    activity: activityReducer,
    clients: clientsReducer,
    activosFijos: activosFijosReducer,
    tickets: ticketsReducer,
    ticketFilter: ticketFilterReducer,
  },
});
