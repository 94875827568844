import { useTheme } from "@mui/material"
import Chip from "../../../Atoms/Chip"
import { StepButtonWrapper, StepHeading, StepWrapper } from "./StepStyles"
import BeatLoader from "react-spinners/BeatLoader"


const VentasSelection = ({
    ventas,
    ventasSelected,
    isLoadingVentas,
    onClickVentas
}) => {

    const theme = useTheme()
    return (
        <StepWrapper>
        <StepHeading>Seleccionar ventas</StepHeading>
        <StepButtonWrapper>
            {isLoadingVentas && <Chip outline label={<BeatLoader css={{lineHeight:0}} color={theme.palette.grey[600]} size={6} />}/>}
            {!isLoadingVentas &&
                ventas?.map(user => (
                    <Chip 
                        key={user._id}
                        success={user?._id === ventasSelected?._id} 
                        outline={user?._id !== ventasSelected?._id}                                     
                        label={`${user.firstname} ${user.lastname[0]}`} 
                        onClick={()=>onClickVentas(user)}
                    />))
            }                            
        </StepButtonWrapper>                    
    </StepWrapper>
    )
}   

export default VentasSelection