import React from 'react';
import { Autocomplete, TextField, Avatar } from '@mui/material';
import styled from '@emotion/styled/macro';

const AgentDropdown = ({ agents, selectedAgent, onChange }) => {
  return (
    <StyledAutocomplete
      options={agents}
      getOptionLabel={(agent) => `${agent.firstname} ${agent.lastname || ''}`}
      value={selectedAgent}
      onChange={(event, newValue) => onChange(newValue)}
      renderOption={(props, agent) => (
        <li {...props}>
          {agent.profilePicture ? (
            <AgentAvatarImage src={agent.profilePicture} alt={agent.firstname} />
          ) : (
            <AgentAvatar>
              {agent.firstname.charAt(0).toUpperCase()}
            </AgentAvatar>
          )}
          <span style={{ marginLeft: '8px' }}>
            {`${agent.firstname} ${agent.lastname || ''}`}
          </span>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select agent"
          size="small"
        />
      )}
    />
  );
};

const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: white;
  }
`;

const AgentAvatarImage = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

const AgentAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  font-size: 12px;
`;

export default AgentDropdown; 