import Button from "../../../Atoms/Button"
import { StepButtonWrapper, StepHeading, StepWrapper } from "./StepStyles"
import BeatLoader from "react-spinners/BeatLoader"
import Upload from "../../../Icons/Upload"


import isEmpty from "../../../../shared/isEmptyOject"
import styled from "@emotion/styled"



const InformacionAdicionalSelection = ({
    setFormType,
    setOpenModal,
    isPhone,
    newCotizacion,
    onClickCrearCotizacion,
    successEvent,
    isUploading,
    handleFileUpload,
    cotizacionRealizada
}) => {
    return (
        <StepWrapper>
                    <StepHeading>Informacion adicional</StepHeading>
                    <StepButtonWrapper>                        
                        <Button primary onClick={()=>{
                            setFormType('addNota')
                            setOpenModal(true)
                        }}>Nota</Button>
                        <Button primary onClick={()=>{
                            setFormType('addPaciente')
                            setOpenModal(true)
                        }}>Paciente</Button>
                        <Button primary onClick={()=>{
                            setFormType('addDoctor&Centro')
                            setOpenModal(true)
                        }}>Doctor & Centro</Button>                     
                        <Button primary onClick={()=>{
                            setFormType('descuentoTotal')
                            setOpenModal(true)
                        }}>Descuento Cotizacion</Button>
                        {false &&
                        <FileUploadButton>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                                id="file-upload"
                                disabled={isUploading}
                            />
                            <label htmlFor="file-upload">
                                <FilUploadInnerButton 
                                    primary
                                    disabled={isUploading}
                                >
                                    {isUploading ? (
                                        <BeatLoader size={8} color="white" />
                                    ) : (
                                        <>
                                            <Upload size={20} />
                                            Receta médica
                                        </>
                                    )}
                                </FilUploadInnerButton>
                            </label>
                        </FileUploadButton>
                        }
                    </StepButtonWrapper>
                    {isEmpty(newCotizacion)  &&  <Button disabled={cotizacionRealizada} primary onClick={onClickCrearCotizacion} success successEvent={successEvent}>Crear cotización</Button>}
                    {isPhone && <Button onClick={()=>{setFormType('mobileInfoSummary'); setOpenModal(true)}}>Ver informacion</Button>}
                    
                </StepWrapper>
    )
}       


const FileUploadButton = styled.div`
    display: inline-flex;
    
    label {
        cursor: pointer;
    }
`

const FilUploadInnerButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: 8px;
`


export default InformacionAdicionalSelection