import { useState, useEffect } from 'react';
import { 
    TextField,     
    IconButton, 
    Chip,
    Paper,
    Typography,
    Popper,
    ClickAwayListener
} from '@mui/material';
import styled from '@emotion/styled';
import Paperclip from '../../Icons/Paperclip';
import X from '../../Icons/X';
import Eye from '../../Icons/Eye';
import { CardWrapper } from '../../Atoms/Card';
import { apiUrl } from '../../../shared/url';
import { FormWrapper } from '../../Atoms/Forms';
import { goFetch, createOptions } from '../../../api/cirugiasApi';
import Button from '../../Atoms/Button';
import ChevronDown from '../../Icons/ChevronDown';
import ReplyDropDown from './ReplyDropDown';
import useSuccessButton from '../../../hooks/useSuccessButton';

// Helper function to get unique recipients by email
const getUniqueRecipients = (recipients) => {
    if (!Array.isArray(recipients)) return [];
    const map = new Map();
    recipients.forEach(recipient => {
        if (recipient && recipient.email) {
            map.set(recipient.email.toLowerCase(), recipient);
        }
    });
    return Array.from(map.values());
};

// Helper function to extract email from "Name <email>" format or return as is
const extractEmail = (emailString) => {
    if (!emailString) return null;
    const match = emailString.match(/<([^>]+)>/);
    // If format "Name <email>" is found, return the part inside <>, otherwise return the whole string trimmed
    return match ? match[1].trim() : emailString.trim();
};

const ReplyForm = ({ 
    originalTicket, 
    onSubmit, 
    onCancel, 
    preAttachedPdf
}) => {

    // --- Start Reply All Logic ---
    const lastMessage = originalTicket?.messages?.[0];
    const sender = lastMessage?.sender;
    const systemRecipient = originalTicket?.originalRecipient;
    const originalTo = lastMessage?.to || [];
    const originalCc = lastMessage?.cc || [];
    console.log('Original Ticket', originalTicket)

    // Combine To and CC recipients from the last message
    const allOriginalRecipients = getUniqueRecipients([...originalTo, ...originalCc]);
    console.log('allOriginalRecipients', allOriginalRecipients)

    // Filter out the sender AND the system's own address to get the CC list for the reply
    const senderEmailLower = extractEmail(sender?.email)?.toLowerCase();
    const systemEmailLower = extractEmail(originalTicket?.originalRecipient)?.toLowerCase();
    console.log('senderEmailLower', senderEmailLower)
    console.log('systemEmailLower', systemEmailLower)

    const ccRecipients = allOriginalRecipients.filter(recipient => {
        if (!recipient || !recipient.email) return false;
        // Extract email before converting to lowercase
        const recipientEmailLower = extractEmail(recipient.email)?.toLowerCase();
        if (!recipientEmailLower) return false; // Skip if email couldn't be extracted or is empty

        const isSender = senderEmailLower && recipientEmailLower === senderEmailLower;
        const isSystemRecipient = systemEmailLower && recipientEmailLower === systemEmailLower;
        return !isSender && !isSystemRecipient; // Exclude if sender OR system recipient
    });

    console.log('ccRecipients', ccRecipients)

    // Determine the 'to' recipient for the reply (should be the sender)
    const toRecipient = sender ? [sender] : [];
    // --- End Reply All Logic ---

    const [formData, setFormData] = useState({
        to: toRecipient, // Set 'to' to the sender of the last message
        cc: ccRecipients, // Set 'cc' to unique others from original To/CC
        bcc: [],
        message: '',
        attachments: []
    });
    const [emailSuggestions, setEmailSuggestions] = useState(['jlvasquezb@hospalmedica.com']);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeField, setActiveField] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showCcBcc, setShowCcBcc] = useState(
        Boolean(ccRecipients.length)
    );
    const { isLoading, setIsLoading, responseStateChanges } = useSuccessButton();

    const fetchEmailSuggestions = async (query) => {
        console.log('fetchEmailSuggestions', query)
        if (query && query.length > 2) {
            try {   
                const options = createOptions('GET');
                const response = await goFetch(`${apiUrl}ticketsContact/search?query=${query}`, options)
                setEmailSuggestions(response.contacts);
                console.log('response', response)            
            } catch (error) {
                console.error('Error fetching email suggestions:', error);
            }
        } else {
            // If query is empty or too short, show no suggestions
            setEmailSuggestions([]);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                fetchEmailSuggestions(searchTerm);
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        setSearchTerm(value);
        setActiveField(field);
        setAnchorEl(e.currentTarget);
    };

    const handleSuggestionClick = (suggestion) => {
        if (activeField) {
            setFormData(prev => ({
                ...prev,
                [activeField]: [...prev[activeField], {
                    email: suggestion.email,
                    name: suggestion.name
                }]
            }));
            setSearchTerm('');
            setEmailSuggestions([]);
            setAnchorEl(null);
        }
    };

    const handleSubmit = async (e, replyStatus) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        
        // Create FormData to handle file uploads
        const formDataToSend = new FormData();
        
        // Send just the new message content without modification
        formDataToSend.append('to', JSON.stringify(formData.to));
        formDataToSend.append('cc', JSON.stringify(formData.cc));
        formDataToSend.append('bcc', JSON.stringify(formData.bcc));
        formDataToSend.append('message', formData.message);
        formDataToSend.append('replyStatus', replyStatus);
        
        // Debug attachments before adding to FormData
        console.log('Attachments to be sent:', formData.attachments);
        console.log('Number of attachments:', formData.attachments.length);

        console.log('formData', formData);
        
        // Add attachments and log each one
        formData.attachments.forEach((file, index) => {
            console.log(`Adding attachment ${index}:`, {
                name: file.name,
                type: file.type,
                size: file.size
            });
            formDataToSend.append(`attachments`, file);
        });

        // Debug final FormData
        console.log('FormData entries:');
        for (let pair of formDataToSend.entries()) {
            console.log(pair[0], pair[1]);
        }
        
        try {
            const response = await onSubmit(formDataToSend);
            responseStateChanges(
                response,
                () => {
                    // Success callback
                    onCancel();
                },
                () => {
                    // Timeout callback after success
                    onCancel();
                }
            );
        } catch (error) {
            console.error('Error submitting reply:', error);
            setIsLoading(false);
        }
    };

    

    
        if (preAttachedPdf) {
            console.log('We have a pdfBlob');
            // Convert that blob to a File if your code expects File objects
            const pdfFile = new File([preAttachedPdf.blob], preAttachedPdf.filename, {
                type: "application/pdf",
            });
            
            // Check if a file with this name already exists in attachments
            const fileExists = formData.attachments.some(
                attachment => attachment.name === preAttachedPdf.filename
            );

            if (!fileExists) {
                setFormData(prev => ({
                    ...prev,
                    attachments: [...prev.attachments, pdfFile]
                }));
            }
        }
    

    const handleAttachmentChange = (event) => {        
        console.log('handleAttachmentChange', event);
        const files = Array.from(event.target.files);
        console.log('Files selected:', files.map(file => ({
            name: file.name,
            type: file.type,
            size: file.size
        })));
        setFormData(prev => ({
            ...prev,
            attachments: [...prev.attachments, ...files]
        }));
    };

    const removeAttachment = (index) => {
        setFormData(prev => ({
            ...prev,
            attachments: prev.attachments.filter((_, i) => i !== index)
        }));
    };

    const handleEmailChipDelete = (field, index) => {
        setFormData(prev => ({
            ...prev,
            [field]: prev[field].filter((_, i) => i !== index)
        }));
    };

    const handleKeyDown = (e) => {
        if (emailSuggestions.length) {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex(prev => 
                        prev < emailSuggestions.length - 1 ? prev + 1 : prev
                    );
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (selectedIndex >= 0) {
                        handleSuggestionClick(emailSuggestions[selectedIndex]);
                        setSelectedIndex(-1);
                    }
                    break;
                case 'Escape':
                    setAnchorEl(null);
                    setEmailSuggestions([]);
                    setSelectedIndex(-1);
                    break;
                default:
                    break;
            }
        } else if (e.key === 'Enter' && e.target.value) {
            e.preventDefault();
            handleManualEmailEntry(e.target.value, activeField);
        }
    };

    const handleManualEmailEntry = (email, field) => {
        const trimmedEmail = email.trim();
        if (isValidEmail(trimmedEmail)) {
            setFormData(prev => ({
                ...prev,
                [field]: [...prev[field], {
                    email: trimmedEmail,
                    name: trimmedEmail
                }]
            }));
            setSearchTerm('');
        }
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleBlur = (e) => {
        // Allow time for click events on suggestions
        setTimeout(() => {
            const value = e.target.value;
            if (value && !emailSuggestions.length) {
                handleManualEmailEntry(value, activeField);
            }
            setEmailSuggestions([]);
            setSelectedIndex(-1);
        }, 200);
    };

    const renderEmailField = (field, label, placeholder) => (
        <EmailField>
            <Label>{label}</Label>
            <ChipsContainer>
                {formData[field].map((recipient, index) => (
                    <Chip
                        key={index}
                        label={recipient.name || recipient.email}
                        onDelete={() => handleEmailChipDelete(field, index)}
                        size="small"
                    />
                ))}
                <TextField
                    size="small"
                    placeholder={placeholder}
                    value={activeField === field ? searchTerm : ''}
                    onChange={(e) => handleInputChange(e, field)}
                    onFocus={() => setActiveField(field)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
            </ChipsContainer>
        </EmailField>
    );

    const handleViewAttachment = (file) => {
        // Create a temporary URL for the file
        const fileUrl = URL.createObjectURL(file);
        
        // Open in new tab
        window.open(fileUrl, '_blank');
        
        // Clean up the URL after a short delay
        setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
    };

    return (
        <form component="form" onSubmit={handleSubmit}>
            <StyledCardWrapper>
                <EmailSection>
                    {renderEmailField('to', 'To:', 'Add recipients...')}
                    {!showCcBcc && (
                        <ShowMoreButton
                            preIcon={<ChevronDown size={16} />}
                            onClick={() => setShowCcBcc(true)}
                            outline
                        >
                            Show CC/BCC
                        </ShowMoreButton>
                    )}
                    {showCcBcc && (
                        <>
                            {renderEmailField('cc', 'CC:', 'Add CC recipients...')}
                            {renderEmailField('bcc', 'BCC:', 'Add BCC recipients...')}
                        </>
                    )}
                </EmailSection>

                <Popper
                    open={Boolean(anchorEl) && emailSuggestions?.length > 0}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    style={{ zIndex: 1301 }}
                    modifiers={[
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: true,
                                altBoundary: true,
                                tether: true,
                                rootBoundary: 'viewport',
                                padding: 8
                            }
                        }
                    ]}
                >
                    <ClickAwayListener onClickAway={() => {
                        setAnchorEl(null);
                        setEmailSuggestions([]);
                    }}>
                        <SuggestionsContainer>
                            {emailSuggestions.map((suggestion, index) => (
                                <SuggestionItem
                                    key={index}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                    selected={index === selectedIndex}
                                >
                                    <SuggestionName>{suggestion.name}</SuggestionName>
                                    <SuggestionEmail>{suggestion.email}</SuggestionEmail>
                                </SuggestionItem>
                            ))}
                        </SuggestionsContainer>
                    </ClickAwayListener>
                </Popper>

                <MessageField
                    multiline
                    rows={8}
                    fullWidth
                    placeholder="Type your message here..."
                    value={formData.message}
                    onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                />

                <AttachmentsSection>
                    <input
                        type="file"
                        multiple
                        id="file-upload"
                        style={{ display: 'none' }}
                        onChange={handleAttachmentChange}
                    />
                    <label htmlFor="file-upload">
                        <Button                            
                            preIcon={<Paperclip size={16} />}
                            outline                            
                        >
                            Attach Files
                        </Button>
                    </label>

                    {formData.attachments.length > 0 && (
                        <AttachmentsList>
                            {formData.attachments.map((file, index) => (
                                <AttachmentItem key={index}>
                                    <AttachmentContent onClick={() => handleViewAttachment(file)}>
                                        <Typography variant="body2" noWrap>{file.name}</Typography>
                                        <AttachmentSize>
                                            {(file.size / 1024).toFixed(1)} KB
                                        </AttachmentSize>
                                    </AttachmentContent>
                                    <AttachmentActions>
                                        <IconButton 
                                            size="small" 
                                            onClick={() => handleViewAttachment(file)}
                                            title="View attachment"
                                        >
                                            <Eye />
                                        </IconButton>
                                        <IconButton 
                                            size="small" 
                                            onClick={() => removeAttachment(index)}
                                            title="Remove attachment"
                                        >
                                            <X />
                                        </IconButton>
                                    </AttachmentActions>
                                </AttachmentItem>
                            ))}
                        </AttachmentsList>
                    )}
                </AttachmentsSection>

            <ButtonsContainer>
                <Button onClick={onCancel}>Cancel</Button>
                <ReplyDropDown
                    onReplyChange={handleSubmit}
                    isClosed={originalTicket?.status === 'closed'}
                    message={formData.message}
                    isLoading={isLoading}
                />
            </ButtonsContainer>
        </StyledCardWrapper>
        </form>
    );
};



const StyledCardWrapper = styled(CardWrapper)`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: none;
    margin:0;
`;

const EmailSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 0.875rem;
`;

const EmailField = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
`;

const Label = styled.span`
    min-width: 40px;
    padding-top: 8px;
    color: ${props => props.theme.palette.text.secondary};
`;

const ChipsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    min-height: 32px;
    padding: 4px;
    border: 1px solid ${props => props.theme.palette.divider};
    border-radius: 4px;

    .MuiTextField-root {
        flex: 1;
        min-width: 120px;
        

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .MuiOutlinedInput-root {
            font-size: 0.875rem;
            &:hover .MuiOutlinedInput-notchedOutline {
                border: none;
            }
            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }

    .MuiChip-root {
        margin: 2px;
    }
`;

const MessageField = styled(TextField)`
    .MuiOutlinedInput-root {
        background-color: ${props => props.theme.palette.background.paper};
        font-size: 0.875rem;
    }
`;

const AttachmentsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const AttachmentsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
`;

const AttachmentItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    background-color: ${props => props.theme.palette.action.hover};
    border-radius: 4px;
    
    &:hover {
        background-color: ${props => props.theme.palette.action.selected};
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 12px;
`;

const SuggestionsContainer = styled(Paper)`
    max-height: 200px;
    overflow-y: auto;
    width: 300px;
    z-index: 1300;
    box-shadow: ${props => props.theme.shadows[2]};
    position: relative;
    background-color: ${props => props.theme.palette.background.paper};
`;

const SuggestionItem = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    background-color: ${props => 
        props.selected ? props.theme.palette.action.selected : 'transparent'};
    
    &:hover {
        background-color: ${props => props.theme.palette.action.hover};
    }
`;

const SuggestionName = styled.div`
    font-weight: 500;
    color: ${props => props.theme.palette.text.primary};
`;

const SuggestionEmail = styled.div`
    font-size: 12px;
    color: ${props => props.theme.palette.text.secondary};
`;

const AttachmentContent = styled.div`
    flex: 1;
    cursor: pointer;
    padding: 4px;
    
    &:hover {
        background-color: ${props => props.theme.palette.action.hover};
    }
`;

const AttachmentActions = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const AttachmentSize = styled.span`
    font-size: 12px;
    color: ${props => props.theme.palette.text.secondary};
`;

const ShowMoreButton = styled(Button)`
    align-self: flex-start;
    margin-left: 52px;
    text-transform: none;
`;

export default ReplyForm;