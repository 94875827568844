import React from 'react';
import { useAccionMaker } from '../../../hooks/useAccionMaker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useUpdateCotizacion from '../../../hooks/useUpdateCotizacion';
import FormActionsBasic from '../../Atoms/FormActionsBasic';
import { FormBody, FormTitle, FormWrapper } from '../../Atoms/Forms';
import VentasAutocomplete from '../../Forms/CustomAutocomplete/VentasAutocomplete';




const ModifyVentasCotizacion = ({    
    cotizacion,
    close,
    cotizaciones,
    setCotizaciones
})=>{
    
    const isTablet = useMediaQuery('tablet');    
    const [selectedVentas, setSelectedVentas] = React.useState(null);        
    const {isLoading, successEvent, updateCotizacion} = useUpdateCotizacion(close, cotizaciones, setCotizaciones);        
    const accionMaker = useAccionMaker();
    
    const onSubmit = async (event) =>{
        event.preventDefault();

        let accion
        if(cotizacion?.ventas?.firstname){
             accion = accionMaker(`Ventas cambio de ${cotizacion?.ventas?.firstname} ${cotizacion?.ventas?.lastname} a ${selectedVentas?.firstname} ${selectedVentas?.lastname}`);
        }else{
            accion = accionMaker(`Centro agregado a la cotizacion`); 
        }
                
        let infoVentas = {
            ventasId: selectedVentas._id,
            cotizacionId: cotizacion._id,
            firstname: selectedVentas.firstname,
            secondname: selectedVentas.secondname || "",
            lastname: selectedVentas.lastname,
            secondLastname: selectedVentas.secondLastname || "",            
            accion:accion
          }            
          updateCotizacion('update-ventas',infoVentas)            
        }        
           
       const onChangeHandler = (event, option)=>{
        if(option){
            setSelectedVentas(option)
        }else{
            setSelectedVentas("")
        }        
    }

    return(
        <FormWrapper onSubmit={onSubmit} style={{minHeight:'300px'}}>
            <FormTitle isTablet={isTablet} >Modificar Ventas</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>

                <VentasAutocomplete
                    onChange={onChangeHandler}
                />               
                
            </FormBody>
            
            <FormActionsBasic
                close={close}
                successEvent={successEvent}
                loading={isLoading}
                type="submit"
                as="button"
           />
            
        </FormWrapper>
    )
}


export default ModifyVentasCotizacion