import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { TextField, useTheme } from '@mui/material';
import Button from '../Atoms/Button';
import Send from '../Icons/Send';
import X from '../Icons/X';
import { createOptions, goFetch } from '../../api/cirugiasApi';
import { apiUrl } from '../../shared/url';
import useCirugia from '../../hooks/useCirugia';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { addOneCirugia } from '../../app/cirugiaReducer';
import { addUpdatedCirugia } from '../../app/cirugiasReducer';
import Mic from '../Icons/MIc';
import MicOff from '../Icons/MicOff';





const AiChatVoiceSystemWide = ({close }) => {
  const user = useCurrentUser();
  const theme = useTheme()  
  const [messages, setMessages] = useState([
    { role: 'assistant', content: `Hola ${user.firstname}, soy tu asistente virtual. ¿En qué puedo ayudarte con esta cirugía?` }
  ]);
  console.log(messages)
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const isTablet = useMediaQuery('tablet');
  const dispatch = useDispatch();
  const params = useParams();
  const inputRef = useRef(null);
  const [isVoiceMode, setIsVoiceMode] = useState(false);
  const [ws, setWs] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordingStream, setRecordingStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  //Not being used for now as we need to improve 
  // useEffect(() => {
  //   if (isVoiceMode && !ws) {
  //     const socket = new WebSocket(`ws://localhost:3001`);
  //     socket.binaryType = 'arraybuffer';  // Specify binary type
      
  //     socket.onopen = async () => {
  //       console.log('WebSocket connected');
  //       // Send initial system prompt
  //       const initData = {
  //         systemPrompt        
  //       };
  //       socket.send(JSON.stringify(initData));
  //     };

  //     socket.onmessage = async (event) => {
  //       const response = JSON.parse(event.data);
        
  //       if (response.type === 'status') {
  //         console.log('Status:', response.message);
  //       } else if (response.type === 'response') {
  //         const aiMessage = { role: 'assistant', content: response.message };
  //         setMessages(prev => [...prev, aiMessage]);
          
  //         // Convert base64 to audio and play
  //         const audioData = atob(response.audio);
  //         const audioArray = new Uint8Array(audioData.length);
  //         for (let i = 0; i < audioData.length; i++) {
  //           audioArray[i] = audioData.charCodeAt(i);
  //         }
  //         const audioBlob = new Blob([audioArray], { type: 'audio/mp3' });
  //         const audioUrl = URL.createObjectURL(audioBlob);
  //         const audio = new Audio(audioUrl);
  //         await audio.play();
  //         URL.revokeObjectURL(audioUrl);

  //         if (response.updatedCirugia) {
  //           updateCirugia(response.updatedCirugia);
  //         }
  //       } else if (response.type === 'error') {
  //         console.error('Server error:', response.message);
  //         setIsVoiceMode(false);
  //       }
  //     };

  //     socket.onclose = () => {
  //       console.log('WebSocket disconnected');
  //       setIsVoiceMode(false);
  //       stopRecording();
  //     };

  //     setWs(socket);
  //   }

  //   return () => {
  //     if (ws) {
  //       console.log('Cleaning up WebSocket connection...');
  //       ws.close();
  //       setWs(null);
  //     }
  //     if (isRecording || recordingStream) {
  //       console.log('Cleaning up active recording...');
  //       stopRecording();
  //     }
  //   };
  // }, [isVoiceMode]);

  const setupMediaRecorder = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      
      setRecordingStream(stream);

      const mimeType = MediaRecorder.isTypeSupported('audio/webm')
        ? 'audio/webm'
        : MediaRecorder.isTypeSupported('audio/ogg')
          ? 'audio/ogg'
          : MediaRecorder.isTypeSupported('audio/wav')
            ? 'audio/wav'
            : null;

      if (!mimeType) {
        throw new Error('No supported audio MIME type found');
      }

      const newRecorder = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000
      });

      let chunks = [];
      
      newRecorder.ondataavailable = (event) => {
        console.log('Recording chunk:', {
          mimeType: event.data.type,
          size: event.data.size,
          state: newRecorder.state
        });
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      newRecorder.onstop = () => {
        console.log('Recording stopped, preparing to send');
        const audioBlob = new Blob(chunks, { type: mimeType });
        if (ws?.readyState === WebSocket.OPEN) {
          ws.send(audioBlob);
        }
        chunks = [];
        setIsRecording(false);
      };

      return newRecorder;
    } catch (error) {
      console.error('Error setting up MediaRecorder:', error);
      setIsVoiceMode(false);
      return null;
    }
  };

  const startRecording = async () => {
    console.log('Attempting to start recording...', {
      mediaRecorderExists: !!mediaRecorder,
      mediaRecorderState: mediaRecorder?.state,
      isRecording
    });

    try {
      const newRecorder = await setupMediaRecorder();
      if (newRecorder) {
        setMediaRecorder(newRecorder);
        newRecorder.start();
        setIsRecording(true);
        console.log('Recording started successfully');
      }
    } catch (error) {
      console.error('Error starting recording:', error);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    console.log('Attempting to stop recording...', {
      mediaRecorderExists: !!mediaRecorder,
      mediaRecorderState: mediaRecorder?.state,
      streamExists: !!recordingStream,
      isRecording
    });

    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      try {
        mediaRecorder.stop();
        console.log('MediaRecorder stopped');
      } catch (error) {
        console.error('Error stopping MediaRecorder:', error);
      }
    }
    
    if (recordingStream) {
      try {
        recordingStream.getTracks().forEach(track => {
          track.stop();
          console.log('Track stopped:', track.kind);
        });
        setRecordingStream(null);
      } catch (error) {
        console.error('Error stopping tracks:', error);
      }
    }
    setMediaRecorder(null);
    setIsRecording(false);
  };

  // Update the VoiceButton click handler
  const handleVoiceButtonClick = () => {
    console.log('Voice button clicked', {
      currentState: {
        isVoiceMode,
        isRecording,
        mediaRecorderState: mediaRecorder?.state
      }
    });

    if (!isVoiceMode) {
      console.log('Enabling voice mode...');
      toggleVoiceMode();
    } else if (mediaRecorder?.state === 'recording' || isRecording) {
      console.log('Stopping current recording...');
      stopRecording();
    } else {
      console.log('Starting new recording...');
      startRecording();
    }
  };

  const updateCirugia = (response)=>{
  
    console.log(response._id)
    console.log(params.cirugiaId)
    if(response._id === params.cirugiaId){
        console.log('in cirugia page')
        dispatch(addOneCirugia(response))
        return
    }else{
        dispatch(addUpdatedCirugia(response));
        return
    }
  }

  const toggleVoiceMode = async () => {
    console.log('Toggling voice mode', {
      currentState: {
        isVoiceMode,
        isRecording,
        mediaRecorderExists: !!mediaRecorder,
        wsExists: !!ws
      }
    });

    if (!isVoiceMode) {
      try {
        // First time enabling voice mode
        setIsVoiceMode(true);
        // MediaRecorder setup will happen in useEffect when isVoiceMode becomes true
      } catch (error) {
        console.error('Error enabling voice mode:', error);
        setIsVoiceMode(false);
      }
    } else {
      // Turning off voice mode
      if (isRecording) {
        stopRecording();
      }
      if (ws) {
        ws.close();
      }
      setIsVoiceMode(false);
      setMediaRecorder(null);
      setRecordingStream(null);
    }
  };

  const systemPrompt = `
      You are an AI assistant for Hospal Medica, a medical device distributor. 
      The user is company employee with id ${user._id} named ${user.firstname} ${user.lastname}.
      He is using an app called Xirugias to manage surgeries. There a different users in the app with various roles and permissions.
      The user has the following permissions: ${user.permissions.join(', ')} and is a ${user.role}.
      You will be able to search for surgeries in the app, get the infomration for the surgeries and update the surgeries.
      In order to answer the users questions regarding a particular surgery you will need to search for the surgery using the tools provided. You wil get back a json object with the surgery information.
      The Xirugias app is multi-tenant. You will need to pass the companyId to the tools to search for surgeries. The companyId is ${user.company._id}, this is a mongoose ObjectId.
      
      Be concise, do not just summarize all the information. Wait for specific questions. 
      Do not read out the products unless the user asks for them. 
      When providing information about a surgery focus on the patient name, doctor name, hospital (centro), and if available the surgery date and time.
      Make sure to answer in Spanish in a friendly and professional way.
      Use the user's name in your responses as appropriate.

      When performing a search for a surgery make sure you found the correct surgery using a patients name. Let the user know which surgery you found, provide relevant information such as confirming patient name, doctor name and hospital (centro). And relevant status in the info object.     
      If the search yeilds more than one potential result, let the user know that there are multiple surgeries with that name and ask for more specific information to narrow down the search.
      Once you found the correct surgery you are ready to provide answers to users questions and performa actions on the surgery.

      IMPORTANT TIMEZONE INSTRUCTIONS:
      - The Dominican Republic uses the America/Santo_Domingo timezone, which is 4 hours behind GMT.
      - Todays date in utc is: ${new Date().toLocaleDateString('es-DO', { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' })} a las ${new Date().toLocaleTimeString('es-DO', { timeZone: 'UTC', hour: 'numeric', minute: 'numeric', hour12: true })}.
     
      - Even if the user is in a different timezone (e.g., Florida), 
        **all references to dates or times must use America/Santo_Domingo**.
      - Do NOT use the current computer time to determine the date or time of the surgery; 
        rely only on America/Santo_Domingo time and any data provided in the surgery info object.

      SCHEDULING SURGERIES (agendar cirugias):
      - Times should always be in America/Santo_Domingo, in 12-hour format with "am" or "pm".
      - When calling the "agendar_cirugia" function, the "fecha" parameter must include 
        both date and time in ISO format with the time portion indicating America/Santo_Domingo, 
        e.g., "2024-03-21T14:00:00".

      -the surgery date is can be found in the Info Object of the cirugia if provided. Ignore comments about the surgery date, the only oficial information about the current time and
      date is in the Info Object of the cirugia.
      When providing the current date for the surgery, access it from the Info Object of the cirugia if provided.

      Remember:
      - Only provide details about the specified surgery.
      - If the user asks for the current date or time, provide the current date and time in America/Santo_Domingo.
      - Be concise, wait for specific questions.
      - Respond only in Spanish.
      - Use the user's name appropriately.
      - All times/dates are in the America/Santo_Domingo timezone.

`;
  
  
  const handleSend = async () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInput('');
      setIsLoading(true);
      
      const updatedMessages = [...messages, userMessage];
      
      const options = createOptions('POST', {        
        messages: updatedMessages,
        systemPrompt: systemPrompt,        
      });
      
      try {
        const response = await goFetch(apiUrl+ "ai/chatBotAssistant", options );

        if(response.updatedCirugia){
          updateCirugia(response.updatedCirugia);
        }
        
        const aiMessage = { role: 'assistant', content: response.message};
        setMessages(prevMessages => [...prevMessages, aiMessage]);
      } catch (error) {
        console.error("Error fetching AI response:", error);
        const errorMessage = { role: 'assistant', content: "Lo siento, hubo un error al procesar tu solicitud. Por favor, intenta de nuevo."};
        setMessages(prevMessages => [...prevMessages, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ChatContainer>
      {isTablet && (
        <CloseButton onClick={close}>
          <X color={theme.palette.error.light} />
        </CloseButton>
      )}
      <MessagesContainer>
        {messages.map((message, index) => (
          <Message key={index} isUser={message.role === 'user'}>
            {message.content}
          </Message>
        ))}
        {isLoading && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputContainer>
        <StyledTextField
          inputRef={inputRef}
          fullWidth
          variant="outlined"
          placeholder="Pregunta al IA sobre esta cirugia"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          disabled={isLoading || isVoiceMode}
        />
        <VoiceModeButton 
          outline
          onClick={toggleVoiceMode}
          active={isVoiceMode}
          disabled={isLoading}
        >
          {isVoiceMode ? 'Desactivar Voz' : 'Activar Voz'}
        </VoiceModeButton>
        {isVoiceMode && (
          <RecordButton
            outline
            onClick={handleVoiceButtonClick}
            recording={isRecording}
            disabled={isLoading}
          >
            {isRecording ? <MicOff /> : <Mic />}
          </RecordButton>
        )}
        {!isVoiceMode && (
          <SendButton success outline onClick={handleSend} disabled={isLoading}>
            <Send />
          </SendButton>
        )}
      </InputContainer>
    </ChatContainer>
  );
};

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  min-height: 380px;
  min-width: 380px;
  max-width: 600px;
  position: relative;
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 90%;
  font-size: 14px;
  line-height: 1.4;
  ${props => props.isUser ? `
    align-self: flex-end;
    background-color: #1a73e8;
    color: white;
    border-bottom-right-radius: 4px;
  ` : `
    align-self: flex-start;
    background-color: #f1f3f4;
    color: #202124;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  `}
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 24px;
  padding: 8px 16px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-input{
    padding: 8px;
  }
`;

const SendButton = styled(Button)`
  min-width: 36px;
  padding: 6px;
  margin-left: 8px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

const LoadingContainer = styled.div`
 margin-bottom: 10px;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 70%;
  font-size: 14px;
  line-height: 1.4;
  align-self: flex-start;
    background-color: white;
    color: #202124;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const VoiceModeButton = styled(Button)`
  min-width: 120px;
  margin-left: 8px;
  ${props => props.active && `
    background-color: ${props.theme.palette.primary.main};
    color: white;
  `}
`;

const RecordButton = styled(Button)`
  min-width: 36px;
  padding: 6px;
  margin-left: 8px;
  ${props => props.recording && `
    background-color: ${props.theme.palette.error.light};
    animation: pulse 1.5s infinite;
    svg {
      fill: white;
    }
  `}

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export default AiChatVoiceSystemWide;