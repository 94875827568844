import { useMediaQuery } from "@mui/material";
import React from "react";
import useCheckForm from "../../../hooks/useCheckForm";
import useSuccessButton from "../../../hooks/useSuccessButton";
import { isInRange, validate } from "../../../shared/validators";
import FormActionsBasic from "../../Atoms/FormActionsBasic";
import { FormBody, FormTitle, FormWrapper } from "../../Atoms/Forms";
import TextInput from "../../Atoms/TextInput";
import { updateSelectedProductObject } from "../CotizacionesHelpers";



const DescuentoTotalInput = ({
    close,
    selectedProductos,
    setSelectedProductos
}) => {
   
    const {isLoading,setIsLoading, successEvent, responseStateChanges} = useSuccessButton()
    const isTablet= useMediaQuery('tablet'); 
    const [values, setValues] = React.useState({
        percentDiscount: 0,
        discount:0,   
        
    })
    const [errors, setErrors] = React.useState({
        percentDiscount: '',
        price: ''
    })
    
    const [formCompleted, errorCount] = useCheckForm(values,errors, ["percentDiscount"])

    const onChangeValues = (event,validators, postCheck) => {        
        const {name, value} = event.target
        setValues(prevState => ({...prevState,[name]:value}));
        
        //provides immediat feedback as user types
        if(!validators || validators?.length<1)return
        const error = validate(value, validators)

        //post check only shows error after blur has taken effect. Will clean up error once the right info is typed in.
        if(postCheck){
            if(error && !errors[name]){
                return
            }

            if(!error && errors[name]){
                setErrors(prevState=>({...prevState, [name]:""}))
            }
        
        }

        if(!postCheck){
            if(error){
                console.log('error found')
                setErrors(prevState=>({...prevState, [name]:error}))
            }else{
                setErrors(prevState=>({...prevState, [name]:""}))
            }    
        }                
     }

     const onBlur = (event, validators)=>{
        if(!validators || validators?.length<1)return
        const {name, value} =  event.target        
        const error = validate(value, validators)
        setErrors(prevState=>({...prevState, [name]:error}))
    }

    function applyPercentDiscount(input, number) {
             
        // Remove percent sign and convert to decimal
        const percent = parseFloat(input) / 100;
      
        // Calculate discount and return discounted value
        const discountedValue = number - (number * percent);
        return Number(discountedValue.toFixed(2)); // round to 2 decimal places
    }


    const onSubmit = (event) => {
        event.preventDefault();
        //update percent discount and discount field in every product in selectedProductos
        const newSelectedProductos = selectedProductos.map(producto=>{
            
                const newPrice = producto.clientPrice
                const quantity = producto.quantity
                const percentDiscount = values.percentDiscount
                const newProducto = updateSelectedProductObject(producto,newPrice,quantity,percentDiscount)
                console.log(newProducto)
                return newProducto
               
            })
        setSelectedProductos(newSelectedProductos)
        close();
                
    }

    


        
    return(
        <FormWrapper onSubmit={onSubmit}>
            <FormTitle isTablet={isTablet}>Descuento global</FormTitle>
            <FormBody isTablet={isTablet} style={{height:"90%"}}>                        
            <TextInput 
                label={"Descuento"} 
                name="percentDiscount"
                style={{textAlign: 'end'}}
                value={values.percentDiscount} 
                onChange={(event)=>onChangeValues(event, [()=>isInRange(0,100, event.target.value)], true)} 
                endIcon={<span>%</span>}
                helperText={errors?.percentDiscount}
                error={errors.percentDiscount}
                onBlurValidation={(event)=>onBlur(event, [()=>isInRange(0,100, event.target.value)])}
            ></TextInput>    
            </FormBody>
            
            <FormActionsBasic
                close={close}                
                successText="Aceptar"
                successEvent={successEvent}
                loading={isLoading}      
                type="submit"
                as="button"          
                disabledSuccess={!formCompleted || errorCount>0}
           />
            
        </FormWrapper>
    )
        
    
}



export default DescuentoTotalInput;