import styled from "@emotion/styled"




export const StepWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    justify-content: center;
`


export const StepHeading = styled.div` 
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    font-weight: 600;
    color: ${p=>p.theme.palette.grey[900]};
    background-color: ${p=>p.theme.palette.grey[100]};
    border-radius: 12px;
    padding: 4px 8px;
`

export const StepButtonWrapper = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    align-items: center;
`