import styled from '@emotion/styled';
import ResultBox, { ResultBoxGrid } from '../../Atoms/ResultBox';
import { round } from '../CrearCotizaciones';
import { getMonthNameInSpanish } from '../../../shared/helpers';


const ReporteCotizaciones = ({  
    cotizacionesCount,
}) => {

    
    //historico
    const cotizaciones = cotizacionesCount?.cotizacionesCount || 0;
    const cotizacionesAprobadasCount = cotizacionesCount?.cotizacionesAprobadasCount || 0;
    const cotizacionesAprobadasPorcentaje = Math.round(cotizacionesAprobadasCount / cotizaciones * 100) || 0;

    //mesActual
    const cotizacionesCurrentMonth = cotizacionesCount?.cotizacionesCurrentMonthCount || 0;
    const cotizacionesAprobadasCurrentMonthCount = cotizacionesCount?.cotizacionesAprobadasCurrentMonthCount || 0;
    const cotizacionesAprobadasCurrentMonthPorcentaje = Math.round(cotizacionesAprobadasCurrentMonthCount / cotizacionesCurrentMonth * 100) || 0;

    //mesAnterior
    const cotizacionesLastMonth = cotizacionesCount?.cotizacionesLastMonthCount || 0;
    const cotizacionesAprobadasLastMonthCount = cotizacionesCount?.cotizacionesAprobadasLastMonthCount || 0;
    const cotizacionesAprobadasLastMonthPorcentaje = Math.round(cotizacionesAprobadasLastMonthCount / cotizacionesLastMonth * 100) || 0;

    //dosMesesAtras
    const cotizacionesTwoMonthsAgo = cotizacionesCount?.cotizacionesTwoMonthsAgoCount || 0;
    const cotizacionesAprobadasTwoMonthsAgoCount = cotizacionesCount?.cotizacionesAprobadasTwoMonthsAgoCount || 0;
    const cotizacionesAprobadasTwoMonthsAgoPorcentaje = Math.round(cotizacionesAprobadasTwoMonthsAgoCount / cotizacionesTwoMonthsAgo * 100) || 0;



    const currentMonth = getMonthNameInSpanish(new Date().getMonth())   
    const lastMonth = getMonthNameInSpanish(new Date().getMonth()-1)    
    const twoMonthsAgo = getMonthNameInSpanish(new Date().getMonth()-2)

    return (
        <Wrapper>
            <Title>Reporte</Title>

            <SectionTitle>{currentMonth}</SectionTitle>
            <ResultBoxGrid>
                <ResultBox title="Cotizadas" result={cotizacionesCurrentMonth} category="Cotizaciones"/>
                <ResultBox title="Aprobadas" result={cotizacionesAprobadasCurrentMonthCount} category="Cotizaciones"/>
                <ResultBox title="Aprobacion" result={`${cotizacionesAprobadasCurrentMonthPorcentaje}%`} category="Cotizaciones"/>
            </ResultBoxGrid>

            <SectionTitle>{lastMonth}</SectionTitle>
            <ResultBoxGrid>
                <ResultBox title="Cotizadas" result={cotizacionesLastMonth} category="Cotizaciones"/>
                <ResultBox title="Aprobadas" result={cotizacionesAprobadasLastMonthCount} category="Cotizaciones"/>
                <ResultBox title="Aprobacion" result={`${cotizacionesAprobadasLastMonthPorcentaje}%`} category="Cotizaciones"/>
            </ResultBoxGrid>

            <SectionTitle>{twoMonthsAgo}</SectionTitle>
            <ResultBoxGrid>
                <ResultBox title="Cotizadas" result={cotizacionesTwoMonthsAgo} category="Cotizaciones"/>
                <ResultBox title="Aprobadas" result={cotizacionesAprobadasTwoMonthsAgoCount} category="Cotizaciones"/>
                <ResultBox title="Aprobacion" result={`${cotizacionesAprobadasTwoMonthsAgoPorcentaje}%`} category="Cotizaciones"/>
            </ResultBoxGrid>

            <SectionTitle>{"Historico"}</SectionTitle>
            <ResultBoxGrid>
                <ResultBox title="Cotizadas" result={cotizaciones} category="Cotizaciones"/>
                <ResultBox title="Aprobadas" result={cotizacionesAprobadasCount} category="Cotizaciones"/>
                <ResultBox title="Aprobacion" result={`${cotizacionesAprobadasPorcentaje}%`} category="Cotizaciones"/>
            </ResultBoxGrid>


        </Wrapper>
    )

}



const Wrapper = styled.div`

    display: flex;
    flex-direction: column;    
    justify-content: center;
    gap: 8px;    
    width: 100%;    
    padding: 25px;

    @media ${p=>p.theme.media.tablet}{
        max-width: 500px;
        min-width: 400px;
    }

`

const Title = styled.h2`

    font-size: 1.5rem;
    font-weight: 600;
    color: ${props => props.theme.palette.text.primary};
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;    
`

const SectionTitle = styled.span`
    margin-left: 16px;
    color: ${props => props.theme.palette.grey[700]};
    font-weight: 600;
`

export default ReporteCotizaciones;
